import { Button } from "../../shared/components";
import { Container } from "../../shared/components";
import { DocumentUpload, Pill } from "../../App/components";
import axios from "axios";
import { useState } from "react";
import { getStageConfig } from "../../config/config";
import { useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axiosInstance";
import "../../text.css";
import { useResponsiveStyles } from "../../shared/mobile";

export const Documents = ({ onClick }) => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t } = useTranslation();
  localStorage.setItem("funnel_step", 5);

  const [pdfFiles, setPdfFiles] = useState([]);
  const config = getStageConfig();

  useAnalytics();

  const onFileChange = (index, file) => {
    if (file) {
      const newPdfFiles = [...pdfFiles];
      newPdfFiles[index] = file;
      setPdfFiles((prevPdfFiles) => {
        const updatedPdfFiles = [...prevPdfFiles];
        updatedPdfFiles[index] = file;
        return updatedPdfFiles;
      });
    }
  };

  const tenantId = localStorage.getItem("funnel_tenant_id");

  const uploadImages = (presignedUrls) => {
    return pdfFiles.map((file, index) =>
      axios.put(presignedUrls[index].presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Allow-Origin": "*",
        },
      })
    );
  };

  const getSignedUrl = async () => {
    const images = [];
    pdfFiles.forEach((file) => {
      if (file) {
        images.push({ title: file.name, contentType: file.type });
      }
    });

    try {
      const result = await axiosInstance.post(
        `${config.API_URL}/default/compensation/images/${tenantId}`,
        { images },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (result.status === 200) {
        await Promise.all(uploadImages(result.data));
      }
    } catch (error) {
      console.error(`error occured, while uploading images: ${error}`);
    }
  };

  const continueClicked = async () => {
    await getSignedUrl();
    onClick();
  };

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "10%", width: "100%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <h2 style={{ fontSize: isMobile ? "3rem" : "", bottom: 0, margin: 0 }}>
          {t("signup.comp-docs.text1")}
        </h2>

        <h3 style={{ fontSize: isMobile ? "3rem" : "" }}>
          {t("signup.comp-docs.text2")}
        </h3>
        <Container
          itemsPerRow={isMobile ? 1 : 4}
          margin="0"
          gap={isMobile ? "5rem" : 0}
          style={{ width: "80%" }}
        >
          <DocumentUpload
            style={{
              height: isMobile ? "10rem" : "",
            }}
            onFileChange={(file) => onFileChange(0, file)}
          ></DocumentUpload>
          <DocumentUpload
            style={{
              height: isMobile ? "10rem" : "",
            }}
            onFileChange={(file) => onFileChange(1, file)}
          ></DocumentUpload>
          <DocumentUpload
            style={{
              height: isMobile ? "10rem" : "",
            }}
            onFileChange={(file) => onFileChange(2, file)}
          ></DocumentUpload>
          <DocumentUpload
            style={{
              height: isMobile ? "10rem" : "",
            }}
            onFileChange={(file) => onFileChange(3, file)}
          ></DocumentUpload>
        </Container>

        <Button
          style={{
            backgroundColor: "#69ADFF",
            fontWeight: 600,
            width: isMobile ? "20rem" : "25%",
            height: isMobile ? "7rem" : "3rem",
            marginTop: isMobile ? "10rem" : "2%",
          }}
          onClick={continueClicked}
          label={t("signup.comp-docs.text3")}
        ></Button>

        <div
          style={{
            position: "absolute",
            bottom: isMobile ? "50%" : isTablet ? "40%" : "3%",
          }}
        >
          <img
            loading="lazy"
            style={{
              width: "95%",
              backgroundSize: "cover",
              backgroundPosition: "center",
              position: "relative",
            }}
            src={require("../assets/smartmieter_instant_payments_background.png")}
            alt="background for instant payments"
          ></img>

          <div
            style={{
              position: "absolute",
              top: "10%",
              left: "3%",
            }}
          >
            <Container
              itemsPerRow={2}
              margin="0"
              gap="0"
              columnWidths={["73%", "27%"]}
            >
              <h2
                style={{
                  color: "white",
                  margin: 0,
                }}
              >
                {t("signup.comp-docs.text4")}
              </h2>
              <Pill
                textStyle={{
                  top: "5%",
                  whiteSpace: "nowrap",
                  color: "white",
                }}
                style={{ width: "60%", right: isTablet ? "20%" : "10%" }}
                label={t("signup.comp-docs.text5")}
              ></Pill>
            </Container>

            <h5 style={{ color: "white", width: "70%", marginTop: "1%" }}>
              {t("signup.comp-docs.text6")}
            </h5>
            <h5 style={{ color: "white", marginTop: 0 }}>
              {t("signup.comp-docs.text7")}
            </h5>
          </div>
        </div>
      </Container>
    </div>
  );
};
