import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import { Header } from "../shared/header";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { ContractElement, ImageButton } from "../shared/components";
import { useAuth } from "../Auth/provider";
import { Send } from "../Cases/send";

export const ServicechargeAnalysis = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isMobile = useResponsiveStyles();

  const [servicecharge, setServicecharge] = useState();
  const { servicechargeId } = useParams();
  const [selectedExpenses, setSelectedExpenses] = useState([]);
  const [caseId, setCaseId] = useState(null);
  const [expenseClicked, setExpenseClicked] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const toggleClauseSelection = (expense) => {
    setSelectedExpenses((prevSelectedExpenses) => {
      const isSelected = prevSelectedExpenses.includes(expense);
      if (isSelected) {
        return prevSelectedExpenses.filter((item) => item !== expense);
      } else {
        return [...prevSelectedExpenses, expense];
      }
    });
  };

  const getServicecharge = async (servicechargeId) => {
    try {
      const response = await axiosInstance.get(
        `/service-charges/${servicechargeId}`
      );

      setServicecharge(response.data);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  useEffect(() => {
    if (servicechargeId) {
      getServicecharge(servicechargeId);
    }

    console.log(selectedExpenses);
    setButtonEnabled(selectedExpenses.length > 0);
  }, [servicechargeId, selectedExpenses]);

  useAnalytics();

  const containerStyle = {
    padding: "2rem",
    width: "80%",
    margin: "0 auto",
  };

  const titleStyle = {
    fontSize: "2.5rem",
    marginTop: "10rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1rem",
  };

  const subtitleStyle = {
    fontSize: "1.1rem",
    textAlign: "center",
    color: "#666",
    marginBottom: "2rem",
  };

  const handleContinueClick = async () => {
    setExpenseClicked(true);

    try {
      const result = await axiosInstance.post(`/cases`, {
        reason: "service_charge",
        status: "opened",
        tenantId: user?.tenantId,
        tenantName: user?.fullName,
        language: user?.language,
      });

      setCaseId(result.data.caseId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const handleVisibility = () => {
    setExpenseClicked(false);
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F6F6",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Header staticColor={true}></Header>
      <div style={containerStyle}>
        {expenseClicked ? (
          <Send
            externalBackgroundStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(10%, 30%)",
              width: isMobile ? "85vw" : "65vw",
              height: isMobile ? "70vh" : "60vh",
              borderRadius: "30px",
              backgroundColor: "white",
              zIndex: 10,
            }}
            landlordInformation={
              user ? user?.landlord || user?.propertyManagement : undefined
            }
            reason={"service_charge"}
            caseId={caseId}
            onCancel={() => handleVisibility()}
          ></Send>
        ) : undefined}
        <h1 style={titleStyle}>{t("servicecharge.analysis.title")}</h1>
        <p style={subtitleStyle}>{t("servicecharge.analysis.subtitle")}</p>

        <div
          style={{
            marginTop: "4rem",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1rem",
            maxHeight: "calc(100vh - 25rem)",
            overflowY: "auto",
            paddingRight: "1rem",
            marginRight: "-1rem",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {servicecharge &&
          servicecharge.analysis.illegalExpenses &&
          servicecharge.analysis.illegalExpenses.length > 0 ? (
            [...servicecharge.analysis.illegalExpenses].map((expense) => (
              <ContractElement
                key={expense.id || expense.label}
                onClickAction={() => toggleClauseSelection(expense)}
                imgstyle={{
                  width: "40%",
                  marginTop: "0.5rem",
                  marginBottom: "1rem",
                  paddingLeft: "1rem",
                }}
                title={expense.label}
                img={
                  selectedExpenses.includes(expense)
                    ? require("../shared/assets/Checked.png")
                    : require("../shared/assets/Unchecked.png")
                }
              >
                {expense.analysis}
              </ContractElement>
            ))
          ) : (
            <div style={{ textAlign: "center", marginTop: "2rem" }}>
              <p>{t("servicecharge.analysis.noExpenses")}</p>
            </div>
          )}
        </div>
        {expenseClicked ? undefined : (
          <ImageButton
            colorScheme="light"
            style={{
              marginTop: "2rem",
              width: "20%",
              height: "3rem",
              position: "absolute",
              right: "10%",
              bottom: "2%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1.1rem",
              backgroundColor: buttonEnabled ? "black" : "lightgray",
              cursor: buttonEnabled ? "pointer" : "not-allowed",
            }}
            label={t("servicecharge.analysis.continue")}
            onClick={() => {
              handleContinueClick();
            }}
          ></ImageButton>
        )}
      </div>
    </div>
  );
};
