export const reasonTranslations = {
  cold_water: {
    de: "kaltes Wasser",
    en: "cold water",
    tr: "soğuk su",
    ar: "ماء بارد",
    compensation_estimate: "15%",
  },
  no_electricity: {
    de: "kein Strom",
    en: "no electricity",
    tr: "elektrik yok",
    ar: "لا كهرباء",
    compensation_estimate: "100%",
  },
  no_water: {
    de: "kein Wasser",
    en: "no water",
    tr: "su yok",
    ar: "لا ماء",
    compensation_estimate: "80%",
  },
  no_heating: {
    de: "keine Heizung",
    en: "no heating",
    tr: "ısıtma yok",
    ar: "لا تدفئة",
    compensation_estimate: "70%",
  },
  mold: {
    de: "Schimmel",
    en: "mold",
    tr: "küf",
    ar: "عفن",
    compensation_estimate: "100%",
  },
  pest_infestation: {
    de: "Schädlingsbefall",
    en: "pest infestation",
    tr: "haşere istilası",
    ar: "غزو الآفات",
    compensation_estimate: "50%",
  },
  broken_windows: {
    de: "kaputte Fenster",
    en: "broken windows",
    tr: "kırık camlar",
    ar: "نوافذ مكسورة",
    compensation_estimate: "20%",
  },
  leaky_roof: {
    de: "undichtes Dach",
    en: "leaky roof",
    tr: "sızdıran çatı",
    ar: "سقف مسرب",
    compensation_estimate: "50%",
  },
  faulty_plumbing: {
    de: "fehlerhafte Sanitäranlagen",
    en: "faulty plumbing",
    tr: "arızalı tesisat",
    ar: "سباكة معيبة",
    compensation_estimate: "25%",
  },
  faulty_wiring: {
    de: "fehlerhafte Verkabelung",
    en: "faulty wiring",
    tr: "arızalı kablolama",
    ar: "أسلاك معيبة",
    compensation_estimate: "50%",
  },
  structural_damage: {
    de: "Strukturschäden",
    en: "structural damage",
    tr: "yapısal hasar",
    ar: "أضرار هيكلية",
    compensation_estimate: "100%",
  },
  poor_insulation: {
    de: "schlechte Isolierung",
    en: "poor insulation",
    tr: "zayıf yalıtım",
    ar: "عزل ضعيف",
    compensation_estimate: "15%",
  },
  asbestos: {
    de: "Asbest",
    en: "asbestos",
    tr: "asbest",
    ar: "الأسبستوس",
    compensation_estimate: "100%",
  },
  lead_paint: {
    de: "Bleifarbe",
    en: "lead paint",
    tr: "kurşun boya",
    ar: "طلاء الرصاص",
    compensation_estimate: "50%",
  },
  sewage_backup: {
    de: "Abwasser Rückstau",
    en: "sewage backup",
    tr: "kanalizasyon yedeklemesi",
    ar: "نسخ احتياطي للصرف الصحي",
    compensation_estimate: "100%",
  },
  illegal_rent_increase: {
    de: "illegale Mieterhöhung",
    en: "illegal rent increase",
    tr: "yasadışı kira artışı",
    ar: "زيادة الإيجار غير القانونية",
  },
  high_rent: {
    de: "hohe Miete",
    en: "high rent",
    tr: "yüksek kira",
    ar: "إيجار مرتفع",
  },
  illegal_contract: {
    de: "Unzulässiger Vertrag",
    en: "illegal contract",
    tr: "yasadışı sözleşme",
    ar: "عقد غير قانوني",
  },
};

export const statusTranslations = {
  resolved: {
    de: "gelöst",
    en: "resolved",
    tr: "çözüldü",
    ar: "تم الحل",
  },
  opened: {
    de: "geöffnet",
    en: "opened",
    tr: "açıldı",
    ar: "مفتوح",
  },
  contact_landlord: {
    de: "Vermieter kontaktieren",
    en: "contact landlord",
    tr: "ev sahibi ile iletişime geç",
    ar: "اتصل بالمالك",
  },
  contact_attorney: {
    de: "Anwalt kontaktieren",
    en: "contact attorney",
    tr: "avukat ile iletişime geç",
    ar: "اتصل بالمحامي",
  },
  response_needed: {
    de: "Antwort erforderlich",
    en: "response needed",
    tr: "yanıt gerekli",
    ar: "الرد مطلوب",
  },
  chat_consultation: {
    de: "Chat-Beratung",
    en: "chat consultation",
    tr: "sohbet danışmanlığı",
    ar: "استشارة الدردشة",
  },
  offer_accepted: {
    de: "Angebot akzeptiert",
    en: "offer accepted",
    tr: "teklif kabul edildi",
    ar: "تم قبول العرض",
  },
  offer_received: {
    de: "Angebot erhalten",
    en: "offer received",
    tr: "teklif alındı",
    ar: "تم الاستلام العرض",
  },
  offer_rejected: {
    de: "Angebot abgelehnt",
    en: "offer rejected",
    tr: "teklif reddedildi",
    ar: "تم رفض العرض",
  },
};
