import { Header } from "../shared/header";
import React, { useState } from "react";
import { useAnalytics } from "../analytics";
import { Container } from "../shared/components";
import { useEffect, useRef } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { getStageConfig } from "../config/config";
import { useAuth } from "../Auth/provider";
import ReactMarkdown from "react-markdown";
import { Send } from "../Cases/send";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import { Account } from "../Account";
import axiosInstance from "../axiosInstance";
import { ChatInput } from "./components/input";
import { Message } from "./components/message";
import { Contract } from "./components/contract";
import "../text.css";
import { Pill } from "../App/components";

export const Chat = () => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [minderungKeys, setMinderungKeys] = useState({});
  const [recipientType, setRecipientType] = useState("");
  const [reductionClicked, setReductionClicked] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [reductionReason, setReductionReason] = useState("");
  const [chatId, setChatId] = useState("");
  const [contractDialogVisible, setContractDialogVisible] = useState(false);
  const [contractContext, setContractContext] = useState(false);
  const [contractId, setContractId] = useState(undefined);
  const [contractKey, setContractKey] = useState("");
  const [contractButtonExpanded, setContractButtonExpanded] = useState(false);
  const navigate = useNavigate();
  const ws = useRef(null);

  const { user, jwtToken } = useAuth();
  const config = getStageConfig();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const question = queryParams.get("question");
  const newChat = queryParams.get("new");
  const chatIdParam = queryParams.get("chatId");

  const initializeWebSocket = () => {
    ws.current = new ReconnectingWebSocket(
      `${config.WEBSOCKET_URL}?token=${jwtToken}`
    );
  };

  useEffect(() => {
    initializeWebSocket();

    if (newChat) {
      createChat();
    }

    if (question) {
      setInput(question);
      sendMessage();
    }

    if (chatIdParam) {
      getMessages(chatIdParam);
    }

    ws.current.onopen = () => {
      console.log("Connected to WebSocket");
    };

    ws.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      const botMessage = {
        sender: "bot",
        reduction: response.rentReduction,
        message:
          response.message === "Internal server error" ? (
            t("chat-chat.error")
          ) : (
            <ReactMarkdown children={response.message}></ReactMarkdown>
          ),
      };
      setReductionReason(response.rentReduction);
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsTyping(false);
      setMinderungKeys((prevKeys) => ({
        ...prevKeys,
        [response.message]: response.rentReduction,
      }));
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error: ", error);
    };

    ws.current.onclose = () => {
      console.log("WebSocket closed");
      setTimeout(initializeWebSocket, 5000);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [question]);

  const handleSuggestionClicked = (label) => {
    setInput(label);
    sendMessage();
  };

  const createChat = async () => {
    try {
      const result = await axiosInstance.post(`/chat`, {
        userId: user?.tenantId,
      });

      setChatId(result.data.chatId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const getMessages = async (chatId) => {
    try {
      const result = await axiosInstance.get(`/chat/${chatId}`);

      setMessages((prevMessages) => {
        const allMessages = [...prevMessages, ...result.data];
        const uniqueMessages = Array.from(
          new Set(allMessages.map((msg) => msg.id))
        ).map((id) => allMessages.find((msg) => msg.id === id));
        return uniqueMessages;
      });
    } catch (error) {}
  };

  const sendMessage = async () => {
    if (
      ws.current &&
      ws.current.readyState === WebSocket.OPEN &&
      input.trim()
    ) {
      setIsTyping(true);
      const userMessage = { sender: "user", message: input };
      setMessages((prevMessages) => [...prevMessages, userMessage]);
      ws.current.send(
        JSON.stringify({
          action: "sendMessage",
          message: input,
          contractContext,
          contractKey,
          userId: user?.tenantId ?? "",
          chatId,
        })
      );

      setInput("");
    } else {
      console.error("WebSocket is not open");
    }
  };

  useAnalytics();

  const suggestionPillStyle = {
    backgroundColor: "#AAD0FF",
    height: isMobile ? "4rem" : "3rem",
    fontSize: isMobile ? "2rem" : "",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    whiteSpace: isMobile ? "" : "nowrap",
    borderRadius: "100px",
    fontWeight: "bold",
  };

  const handleContractClicked = () => {
    setContractDialogVisible(!contractDialogVisible);
  };

  const handleReductionClick = async (reason) => {
    setReductionClicked(true);

    try {
      const result = await axiosInstance.post(`/cases`, {
        reason,
        status: "opened",
        tenantId: user?.tenantId,
        tenantName: user?.fullName,
        language: user?.language,
        chatId,
      });

      setCaseId(result.data.caseId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const handleVisibility = () => {
    setReductionClicked(false);
  };

  const createContractContext = (contractId, contractKey) => {
    setContractDialogVisible(false);
    setContractContext(true);
    setContractId(contractId);
    setContractKey(contractKey);
  };

  const analyseContract = () => {
    navigate(`/contract/${contractId}`);
  };

  useEffect(() => {
    scrollToBottom();

    const handleKeyDown = (event) => {
      if (event.key === "Enter" && input.trim()) {
        event.preventDefault();
        sendMessage();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [messages, input, sendMessage]);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header staticColor={true}></Header>
      <div id="parent" style={{ overflowX: "hidden", height: "90%" }}>
        {/* TODO: fix this fucking shit*/}
        <div id="wide" style={{ display: "flex", flexDirection: "column" }}>
          {contractContext ? (
            <button
              onClick={analyseContract}
              style={{
                backgroundColor: "black",
                color: "white",
                position: "absolute",
                top: "5rem",
                left: "2rem",
                cursor: "pointer",
                transition: "width 0.3s",
                border: "none",
                borderRadius: "100px",
                fontSize: "1rem",
                width: contractButtonExpanded ? "15rem" : "10rem",
                height: "3rem",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onMouseEnter={() => setContractButtonExpanded(true)}
              onMouseLeave={() => setContractButtonExpanded(false)}
            >
              {contractButtonExpanded
                ? "Mietvertrag analysieren"
                : "Mietvertrag"}
              <img
                style={{
                  width: isMobile ? "44px" : "22px",
                  height: isMobile ? "40px" : "20px",
                  objectFit: "cover",
                  marginLeft: "0.5rem",
                }}
                loading="lazy"
                alt="SmartMieter Continue Arrow"
                src={require("../shared/assets/continue-arrow.png")}
              ></img>
            </button>
          ) : undefined}
          <div>
            <div
              style={{
                margin: isMobile ? "3%" : "1%",
                marginTop: isMobile ? "30%" : "10%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {messages.length === 0 && !chatIdParam ? (
                <div>
                  <h1
                    style={{
                      margin: 0,
                      textAlign: "center",
                      marginTop: isMobile ? "50%" : "20%",
                      bottom: 0,
                    }}
                  >
                    smartmieter Chat
                  </h1>
                  <h2
                    style={{
                      color: "gray",
                      textAlign: "center",
                      margin: 0,
                    }}
                  >
                    {t("chat-chat.help")}
                  </h2>
                  <Container
                    style={{
                      width: isMobile ? "100%" : "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "2%",
                    }}
                    itemsPerRow={2}
                    columnWidths={["50%", "50%"]}
                    itemWidth="auto"
                  >
                    <Pill
                      key={t("chat-chat.raise")}
                      label={t("chat-chat.raise")}
                      style={{
                        minWidth: "88%",
                        marginLeft: isMobile ? "" : "2rem",
                        marginRight: isMobile ? "" : "2rem",
                      }}
                      textStyle={{
                        fontSize: isMobile ? "0.8rem" : "1rem",
                        fontWeight: 500,
                      }}
                      onClick={() =>
                        handleSuggestionClicked(t("chat-chat.raise"))
                      }
                    />
                    <Pill
                      key={t("chat-chat.water")}
                      label={t("chat-chat.water")}
                      style={{
                        minWidth: "88%",
                        marginLeft: isMobile ? "" : "2rem",
                        marginRight: isMobile ? "" : "2rem",
                      }}
                      textStyle={{
                        fontSize: isMobile ? "0.8rem" : "1rem",
                        fontWeight: 500,
                      }}
                      onClick={() =>
                        handleSuggestionClicked(t("chat-chat.water"))
                      }
                    />
                    <Pill
                      key={t("chat-chat.dogs")}
                      label={t("chat-chat.dogs")}
                      style={{
                        minWidth: "88%",
                        marginLeft: isMobile ? "" : "2rem",
                        marginRight: isMobile ? "" : "2rem",
                      }}
                      textStyle={{
                        fontSize: isMobile ? "0.8rem" : "1rem",
                        fontWeight: 500,
                      }}
                      onClick={() =>
                        handleSuggestionClicked(t("chat-chat.servicecharges"))
                      }
                    />

                    <Pill
                      key={t("chat-chat.servicecharges")}
                      label={t("chat-chat.servicecharges")}
                      style={{
                        minWidth: "88%",
                        marginLeft: isMobile ? "" : "2rem",
                        marginRight: isMobile ? "" : "2rem",
                      }}
                      textStyle={{
                        fontSize: isMobile ? "0.8rem" : "1rem",
                        fontWeight: 500,
                      }}
                      onClick={() =>
                        handleSuggestionClicked(t("chat-chat.servicecharges"))
                      }
                    />
                  </Container>
                </div>
              ) : undefined}
              {messages.map((message, index) => (
                <Message
                  message={message.message}
                  sender={message.sender}
                  reduction={message.reduction}
                  index={index}
                  reductionClicked={() =>
                    handleReductionClick(message.reduction)
                  }
                ></Message>
              ))}
              {isTyping && (
                <div className="message-bubble bot message bot-message">
                  <div className="typing-indicator">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>
          </div>
        </div>
      </div>
      {contractDialogVisible ? (
        <Contract
          onClick={(contractId, contractKey) =>
            createContractContext(contractId, contractKey)
          }
          toggleVisibility={() => setContractDialogVisible(false)}
        ></Contract>
      ) : undefined}
      <ChatInput
        input={input}
        inputChanged={(e) => setInput(e)}
        contractClicked={() => handleContractClicked()}
        sendMessage={sendMessage}
      ></ChatInput>
      {reductionClicked ? (
        <Send
          recipientType={recipientType}
          landlordInformation={
            user ? user?.landlord || user?.propertyManagement : undefined
          }
          reason={reductionReason.replace(RegExp('"', "g"), "")}
          caseId={caseId}
          onCancel={() => handleVisibility()}
        ></Send>
      ) : undefined}
      <Account></Account>
    </div>
  );
};
