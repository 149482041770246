import { Button, Container, Input, TextArea } from "../../shared/components";
import { Header } from "../../shared/header";
import { useRef, useState } from "react";
import { Footer } from "../../shared/footer";
import { useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useResponsiveStyles } from "../../shared/mobile";
import { getStageConfig } from "../../config/config";
import { Account } from "../../Account";
import axiosInstance from "../../axiosInstance";
import "../../text.css";
import { SEO } from "../../shared/components/SEO";

export const Contact = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t, i18n } = useTranslation();
  const [response, setResponse] = useState("");
  const parentRef = useRef();
  const nestedRef = useRef(null);
  const config = getStageConfig();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  useAnalytics();

  const image = {
    desktop: require("../assets/woman-image.jpg"),
    mobile: require("../assets/smartmieter_contact_background_mobile.png"),
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSend = async () => {
    if (!email || !name || !message) {
      setResponse(t("legal.contact.error"));
      return;
    }

    try {
      const result = await axiosInstance.post(`/users/contact`, {
        email,
        name,
        message,
      });
      if (result.status === 200) {
        setResponse(t("legal.contact.success"));
      } else {
        setResponse(t("legal.contact.error"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <SEO namespace="marketing" page="contact" path="/contact" />
      <div ref={parentRef}>
        <Header
          parentRef={parentRef}
          nestedRef={nestedRef}
          staticColor={true}
        ></Header>
        <Container
          itemWidth="100%"
          style={{
            top: 0,
            left: 0,
            margin: 0,
            rowGap: 0,
            overflowX: "hidden",
            width: "100%",
          }}
        >
          <div>
            <Container
              itemWidth="100%"
              style={{
                height: isMobile ? "60rem" : isTablet ? "30rem" : "45rem",
                top: 0,
                left: 0,
                margin: 0,
                display: "block",
                width: "100%",
              }}
            >
              <img
                ref={nestedRef}
                loading="lazy"
                style={{ top: 0, left: 0, width: "100%", position: "absolute" }}
                src={isMobile ? image.mobile : image.desktop}
                alt="woman placeholder for aesthetics"
              ></img>
              <p
                style={{
                  position: "relative",
                  marginTop: isMobile ? "90%" : "35%",
                  marginLeft: "10%",
                  fontSize: "2rem",
                  fontWeight: 500,
                  marginBottom: "0",
                  color: "#E6E6E6",
                  bottom: 0,
                }}
              >
                {t("legal.contact.subheadline")}
              </p>
              <h1
                style={{
                  color: "white",
                  position: "relative",
                  top: 0,
                  marginTop: 0,
                  marginLeft: "10%",
                }}
              >
                {t("legal.contact.headline")}
              </h1>
            </Container>
            <Container
              itemsPerRow={1}
              style={{
                width: isMobile ? "70%" : "30%",
                height: isMobile ? "auto" : "60vh",
                margin: "0 auto",
                marginBottom: "10%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
              gap="0"
              itemWidth={"100%"}
              alignItems={isMobile ? "auto" : "center"}
              columnWidths={["100%"]}
            >
              <h2>{t("legal.contact.h3")}</h2>

              <h4
                style={{
                  marginTop: "5%",
                }}
              >
                {t("common.fullname")}
              </h4>
              <Input
                onChange={(e) => handleNameChange(e)}
                placeholder={
                  i18n.language === "de" ? "Max Mustermann" : "Jessica Williams"
                }
                style={{
                  border: "1px solid lightgray",
                  margin: 0,
                  paddingLeft: isMobile ? "20px" : "10px",
                }}
              ></Input>

              <h4
                style={{
                  marginTop: "10%",
                }}
              >
                {t("common.email")}
              </h4>
              <Input
                onChange={(e) => handleEmailChange(e)}
                placeholder={
                  i18n.language === "de" ? "max@email.de" : "jessica@email.com"
                }
                style={{
                  border: "1px solid lightgray",
                  paddingLeft: isMobile ? "20px" : "10px",
                }}
              ></Input>

              <h4
                style={{
                  marginTop: "10%",
                }}
              >
                {t("legal.contact.message")}
              </h4>
              <TextArea
                onChange={(e) => handleMessageChange(e)}
                placeholder={
                  i18n.language === "de" ? "Nachricht eingeben" : "Type here"
                }
                style={{
                  border: "1px solid lightgray",
                  height: isMobile ? "18vh" : "10vh",
                  fontSize: isMobile ? "2.4rem" : "1rem",
                  fontWeight: 300,
                }}
              ></TextArea>

              <h4 style={{ marginTop: "10%" }}>{response}</h4>
              <Button
                onClick={handleSend}
                style={{
                  marginTop: "2%",
                  borderRadius: isMobile ? "20px" : "10px",
                  height: isMobile ? "6rem" : "3rem",
                }}
                label={t("common.send")}
              ></Button>
              <h5
                style={{
                  color: "lightgray",
                  fontSize: isMobile ? "0.8rem" : "0.7rem",
                  marginTop: "1rem",
                }}
              >
                {t("common.privacynotice")}
              </h5>
            </Container>
          </div>
          <br></br>
          <br></br>
        </Container>
        <Footer></Footer>
        <Account></Account>
      </div>
    </>
  );
};
