import { useAnalytics } from "../analytics";
import { Container } from "../shared/components";
import { Footer } from "../shared/footer";
import { Header } from "../shared/header";
import { useTranslation } from "react-i18next";
import "../i18n";
import { SEO } from "../shared/components/SEO";

export const Story = () => {
  const { t, i18n } = useTranslation();

  useAnalytics();
  return (
    <>
      <SEO
        namespace="marketing"
        page="story"
        path="/story"
      />
      <div style={{ overflow: "hidden" }}>
        <Header staticColor={true}></Header>
        <Container
          itemWidth="80%"
          columnWidths={["100%"]}
          style={{
            width: "100%",
            height: "150vh",
            marginBottom: "5%",
          }}
        >
          <p
            className="split-text"
            style={{
              fontSize: "2.5rem",
              marginTop: "20%",
              marginLeft: "20%",
            }}
          >
            {t("founding_story")}
          </p>
        </Container>
        <Footer></Footer>
      </div>
    </>
  );
};
