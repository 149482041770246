import { getStageConfig } from "../../config/config";
import { Button, Input, SelectionInput } from "../../shared/components";
import { Container } from "../../shared/components";
import { useState } from "react";
import { useEffect } from "react";
import { useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axiosInstance";
import "../../text.css";
import { useResponsiveStyles } from "../../shared/mobile";

export const Contact = ({ type = "personal" | "landlord", onClick }) => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t } = useTranslation();
  localStorage.setItem("funnel_step", type === "personal" ? 3 : 4);
  const config = getStageConfig();

  useAnalytics();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [landlordName, setLandlordName] = useState("");
  const [landlordEmail, setLandlordEmail] = useState("");
  const [landlordAddress, setLandlordAddress] = useState("mrs");
  const [address, setAddress] = useState("mrs");
  const [validInput, setValidInput] = useState(false);
  const [language, setLanguage] = useState("");
  const [city, setCity] = useState("");

  const [error, setError] = useState("");

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  const validatePassword = () => passwordRegex.test(password);

  useEffect(() => {
    setLanguage(navigator.language || navigator.userLanguage);
  }, []);

  const checkInput = () => {
    switch (type) {
      case "personal":
        if (name.length === 0 || password.length === 0 || city.length === 0) {
          setError("Bitte füllen Sie alle Felder aus");
          return false;
        } else if (!validatePassword()) {
          setError(
            "Das Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl enthalten."
          );
          return false;
        }
        setValidInput(true);
        setError("");
        return true;
      case "landlord":
        if (landlordName.length === 0 || landlordEmail.length === 0) {
          setError("Bitte füllen Sie alle Felder aus");
          return false;
        } else if (!landlordEmail.includes("@")) {
          setError("Bitte geben Sie eine gültige Email ein");
          return false;
        }
        setValidInput(true);
        return true;
    }
  };

  const createUser = async () => {
    try {
      const result = await axiosInstance.post(
        `${config.API_URL}/default/users`,
        {
          fullName: name,
          email: localStorage.getItem("lead_email"),
          address,
          language,
          password,
          city,
          prompt: localStorage.getItem("funnel_prompt"),
        }
      );

      if (result.data && result.data.tenantId) {
        const newTenantId = result.data.tenantId;

        localStorage.setItem("funnel_tenant_id", newTenantId);
      } else {
        console.error("Error creating user:", result);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const updateLandlordData = async () => {
    const id = localStorage.getItem("funnel_tenant_id");
    if (!id) {
      console.error("Cannot update: Tenant ID is not set.");
      return;
    }

    try {
      const result = await axiosInstance.put(`/users/${id}`, {
        sk: "PROFILE",
        landlord: {
          name: landlordName,
          email: landlordEmail,
          address: landlordAddress,
          type: "landlord",
        },
      });

      if (result.status === 200) {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    localStorage.setItem("funnel_name", event.target.value);
    checkInput();
  };

  const handleLandlordAddressChange = (event) => {
    setLandlordAddress(event.target.value);
    localStorage.setItem("funnel_landlord_address", event.target.value);
    checkInput();
  };

  const handleLandlordNameChange = (event) => {
    setLandlordName(event.target.value);
    localStorage.setItem("funnel_landlord_name", event.target.value);
    checkInput();
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    localStorage.setItem("funnel_address", event.target.value);
    checkInput();
  };

  const handleLandlordEmailChange = (event) => {
    setLandlordEmail(event.target.value);
    localStorage.setItem("funnel_landlord_email", event.target.value);
    checkInput();
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    localStorage.setItem("funnel_city", event.target.value);
    checkInput();
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    checkInput();
  };

  const continueClicked = async () => {
    if (checkInput()) {
      if (type === "landlord") {
        await updateLandlordData();
      } else if (type === "personal") {
        await createUser();
      }
      onClick();
    }
  };

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "10%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <h2 style={{ bottom: 0, margin: 0, fontSize: isMobile ? "3rem" : "" }}>
          {
            type === "personal"
              ? t("signup.comp-contact.text1") //Here
              : t("signup.comp-contact.text2") //Here
          }
        </h2>
        {type === "personal" ? (
          <div>
            <h3
              style={{
                fontSize: isMobile ? "3rem" : "",
                bottom: 0,
                margin: 0,
                marginTop: "3%",
              }}
            >
              {t("signup.comp-contact.title1")}
            </h3>
            <SelectionInput
              onChange={(event) => handleAddressChange(event)}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              options={[
                { label: t("auth-register.title1"), value: "ms" },
                { label: t("auth-register.title2"), value: "mr" },
                { label: t("auth-register.other"), value: "other" },
              ]}
            ></SelectionInput>
            <h3
              style={{
                fontSize: isMobile ? "3rem" : "",
                margin: 0,
                marginTop: "4%",
              }}
            >
              {t("signup.comp-contact.names")}
            </h3>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder={t("signup.comp-contact.enter")}
              value={localStorage.getItem("funnel_name")}
              onChange={(event) => handleNameChange(event)}
            ></Input>

            <h3
              style={{
                fontSize: isMobile ? "3rem" : "",
                margin: 0,
                marginTop: "4%",
              }}
            >
              {t("signup.comp-contact.town")}
            </h3>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder={t("signup.comp-contact.town")}
              value={localStorage.getItem("funnel_city")}
              onChange={(event) => handleCityChange(event)}
            ></Input>

            <h3
              style={{
                fontSize: isMobile ? "3rem" : "",
                bottom: 0,
                margin: 0,
                marginTop: "4%",
              }}
            >
              {t("signup.comp-contact.password")}
            </h3>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder={t("signup.comp-contact.confirm")}
              type="password"
              onChange={(event) => handlePasswordChange(event)}
            ></Input>
            <h5 style={{ fontSize: isMobile ? "2rem" : "", marginTop: "16px" }}>
              {t("signup.comp-contact.text3")}
            </h5>
          </div>
        ) : (
          <div>
            <h3
              style={{
                fontSize: isMobile ? "3rem" : "",
                bottom: 0,
                margin: 0,
                marginTop: "5%",
              }}
            >
              {t("signup.comp-contact.title1")}
            </h3>
            <SelectionInput
              onChange={(event) => handleLandlordAddressChange(event)}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              options={[
                { label: "signup.comp-contact.mrs", value: "ms" },
                { label: "signup.comp-contact.mr", value: "mr" },
                { label: "signup.comp-contact.other", value: "other" },
              ]}
            ></SelectionInput>
            <h3
              style={{
                fontSize: isMobile ? "3rem" : "",
                bottom: 0,
                margin: 0,
                marginTop: "5%",
              }}
            >
              {t("signup.comp-contact.names")}
            </h3>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder="signup.comp-contact.enter"
              value={localStorage.getItem("funnel_landlord_name")}
              onChange={(event) => handleLandlordNameChange(event)}
            ></Input>

            <h3
              style={{
                fontSize: isMobile ? "3rem" : "",
                bottom: 0,
                margin: 0,
                marginTop: "4%",
              }}
            >
              Email
            </h3>
            <Input
              onEnter={() => {}}
              style={{ width: "50%", fontWeight: 500, marginTop: "1%" }}
              placeholder={t("signup.comp-contact.email-ex")}
              value={localStorage.getItem("funnel_landlord_email")}
              onChange={(event) => handleLandlordEmailChange(event)}
            ></Input>
          </div>
        )}

        <h5
          style={{
            fontSize: isMobile ? "2rem" : "",
            bottom: 0,
            marginTop: "1%",
          }}
        >
          {error}
        </h5>
        <Button
          style={{
            backgroundColor: "#69ADFF",
            fontWeight: 600,
            width: isMobile ? "20rem" : "25%",
            height: isMobile ? "7rem" : "3rem",
          }}
          active={validInput}
          onClick={continueClicked}
          label={t("signup.comp-contact.continue")}
        ></Button>
      </Container>
    </div>
  );
};
