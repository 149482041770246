import React, { createContext, useContext, useEffect, useState } from "react";
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
} from "amazon-cognito-identity-js";
import CognitoUserPool from "./configuration.js";
import { getStageConfig } from "../config/config.js";
import axiosInstance from "../axiosInstance.js";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const config = getStageConfig();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [jwtToken, setJwtToken] = useState(
    localStorage.getItem("jwtToken") || null
  );

  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken")
  );

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      checkTokenExpiry();
    }, 60000);

    const storedJwtToken = localStorage.getItem("jwtToken");
    const storedUser = localStorage.getItem("user");

    if (storedUser && storedJwtToken) {
      try {
        setUser(JSON.parse(storedUser));
        setJwtToken(storedJwtToken);
      } catch (error) {
        localStorage.removeItem("user");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("jwtToken");
      }
    }
    return () => clearInterval(interval);
  }, [jwtToken, refreshToken]);

  const getUserByEmail = async (email, jwt) => {
    try {
      const response = await axiosInstance.get(`/user/${email}`);

      setUser(response.data);
      localStorage.setItem("user", JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      console.error(`error fetching user: ${error}`);
      setUser(null);
    }
  };

  const signIn = async (email, password) => {
    console.log("attempting sign in");
    try {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: CognitoUserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(authDetails, {
          onSuccess: async (result) => {
            console.log("user authenticated");

            const jwt = result.getIdToken().getJwtToken();
            const refreshToken = result.getRefreshToken().getToken();
            setJwtToken(jwt);
            setRefreshToken(refreshToken);
            localStorage.setItem("jwtToken", jwt);
            localStorage.setItem("refreshToken", refreshToken);

            try {
              const userDetails = await getUserByEmail(email, jwt);
              setUser(userDetails);
              localStorage.setItem("user", JSON.stringify(userDetails));

              resolve({
                type: "",
                authError: false,
                redirect: "/app",
              });
            } catch (error) {
              reject({
                type: "fetch_user_error",
                authError: true,
                redirect: "/login",
              });
            }
          },
          onFailure: (error) => {
            setUser(null);
            reject({
              type: "denied",
              authError: true,
            });
          },
        });
      });
    } catch (error) {
      console.log("error occured");
      return {
        error: "",
        authError: true,
      };
    }
  };

  const signOut = async () => {
    console.log("signing out...");

    try {
      const cognitoUser = new CognitoUser({
        Username: user.email,
        Pool: CognitoUserPool,
      });

      cognitoUser.signOut();
      setUser(null);
      setJwtToken(null);
      setRefreshToken(null);
      localStorage.removeItem("user");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("jwtToken");
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const checkTokenExpiry = async () => {
    if (!jwtToken) return;

    const decodedToken = jwtDecode(jwtToken);
    const currentTime = Date.now() / 1000;

    if (decodedToken.exp - currentTime < 60) {
      try {
        const cognitoUser = await getCurrentUser();
        if (!cognitoUser) {
          throw new Error("No user found");
        }

        const newToken = await new Promise((resolve, reject) => {
          cognitoUser.refreshSession(
            new CognitoRefreshToken({ RefreshToken: refreshToken }),
            (err, session) => {
              if (err) {
                reject(err);
              } else {
                resolve(session.getIdToken().getJwtToken());
              }
            }
          );
        });

        setJwtToken(newToken);
      } catch (error) {
        console.error("Error refreshing token", error);
        await signOut(user.email);
      }
    }
  };

  return (
    <AuthContext.Provider value={{ user, jwtToken, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const getCurrentUser = () => {
  const user = CognitoUserPool.getCurrentUser();

  if (user) {
    return new Promise((resolve, reject) => {
      user.getSession((err, session) => {
        if (err) {
          reject(err);
        } else if (session.isValid()) {
          resolve(user);
        } else {
          reject("Session is invalid");
        }
      });
    });
  } else {
    return Promise.reject("No user is currently logged in");
  }
};
