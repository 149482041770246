import {
  BlurredBackground,
  CancelButton,
  Container,
  Button,
  Input,
  SelectionInput,
} from "../shared/components";
import { useEffect, useState } from "react";
import { insertName, mapReason } from "../shared/helpers";
import { useAuth } from "../Auth/provider";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import { createMessage } from "./helpers.ts";
import { Step } from "./components/steps.jsx";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInstance.js";

export const Send = ({
  options,
  landlordInformation,
  externalBackgroundStyle,
  reason,
  caseId,
  onCancel,
}) => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t, i18n } = useTranslation();

  const { user } = useAuth();
  const [continueClicked, setContinueClicked] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState(
    "vermieter@mails.smartmieter.com"
  );
  const [contactAddress, setContactAddress] = useState("mrs");
  const [index, setIndex] = useState(0);
  const [templateMessages, setTemplateMessages] = useState([]);
  const [response, setResponse] = useState("");
  const [message, setMessage] = useState("");
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [currentStep, setCurrentStep] = useState(2);

  const navigate = useNavigate();

  const backgroundStyle = {
    width: isMobile ? "85vw" : "65vw",
    height: isMobile ? "70vh" : "60vh",
    borderRadius: "30px",
    backgroundColor: "white",
    zIndex: 10,
    position: "absolute",
    top: "50%",
    left: isMobile ? "20%" : "50%",
    transform: isMobile ? "translate(10%, 20%)" : "translate(30%, 30%)",
    display: "grid",
    gridTemplateColumns: "30% 70%",
    ...externalBackgroundStyle,
  };

  const titleStyle = {
    marginLeft: "10%",
    marginTop: isMobile ? "12%" : "6%",
  };

  const subtitleStyle = {
    marginLeft: "10%",
    color: "#959595",
    top: 0,
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axiosInstance.get(`/templates`);

      if (result.status === 200) {
        setMessage(
          insertName(result.message, landlordInformation.name ?? contactName)
        );
      }
      setTemplateMessages(result.data);
    };
    // fetchData();
  }, [message, index]);

  const getMessage = async () => {
    console.log(
      `mappedReason: ${mapReason(reason)[user?.language.substring(0, 2)]}`
    );
    try {
      const messageBody = await createMessage({
        language: user?.language.substring(0, 2),
        landlord_name: landlordInformation?.name ?? contactName,
        landlord_email: landlordInformation?.email ?? contactEmail,
        landlord_address: landlordInformation?.address ?? contactAddress,
        reason: mapReason(reason)[user?.language.substring(0, 2)],
        compensation_request: mapReason(reason).compensation_estimate,
        tenant_name: user?.fullName,
      });

      const greeting =
        user?.language.substring(0, 2) === "de"
          ? contactAddress === "mrs"
            ? "Sehr geehrte Frau"
            : "Sehr geehrter Herr"
          : contactAddress === "mr"
            ? "Dear Mr."
            : "Dear Mrs";

      const message = `${greeting} ${contactName},\n\n${messageBody.replace(RegExp("<br><br>", "g"), " ")}\n\n`; // quick'n'dirty fix for line breaks
      setTemplateMessages([message]);
      setMessage(messageBody.replace(RegExp("<br><br>", "g"), " "));
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const handleDismissDialog = () => {
    setDialogError(false);
  };

  const handleContinueClicked = async () => {
    if (!contactEmail) {
      setResponse(t("cases-send.name"));
    } else if (!contactEmail) {
      setResponse(t("cases-send.email"));
    } else if (validateEmail()) {
      setContinueClicked(true);
    } else {
      setResponse(t("cases-send.invalid-email"));
    }

    setCurrentStep((prevStep) => {
      const newStep = prevStep + 1;
      return newStep;
    });

    await getMessage();
  };

  const validateEmail = () => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contactEmail);

  const handleIndex = () => {
    setMessage(templateMessages[index]);
  };

  useAnalytics();

  const sendMessage = async () => {
    console.log("sending message");
    let result;

    try {
      result = await axiosInstance.post(`/correspondence/${caseId}`, {
        type: "landlord",
        caseId,
        message,
        reason,
        tenantId: user?.tenantId,
        recipientName: landlordInformation?.name ?? contactName,
        recipientEmail: landlordInformation?.email ?? contactEmail,
        recipientAddress: landlordInformation?.address ?? contactAddress,
      });

      setDialogSuccess(true);
      setDialogTitle(t("cases-send.sent"));
      setDialogMessage(t("cases-send.inform"));
      //setResponse(t("cases-send.sent") + t("cases-send.inform"));
      setCurrentStep(4);
      setTimeout(() => navigate("/cases"), 5000);
    } catch (error) {
      setDialogError(true);
      setDialogTitle(t("cases-send.error"));
      setResponse(t("cases-send.error"));
    }
  };

  const handleAddressChange = (event) => {
    setContactAddress(event.target.value);
  };

  const handleEmailChange = (event) => {
    // BETA: add correct email back setContactEmail(event.target.value);
  };

  const handleNameChange = (event) => {
    setContactName(event.target.value);
  };

  return (
    <div style={{ position: "absolute" }}>
      <BlurredBackground></BlurredBackground>
      <div style={backgroundStyle}>
        <div
          style={{
            backgroundColor: "#F8F8F8",
            borderRadius: "30px 0px 0px 30px",
            width: "100%",
            height: "100%",
          }}
        >
          <div style={{ marginLeft: "5%", marginTop: "20%" }}>
            <Step step={1} currentStep={currentStep}></Step>
            <Step step={2} currentStep={currentStep}></Step>
            <Step step={3} currentStep={currentStep}></Step>
            <Step step={4} currentStep={currentStep}></Step>
          </div>
        </div>
        <div>
          <Container
            style={{
              width: "100%",
              margin: 0,
              rowGap: 0,
              columnGap: 0,
              marginTop: "2%",
            }}
            itemWidth="auto"
            gap="0"
            columnWidths={["80%", "20%"]}
            alignItems="auto"
            itemsPerRow={2}
          >
            <h2 style={titleStyle}>{t("cases-send.send")}</h2>

            <CancelButton
              style={{ marginTop: isMobile ? "35%" : "20%" }}
              onClick={() => onCancel()}
            ></CancelButton>
            {currentStep !== 4 ? (
              <h3 style={subtitleStyle}>{t("cases-send.choose")}</h3>
            ) : undefined}
          </Container>

          {currentStep === 2 ? (
            <div
              style={{
                marginLeft: "8%",
                marginTop: "3%",
              }}
            >
              <h4 style={{}}>{t("cases-send.landlord-name")}</h4>
              <Container
                style={{ width: isMobile ? "90%" : "70%" }}
                itemsPerRow={2}
                itemWidth="auto"
                columnWidths={["20%", "75%"]}
              >
                <SelectionInput
                  onChange={(event) => handleAddressChange(event)}
                  options={[
                    { label: t("cases-send.title1"), value: "ms" },
                    { label: t("cases-send.title2"), value: "mr" },
                    { label: t("cases-send.other"), value: "other" },
                  ]}
                ></SelectionInput>
                <Input
                  onChange={(event) => handleNameChange(event)}
                  onEnter={() => {}}
                  placeholder="Maximilian Mustermann"
                  value={contactName}
                ></Input>
              </Container>

              <h4
                style={{
                  marginTop: isMobile ? "5%" : "24px",
                }}
              >
                {t("cases-send.landlord-email")}
              </h4>
              <Input
                type="email"
                onChange={(event) => handleEmailChange(event)}
                onEnter={() => {}}
                style={{ width: isMobile ? "90%" : "70%" }}
                value={
                  user?.beta ? "vermieter@mails.smartmieter.com" : contactEmail
                }
                placeholder="mustermann@gmail.com"
              ></Input>
            </div>
          ) : currentStep === 3 ? (
            <h5
              style={{
                marginLeft: "8%",
                width: "80%",
                fontSize: "12px",
                fontStyle: "italic",
                whiteSpace: "pre-line",
              }}
            >
              {templateMessages[0]}
            </h5>
          ) : (
            <p style={{ width: "60%", marginLeft: "8%" }}>
              {t("cases-send.confirmation")}
            </p>
          )}

          <h4
            style={{
              marginLeft: "8%",
              width: "80%",
            }}
          >
            {response}
          </h4>

          {currentStep !== 4 ? (
            <Button
              style={{
                width: isMobile ? "50%" : "224px",
                height: isMobile ? "7rem" : "56px",
                position: "absolute",
                borderRadius: "100px",
                bottom: "24px",
                marginLeft: "5%",
                fontSize: "16px",
              }}
              onClick={() =>
                landlordInformation
                  ? sendMessage()
                  : continueClicked
                    ? sendMessage()
                    : handleContinueClicked()
              }
              label={
                continueClicked
                  ? t("cases-send.send-message")
                  : t("cases-send.continue")
              }
            ></Button>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
