import { Elements } from "@stripe/react-stripe-js";
import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Checkout } from "../../Payment";
import { loadStripe } from "@stripe/stripe-js/dist";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "../../shared/mobile";

export const Payment = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();
  localStorage.setItem("funnel_step", 6);

  useAnalytics();

  const stripePromise = loadStripe(
    "pk_test_51QGfVnBQODzeSQeQ0lMUgcJq98ZyVbRKADz5qppJLUWUoMDMTRihfgQELVgLhN2b3w4bTpcw06fCVrTWLKNi8Xd100M5Ex94cm"
  );

  return (
    <div>
      <Container
        style={{ marginLeft: isMobile ? "4rem" : "10rem", marginTop: "5%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <h2
          style={{
            bottom: 0,
            marginLeft: isTablet ? "3rem" : "8rem",
          }}
        >
          {t("signup.comp-payment.text1")}
        </h2>
        <div style={{ marginLeft: "10rem" }}>
          <Elements stripe={stripePromise}>
            <Checkout
              return_url={"https://smartmieter.com/funnel?step=7"}
            ></Checkout>
          </Elements>
        </div>
      </Container>
    </div>
  );
};
