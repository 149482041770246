import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { Container } from "./components";
import { useAuth } from "../Auth/provider";
import { storeAnalyticsEvent, useMouseflow } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "./mobile";

export const Header = ({
  onAccountClick,
  parentRef,
  nestedRef,
  hidden = false,
  hideSignUp = false,
  staticColor = false,
}) => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [bgColor, setBgColor] = useState("");
  const { user } = useAuth();

  let scrollTimeout = null;
  const color = staticColor
    ? "black"
    : bgColor === "rgba(0, 0, 0, 0)"
      ? "black"
      : "white";

  let newColor;
  let parentColor;
  useEffect(() => {
    if (!parentRef || !parentRef.current) return;
    const handleScroll = () => {
      if (parentRef.current && nestedRef.current) {
        parentColor = window.getComputedStyle(
          parentRef.current
        ).backgroundColor;
        newColor = window.getComputedStyle(nestedRef.current).backgroundColor;

        const nestedRefRect = nestedRef.current.getBoundingClientRect();

        const isNestedRefVisible =
          nestedRefRect.top < window.innerHeight && nestedRefRect.bottom > 0;
        if (!isNestedRefVisible) {
          setBgColor(parentColor);
        } else {
          setBgColor(newColor);
        }
      }

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
    };
  }, [parentRef, nestedRef]);

  const headerStyles = {
    position: "fixed",
    backgroundColor:
      bgColor === "rgba(0, 0, 0, 0)"
        ? "rgba(255,255,255, 0.4)"
        : "rgba(255,255,255, 0)",
    width: "100%",
    zIndex: 20,
    backdropFilter: "blur(10px)",
    webkitBackdropFilter: "blur(10px)",
    borderBottom: bgColor === "rgba(0, 0, 0, 0)" ? "1px solid lightgray" : "",
    top: 0,
    margin: 0,
    padding: 0,
  };

  const link = {
    de: "https://forms.gle/Hq4h4pxbwDMC6XbR7",
    en: "https://forms.gle/9cDUxTSaisdzgqej6",
  }[i18n.language];

  const handleFeedbackClick = () => {
    storeAnalyticsEvent({
      category: location.pathname + location.search,
      action: "clicked",
      label: "Feedback",
    });

    window.location.href = link;
  };

  useMouseflow();

  return (
    <header style={headerStyles}>
      <Container
        itemsPerRow={3}
        columnWidths={
          isMobile
            ? ["50%", "0%", "50%"]
            : isTablet
              ? ["25%", "50%", "25%"]
              : ["25%", "55%", "20%"]
        }
        itemWidth={"100%"}
        alignItems="auto"
        style={{
          width: "100%",
          top: 0,
          margin: 0,
          padding: 0,
          alignItems: "flex-start",
        }}
      >
        {/**TODO: remove style for LogoButton with SNM-31 */}

        <LogoButton color={color} isMobile={isMobile} isTablet={isTablet} />
        {!isMobile && !hidden ? (
          isTablet ? (
            <Container
              itemsPerRow={5}
              itemWidth="auto"
              style={{
                width: "100%",
                display: "flex",
                marginTop: "3%",
                justifyContent: "center",
              }}
            >
              <HeaderButton
                style={{ font: "1rem" }}
                url={user ? "/contract" : "/alpha"}
                color={color}
              >
                {/*ALPHA: CHANGE URL TO FUNNEL OR REGISTER*/}
                {user
                  ? t("shared-header.contracts")
                  : t("shared-header.gethelp")}
              </HeaderButton>
              <HeaderButton
                style={{ font: "1rem" }}
                url={user ? "/chat" : "/"}
                color={color}
              >
                {" "}
                {/** ADD MARKETING PAGE FOR CONTRACTS */}
                {user ? t("shared-header.chat") : t("shared-header.contracts")}
              </HeaderButton>
              <HeaderButton
                style={{ font: "1rem" }}
                url={user ? "/cases" : "/"}
                color={color}
              >
                {" "}
                {/** ADD MARKETING PAGE FOR SERVICECHARGES */}
                {user
                  ? t("shared-header.cases")
                  : t("shared-header.servicecharges")}
              </HeaderButton>
              <HeaderButton
                style={{ font: "1rem" }}
                url={user ? "/cases" : "/about"}
                color={color}
              >
                {user
                  ? t("shared-header.servicecharges")
                  : t("shared-header.aboutus")}
              </HeaderButton>
            </Container>
          ) : (
            <Container
              itemsPerRow={5}
              itemWidth="auto"
              style={{
                width: "100%",
                display: "flex",
                marginTop: "2%",
                gap: "3rem",
              }}
            >
              <HeaderButton url={user ? "/contract" : "/alpha"} color={color}>
                {/*ALPHA: CHANGE URL TO FUNNEL OR REGISTER*/}
                {user
                  ? t("shared-header.contracts")
                  : t("shared-header.gethelp")}
              </HeaderButton>
              <HeaderButton url={user ? "/chat" : "/"} color={color}>
                {" "}
                {/** ADD MARKETING PAGE FOR CONTRACTS */}
                {user ? t("shared-header.chat") : t("shared-header.contracts")}
              </HeaderButton>
              <HeaderButton url={user ? "/cases" : "/"} color={color}>
                {" "}
                {/** ADD MARKETING PAGE FOR SERVICECHARGES */}
                {user
                  ? t("shared-header.cases")
                  : t("shared-header.servicecharges")}
              </HeaderButton>
              <HeaderButton
                url={user ? "/servicecharges" : "/about"}
                color={color}
              >
                {user
                  ? t("shared-header.servicecharges")
                  : t("shared-header.aboutus")}
              </HeaderButton>
            </Container>
          )
        ) : undefined}
        {user?.beta ? (
          <div
            onClick={handleFeedbackClick}
            style={{
              marginTop: isMobile ? "3%" : "2%",
              marginBottom: isMobile ? "" : "2%",
              width: isMobile ? "8rem" : "11rem",
              height: isMobile ? "5rem" : "3rem",
              backgroundColor: "black",
              borderRadius: isMobile ? "30px" : "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                zIndex: 10,
                color: "white",
                fontWeight: 600,
                textAlign: "center",
                fontSize: "1.5rem",
              }}
            >
              {t("feedback")}
            </p>
          </div>
        ) : !hideSignUp ? (
          // BETA CHANGE BUTTON LABEL
          <SignUpButton
            color={color}
            loggedIn={user}
            isMobile={isMobile}
            label={user ? t("shared-header.account") : t("shared-header.start")}
          />
        ) : undefined}
      </Container>
    </header>
  );
};

const HeaderButton = ({ url, children, color, style }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(url); // Redirect to the specified URL
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        border: "none",
        appearance: "none",
        backgroundColor: "transparent",
        fontSize: "1.25rem",
        cursor: "pointer",
        whiteSpace: "nowrap",
        fontWeight: 500,
        color,
        ...style,
      }}
    >
      {children}
    </button>
  );
};

const LogoButton = ({ style, url, color, isMobile, isTablet }) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleClick = () => {
    if (!user || !user.accountStatus === "inactive") {
      navigate("/");
    } else {
      navigate("/app");
    }
    storeAnalyticsEvent({
      category: "/home",
      action: "clicked",
      label: "Logo Clicked",
    });
  };

  const desktopStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 700,
    fontSize: "2rem",
    marginLeft: "20%",
    padding: 0,
    marginTop: "3%",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  const tabletStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 700,
    fontSize: "2rem",
    marginLeft: "10%",
    padding: 0,
    marginTop: "3%",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };
  const mobileStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 900,
    fontSize: "6rem",
    fontStyle: "italic",
    textAlign: "left",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: 0,
    marginLeft: "10%",
    marginTop: "-1rem", // Pulls it closer to the top without absolute
  };
  return (
    <button
      onClick={() => handleClick()}
      style={{
        color,
        ...(isMobile ? mobileStyle : isTablet ? tabletStyle : desktopStyle),
        ...style,
      }}
    >
      {isMobile ? "s" : "smartmieter"}
    </button>
  );
};

const SignUpButton = ({ loggedIn, label, color, isMobile }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const isAccountOpen = searchParams.get("account") === "true";

  const handleClick = () => {
    const newParams = new URLSearchParams(searchParams);
    if (loggedIn) {
      if (isAccountOpen) {
        newParams.delete("account");
      } else {
        newParams.set("account", "true");
      }
      setSearchParams(newParams);
    } else {
      navigate("/alpha");
    }
  };

  const computedStyles = {
    white: { color: "white", backgroundColor: "white" },
    black: { color: "black", backgroundColor: "#69ADFF" },
  };

  return (
    <button
      onClick={() => handleClick()}
      style={{
        width: isMobile ? "8rem" : "11rem",
        height: isMobile ? "5rem" : "2.75rem",
        fontSize: isMobile ? (loggedIn ? "2rem" : "2rem") : "1.5rem",
        backgroundColor: "69ADFF",
        border: "none",
        fontStyle: loggedIn ? "" : "italic",
        borderRadius: isMobile ? "30px" : "100px",
        marginTop: isMobile ? "3%" : "2%",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...computedStyles[color],
        background: "linear-gradient(135deg, #D469FF 0%, #FFD3CB 100%)",
      }}
    >
      {label}
    </button>
  );
};
