import { Pill } from "../../../App/components";
import { Container } from "../../../shared/components";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "../../../shared/mobile";

export const Problems = ({ onClick }) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const iGot99ProblemsAndABitchAintOne = [
    t("home.comp-problems.prob1"),
    t("home.comp-problems.prob2"),
    t("home.comp-problems.prob3"),
    t("home.comp-problems.prob4"),
    t("home.comp-problems.prob5"),
    t("home.comp-problems.prob6"),
    t("home.comp-problems.prob7"),
  ];

  return (
    <div
      style={{
        height: isMobile ? "55rem" : isTablet ? "40rem" : "30rem" /*50vh*/,
        position: "relative",
        marginTop: isMobile ? "5rem" : "10rem",
        marginBottom: isMobile ? "8rem" : "",
        marginRight: "5%",
        marginLeft: "5%",
      }}
    >
      <h1
        style={{
          fontSize: isMobile ? "2rem" : "",
          textAlign: "center",
          bottom: 0,
        }}
      >
        {t("You know your problem. We know the solution.")}
      </h1>
      <h2
        style={{
          fontSize: isMobile ? "1.5rem" : "",
          textAlign: "center",
        }}
      >
        {t(
          "Explain your problem and we'll look to solve your issue as fast as possible."
        )}
      </h2>
      <Container
        itemWidth="auto"
        itemsPerRow={4}
        columnWidths={["25%", "25%", "25%", "25%"]}
        style={{
          position: "absolute",
          width: "90%",
          left: "5%",
          right: "5%",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        {iGot99ProblemsAndABitchAintOne.map((input, index) => (
          <Pill
            key={index}
            label={input}
            style={{
              minWidth: isTablet ? "95%" : "88%",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
            textStyle={{ fontSize: isMobile ? "1rem" : "", fontWeight: 500 }}
            onClick={onClick}
          />
        ))}
      </Container>
    </div>
  );
};
