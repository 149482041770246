import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { BlurredBackground, CancelButton, Input } from "./components";
import { Styles } from "../Servicecharge/styles";
import PropTypes from "prop-types";
import { useState } from "react";
import axiosInstance from "../axiosInstance";
import { AnalysisCard } from "./analysis-card";
import { useResponsiveStyles } from "./mobile";

export const Result = ({
  isMobile,
  isTablet,
  type = "servicecharge",
  children,
  analysis,
  onCancel,
  loading = true,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <BlurredBackground />
      <div
        style={{ ...Styles().modalStyle, width: isMobile ? "80vw" : "40vw" }}
      >
        <div style={Styles().modalHeaderStyle}>
          <h1>{t(`${type}.result.title`)}</h1>
          <CancelButton onClick={onCancel} />
        </div>

        {loading && (
          <div style={Styles().loadingStyle}>
            <div style={Styles().spinnerStyle} />
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
            <p style={{ color: "#666" }}>{t(`${type}.result.analyzing`)}</p>
          </div>
        )}

        {analysis && !loading && (
          <AnalysisCard
            cardStyles={{ marginLeft: 0 }}
            state={analysis.status.toLowerCase()}
            analysis={analysis}
            type={type}
            onClick={() => navigate(`/${type}/${analysis.id}`)}
          />
        )}
      </div>
    </div>
  );
};

Result.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  children: PropTypes.node,
  analysis: PropTypes.shape({
    status: PropTypes.oneOf(["INVALID", "ILLEGAL", "VALID"]),
  }),
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export const Upload = ({
  isMobile,
  isTablet,
  children,
  type = "servicecharge",
  onCancel,
  pollServicechargeStatus,
}) => {
  const [fileName, setFileName] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [dialogError, setDialogError] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const { t } = useTranslation();

  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const handleSubmit = async () => {
    if (!pdfFile) {
      setDialogError(true);
      setDialogMessage(t(`${type}.select`));
      return;
    }

    if (!fileName) {
      setDialogError(true);
      setDialogMessage(t(`${type}.enter-name`));
      return;
    }

    const formData = new FormData();
    formData.append("pdfFile", pdfFile);

    try {
      const path = type === "servicecharge" ? "service-charges" : "contracts";
      const result = await axiosInstance.post(
        `/${path}?fileName=${fileName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (result.status === 202) {
        const servicechargeId = result.data.id;
        pollServicechargeStatus(servicechargeId);
      }
    } catch (error) {
      console.error(`Error occurred while uploading ${type}: ${error}`);
    }
  };

  return (
    <div>
      <BlurredBackground />
      <div style={{ ...Styles().modalStyle }}>
        <div style={Styles().modalHeaderStyle}>
          <h1
            style={{
              marginBottom: 0,
              fontSize: isMobile ? "2rem" : "",
              marginTop: 0,
              maxWidth: isMobile ? "1rem" : "",
            }}
          >
            {t(`upload.${type}.title`)}
          </h1>
          <CancelButton
            imgStyle={{
              maxWidth: isMobile ? "2.5rem" : "",
              maxHeight: isMobile ? "2.5rem" : "",
              marginRight: isMobile ? "5%" : "",
            }}
            onClick={onCancel}
          />
        </div>

        {pdfFile ? (
          <div style={Styles().filePreviewStyle}>
            <span style={{ color: "#2D5B9E", fontWeight: 500 }}>
              {fileName ?? pdfFile.name}
            </span>
            <button
              onClick={() => setPdfFile(null)}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                padding: "4px",
                color: "#2D5B9E",
              }}
            >
              ✕
            </button>
          </div>
        ) : (
          <div>
            <input
              id="fileUpload"
              type="file"
              accept=".pdf"
              onChange={(e) => {
                if (e.target.files[0]) {
                  setPdfFile(e.target.files[0]);
                }
                e.target.value = "";
              }}
              style={{ display: "none" }}
            />
            <div
              onClick={() => document.getElementById("fileUpload").click()}
              style={Styles().dropzoneStyle}
            >
              <img
                src={require("../shared/assets/contract.png")}
                style={{
                  width: "2rem",
                  height: isMobile ? "1.5rem" : "2rem",
                  marginBottom: "0.5rem",
                }}
                alt="Upload icon"
              />
              <p style={{ margin: 0, color: "#666" }}>
                {t(`upload.${type}.upload`)}
              </p>
            </div>
          </div>
        )}

        <h2
          style={{
            color: "gray",
            fontWeight: 400,
            fontSize: "1.1rem",
            marginTop: "2rem",
            marginBottom: "1rem",
          }}
        >
          {t(`upload.${type}.nickname.title`)}
        </h2>
        <h2
          style={{
            marginBottom: "1rem",
            fontSize: "10",
            marginBottom: 0,
            marginTop: "1rem",
          }}
        >
          {children}
        </h2>
        <Input
          style={{
            marginTop: 0,
            width: isMobile ? "80%" : "60%",
            fontWeight: 500,
            border: "1px solid lightgray",
            marginBottom: "1rem",
            height: isMobile ? "4rem" : "3rem",
            fontSize: isMobile ? "1rem" : "",
          }}
          placeholder={t(`upload.${type}.nickname.placeholder`)}
          value={fileName}
          onChange={handleFileNameChange}
        />
        <button
          style={{
            border: "none",
            width: isMobile ? "80%" : isTablet ? "15vw" : "12vw",
            height: isMobile ? "4rem" : "3.5rem",
            borderRadius: "20px",
            backgroundColor: fileName ? "black" : "lightgray",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            marginTop: "3%",
            cursor: "pointer",
          }}
          onClick={handleSubmit}
          disabled={!fileName}
        >
          <h2
            style={{
              color: "white",
              marginLeft: "10%",
              marginBottom: 0,
              fontSize: "1.5rem",
            }}
          >
            {t("common.analyze")}
          </h2>
          <img
            src={require("../shared/assets/continue-arrow.png")}
            style={{
              width: isMobile ? "44px" : "22px",
              height: isMobile ? "40px" : "20px",
              objectFit: "cover",
              marginRight: "5%",
            }}
            alt="Continue arrow"
          />
        </button>
      </div>
    </div>
  );
};

Upload.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  pollServicechargeStatus: PropTypes.func.isRequired,
};
