import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useResponsiveStyles } from "../../shared/mobile";
import { Account } from "../../Account";
import { SEO } from "../../shared/components/SEO";
import "../../text.css";

export const Terms = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  useAnalytics();
  return (
    <>
      <SEO namespace="marketing" page="terms" path="/terms" />
      <div style={{ overflowX: "hidden" }}>
        <Header staticColor={true}></Header>
        <Container
          itemWidth="100%"
          columnWidths={["100%"]}
          style={{ width: "100%" }}
        >
          <h1
            style={{
              textAlign: "center",
              marginTop: isMobile ? "50%" : "25%",
            }}
          >
            {t("legal.terms-index.title1")}
          </h1>
          <h3
            style={{
              color: "#AAA6A6",
              width: isMobile ? "80%" : "50%",
              marginLeft: isMobile ? "10%" : "25%",
            }}
          >
            {t("legal.terms-index.text1")}
          </h3>
          <AGB></AGB>
        </Container>
        <Footer></Footer>
        <Account></Account>
      </div>
    </>
  );
};

const Sectiontitle = ({ children }) => {
  const { isMobile, isTablet } = useResponsiveStyles();

  return (
    <h3
      style={{
        fontSize: isMobile ? "4rem !important" : "2rem",
        margin: "1em 0",
        fontWeight: "bold",
      }}
    >
      {children}
    </h3>
  );
};

const AGB = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  return (
    <div
      style={{
        width: isMobile ? "80%" : "50%",
        marginLeft: isMobile ? "10%" : "25%",
        marginTop: "20%",
        marginBottom: "10%",
        fontSize: isMobile ? "2.5rem" : "1rem",
      }}
    >
      <Sectiontitle>{t("legal.terms-index.subtitle1")}</Sectiontitle>
      <h4>{t("legal.terms-index.text2")}</h4>

      <Sectiontitle>{t("legal.terms-index.subtitle2")}</Sectiontitle>
      <h4>{t("legal.terms-index.text3")}</h4>

      <Sectiontitle>{t("legal.terms-index.subtitle3")}</Sectiontitle>
      <h4>{t("legal.terms-index.text4")}</h4>

      <Sectiontitle>{t("legal.terms-index.subtitle4")}</Sectiontitle>
      <h4>{t("legal.terms-index.text5")}</h4>

      <Sectiontitle>{t("legal.terms-index.subtitle5")}</Sectiontitle>
      <h4>
        {t("legal.terms-index.text6")}
        <br />
        {t("legal.terms-index.text7")}
        <br />
        {t("legal.terms-index.text8")}
      </h4>

      <Sectiontitle>{t("legal.terms-index.subtitle6")}</Sectiontitle>
      <h4>{t("legal.terms-index.text9")}</h4>

      <Sectiontitle>{t("legal.terms-index.subtitle7")}</Sectiontitle>
      <h4>{t("legal.terms-index.text10")}</h4>

      <Sectiontitle>{t("legal.terms-index.subtitle8")}</Sectiontitle>
      <h4>
        {t("legal.terms-index.text11") + " "}
        <a href="/privacy" style={{ color: "black" }}>
          {t("legal.terms-index.title2")}
        </a>
        .
      </h4>

      <Sectiontitle>{t("legal.terms-index.subtitle9")}</Sectiontitle>
      <h4>{t("legal.terms-index.text12")}</h4>

      <Sectiontitle>{t("legal.terms-index.subtitle10")}</Sectiontitle>
      <h4>{t("legal.terms-index.text13")}</h4>

      <Sectiontitle>{t("legal.terms-index.subtitle11")}</Sectiontitle>
      <h4>{t("legal.terms-index.text14")}</h4>

      <h4>{"Stand/Status: December 10, 2024"}</h4>
    </div>
  );
};

export default AGB;
