import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "../../shared/mobile";

export const ChatInput = ({
  input,
  inputChanged,
  sendMessage,
  contractClicked,
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const styles = {
    display: "flex",
    padding: "10px",
    position: "fixed",
    width: "100%",
    marginBottom: 0,
    bottom: 0,
    backgroundColor: "#ffffff",
    borderTop: "1px solid #ddd",
  };

  const inputStyle = {
    flex: 1,
    padding: "10px",
    outline: "none",
    border: "1px solid #ddd",
    marginLeft: "10px",
    width: isMobile ? "100%" : "",
    paddingLeft: isMobile ? "10px" : "20px",
    borderRadius: isMobile ? "30px" : "20px",
    fontSize: isMobile ? "1.5rem" : isTablet ? "1.5rem" : "1rem",
    height: isMobile ? "4rem" : isTablet ? "6rem" : "",
    width: isMobile ? "10rem" : "",
  };

  const buttonStyle = {
    marginLeft: "10px",
    padding: isMobile ? "10px" : "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: isMobile ? "100px" : "20px",
    fontSize: isMobile ? "2rem" : isTablet ? "2rem" : "1rem",
    cursor: "pointer",
    transition: "background-color 0.2s",
    height: isMobile ? "4rem" : isTablet ? "6rem" : "",
    width: isMobile ? "8rem" : isTablet ? "12rem" : "",
  };

  return (
    <div style={styles}>
      <button
        style={{
          ...buttonStyle,
          marginLeft: 0,
          width: isMobile ? "4rem" : isTablet ? "6rem" : "",
          borderRadius: "40%",
          backgroundColor: "black",
        }}
        onClick={contractClicked}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            loading="lazy"
            src={require("../../shared/assets/upload-contract-white.png")}
            alt="icon"
            style={{
              width: isMobile ? "3rem" : isTablet ? "3rem" : "1.5rem",
              height: isMobile ? "3rem" : isTablet ? "3rem" : "1.5rem",
            }}
          />
        </div>
      </button>
      <input
        style={inputStyle}
        type="text"
        value={input}
        onChange={(e) => inputChanged(e.target.value)}
        placeholder={t("chat-chat.message")}
        onKeyDown={(e) => e.key === "Enter" && sendMessage()}
      />
      <button style={buttonStyle} onClick={sendMessage}>
        {t("chat-chat.send")}
      </button>
    </div>
  );
};
