import { useTranslation } from "react-i18next";
import { Header } from "../shared/header";
import { OTPInput } from "./components";
import { Button, Input } from "../shared/components";
import { useEffect, useState } from "react";
import axiosInstance from "../axiosInstance";
import { useNavigate, useParams } from "react-router-dom";

export const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { email } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  const confirmationCodeFromUrl = queryParams.get("confirmation-code");

  useEffect(() => {
    if (confirmationCodeFromUrl) {
      setConfirmationCode(confirmationCodeFromUrl);
      setPasswordVisible(true);
    }
  }, [confirmationCodeFromUrl]);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [password, setPassword] = useState("");
  const [result, setResult] = useState("");

  const confirmPasswordReset = async () => {
    try {
      const result = await axiosInstance.post(
        `/user/${email}/auth/confirm-reset`,
        {
          email,
          password,
          confirmationCode,
        }
      );

      if (result.status === 200) {
        setResult(t("auth-reset.success"));
        navigate("/login");
      }
    } catch (error) {
      console.error(`error occured: ${JSON.stringify(error.response.data)}`);
      setResult(t("auth-reset.error"));
      if (error.response) {
        if (error.response.data.errorCode === "CodeMismatchException") {
          setResult(t("auth-reset.mismatch-error"));
        } else if (error.response.data.errorCode === "ExpiredCodeException") {
          setResult(t("auth-reset.expired-error"));
        }
      }
    }
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleOTPChange = (confirmationCode) => {
    setPasswordVisible(true);
    setConfirmationCode(confirmationCode);
    setResult("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Header staticColor={true}></Header>
      <h1 style={{ textAlign: "center", marginTop: "20%" }}>
        {t("auth-reset.h1")}
      </h1>
      <h3 style={{ marginTop: 0 }}>{t("auth-reset.h2")}</h3>
      <OTPInput
        onComplete={(confirmationCode) => handleOTPChange(confirmationCode)}
      ></OTPInput>
      {passwordVisible ? (
        <div style={{ width: "19rem", justifyContent: "center" }}>
          <h4 style={{ marginTop: "10%" }}>{t("auth-reset.new-password")}</h4>
          <Input
            type="password"
            placeholder={t("auth-reset.new-password")}
            onChange={(e) => handlePasswordChange(e)}
          ></Input>

          <Button
            onClick={confirmPasswordReset}
            style={{ marginTop: "4%" }}
            label={t("auth-reset.reset")}
          ></Button>
          <h4>{result}</h4>
        </div>
      ) : undefined}
    </div>
  );
};
