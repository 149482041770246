import { useNavigate } from "react-router-dom";
import { Container, HyperLink, Button } from "./components";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "./mobile";

export const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const handleGetHelp = () => {
    // after BETA: /register
    navigate("/alpha");
  };

  const styles = {
    footer: {
      backgroundColor: "#16171D",
      color: "#FFFFFF",
      width: "100%",
      height: "40rem",
      display: "grid",
      alignItems: "center",
      position: "relative",
      bottom: 0,
    },
    content: {
      textAlign: "center",
    },
    text: {
      margin: 0,
    },
  };

  return (
    <footer style={styles.footer}>
      <Container
        itemWidth="inherit"
        style={{
          marginLeft: "5%",
          marginTop: "5%",
          marginRight: "5%",
          gap: "0px",
        }}
      >
        <p
          style={{
            color: "868080",
            fontSize: isMobile ? "1rem" : "1.4rem",
            textAlign: "left",
            bottom: 0,
            width: isMobile ? "80%" : "inherit",
          }}
        >
          {t("shared-footer.title1")}
        </p>
        <h1
          style={{
            fontSize: isMobile ? "2rem" : "",
            textAlign: "left",
            paddingTop: 0,
          }}
        >
          {t("shared-footer.title2")}
        </h1>
        <Button
          onClick={() => handleGetHelp()}
          style={{
            width: "15rem",
            height: isMobile ? "5rem" : "3rem",
            borderRadius: "10rem",
            float: "left",
            gap: "24px",
          }}
          label={t("shared-footer.text1")}
        ></Button>
      </Container>

      <h2
        style={{
          fontSize: isMobile ? "2rem" : "",
          textAlign: "left",
          marginLeft: "5%",
          marginTop: "5%",
          marginBottom: "2%",
        }}
      >
        smartmieter
      </h2>
      <Container
        itemsPerRow={3}
        alignItems="auto"
        itemWidth="auto"
        gap="0"
        margin="0"
        style={{
          width: isMobile ? "90%" : "70%",
          marginLeft: "5%",
          marginBottom: "5%",
        }}
      >
        <Container
          gap="10px"
          margin="0"
          itemWidth="auto"
          style={{ marginLeft: "0" }}
        >
          <h4
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              textAlign: "left",
              fontWeight: "500",
              textDecorationLine: "underline",
              textDecorationThickness: "0.5px",
            }}
          >
            Company
          </h4>
          <HyperLink
            onClick={() => {}}
            href={"/about"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
            }}
          >
            {t("shared-footer.text2")}
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/terms"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
            }}
          >
            {t("shared-footer.text3")}
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/privacy"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
            }}
          >
            {t("shared-footer.text4")}
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"/landlords"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
            }}
          >
            {t("shared-footer.text5")}
          </HyperLink>
        </Container>
        <Container gap="10px" margin="0" style={{ marginLeft: "20%" }}>
          <h4
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              textAlign: "left",
              fontWeight: "500",
              textDecorationLine: "underline",
              textDecorationThickness: "0.5px",
            }}
          >
            {t("shared-footer.text6")}
          </h4>
          <HyperLink
            onClick={() => {}}
            href={"/contact"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
            }}
          >
            {t("shared-footer.text7")}
          </HyperLink>
          {
            <HyperLink
              onClick={() => {}}
              href={"/contact"}
              style={{
                color: "white",
                textDecoration: "none",
                textAlign: "left",
                float: "left",
              }}
            >
              {t("shared-footer.text8")}
            </HyperLink>
          }
        </Container>
        <Container gap="10px" margin="0" style={{ marginLeft: "20%" }}>
          <h4
            style={{
              fontSize: isMobile ? "1rem" : "1.5rem",
              textAlign: "left",
              textDecorationLine: "underline",
              textDecorationThickness: "0.5px",
            }}
          >
            Socials
          </h4>
          <HyperLink
            onClick={() => {}}
            href={
              "https://www.instagram.com/getsmartmieter/"
            } /** add instagram url here */
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
            }}
            externalLink={true}
          >
            Instagram
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={
              "https://www.linkedin.com/company/smart-mieter/about/?viewAsMember=true"
            }
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
            }}
            externalLink={true}
          >
            LinkedIn
          </HyperLink>
          <HyperLink
            onClick={() => {}}
            href={"https://x.com/smart_mieter"}
            style={{
              color: "white",
              textDecoration: "none",
              textAlign: "left",
              float: "left",
            }}
            externalLink={true}
          >
            X/Twitter
          </HyperLink>
        </Container>
        <br></br> <br></br>
      </Container>
    </footer>
  );
};
