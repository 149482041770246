import { useNavigate } from "react-router-dom";
import { useAuth } from "./Auth/provider";
import { configureAxiosInterceptor } from "./axiosInstance";
import { useEffect } from "react";

export const AxiosInterceptor = () => {
  const { jwtToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    configureAxiosInterceptor(jwtToken, navigate);
  }, [jwtToken, navigate]);

  return null;
};
