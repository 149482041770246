import { useEffect, useState, useRef } from "react";

export const useResponsiveStyles = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const lockRef = useRef(null); // "mobile" | "tablet" | null

  // Only runs ONCE on mount to set the lock
  useEffect(() => {
    if (window.outerWidth <= 440) {
      lockRef.current = "mobile";
      setIsMobile(true);
      setIsTablet(false);
      /*console.log("Locked as mobile via outerWidth");*/
    } else if (window.outerWidth <= 1040) {
      lockRef.current = "tablet";
      setIsMobile(false);
      setIsTablet(true);
      /*console.log("Locked as tablet via outerWidth");*/
    } else {
      lockRef.current = null; // No lock, allow innerWidth to control
      determineDevice(); // Initial innerWidth check
    }

    window.addEventListener("resize", determineDevice);
    return () => window.removeEventListener("resize", determineDevice);
  }, []);

  const determineDevice = () => {
    if (lockRef.current) {
      // Locked - do nothing
      /*console.log("Locked:", lockRef.current);*/
      return;
    }

    // Only run if NOT locked
    if (window.innerWidth <= 980) {
      setIsMobile(true);
      setIsTablet(false);
      /*console.log("Responsive - Mobile");*/
    } else if (window.innerWidth <= 1040) {
      setIsMobile(false);
      setIsTablet(true);
      /*console.log("Responsive - Tablet");*/
    } else {
      setIsMobile(false);
      setIsTablet(false);
      /*console.log("Responsive - Desktop");*/
    }
  };

  return { isMobile, isTablet };
};
