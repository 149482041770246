import { storeAnalyticsEvent, useAnalytics } from "../../../analytics";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";

export const Partners = ({ onClick }) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const backgroundStyle = {
    width: "100%",
    height: isMobile ? "60rem" : "46rem",
    position: "relative",
  };
  const buttonStyles = {
    backgroundColor: "transparent",
    width: isMobile ? "30rem" : "224px",
    height: "56px",
    cursor: "pointer",
    fontSize: isMobile ? "2rem" : "1.5rem",
    borderRadius: "100px",
    fontWeight: 800,
    textAlign: "left",
    border: "none",
    color: "white",
    paddingLeft: "0px",
  };

  useAnalytics();

  const handleButtonClick = () => {
    storeAnalyticsEvent({
      category: "/home:partners",
      action: "clicked",
      label: "Lets go",
    });
    onClick();
  };

  const imageSrc = {
    mobile: require("../assets/smartmieter_lawyer_mobile.png"),
    desktop: require("../assets/smartmieter_lawyer_image.png"),
  };

  return (
    <div
      style={{
        ...backgroundStyle,
        display: "flex",
        flexDirection: "column",
        backgroundImage: `url(${isMobile ? imageSrc.mobile : imageSrc.desktop})`,
        backgroundSize: "cover",
        marginTop: isMobile ? "" : "10rem",
        backgroundPosition: "center",
      }}
    >
      <div
        style={{
          width: "80%",
          textAlign: "left",
          marginLeft: "5%",
          marginTop: "480px",
          color: "white",
        }}
      >
        <h1
          style={{
            fontSize: isMobile ? "2rem" : "3rem",
            color: "white",
          }}
        >
          {t("home.comp-partners.text1")}
        </h1>
        <h3
          style={{
            fontSize: isMobile ? "1.5rem" : "",
            color: "white",
          }}
        >
          {t("home.comp-partners.text2")}
        </h3>
        <button onClick={handleButtonClick} style={buttonStyles}>
          {t("home.comp-partners.text3")}
        </button>
      </div>
    </div>
  );
};
