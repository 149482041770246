import { useTranslation } from "react-i18next";
import { Button, CancelButton, Container } from "../../shared/components";
import { useResponsiveStyles } from "../../shared/mobile";
import { Pill } from "../../App/components";

export const MobileDetails = ({
  offer,
  date,
  message,
  sender,
  onCancel,
  onClick,
}) => {
  const { isMobile, isTablet } = useResponsiveStyles();

  const backgroundStyle = {
    width: "90vw",
    height: isMobile ? "75vh" : "90vh",
    borderRadius: "30px",
    backgroundColor: "white",
    zIndex: 10,
    position: "fixed",
    top: isMobile ? "15%" : "5%",
    left: "5%",
  };

  return (
    <div style={backgroundStyle}>
      <CancelButton
        style={{ position: "absolute", right: "5%", top: "5%" }}
        onClick={onCancel}
      ></CancelButton>
      <CorrespondenceDetails
        sender={sender}
        offer={offer}
        date={date}
        message={message}
        onClick={onClick}
      ></CorrespondenceDetails>
    </div>
  );
};

export const CorrespondenceDetails = ({
  offer,
  date,
  message,
  sender,
  onClick,
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const title = {
    landlord: t("cases-corr.message-from") + t("cases-corr.landlord"),
    tenant: t("cases-corr.message-from") + t("cases-corr.renter"),
    property_management:
      t("cases-corr.message-from") + t("cases-corr.management"),
    attorney: t("cases-corr.message-from") + t("cases-corr.attorney"),
  }[sender];

  const label = {
    landlord: t("cases-corr.answer"),
    tenant: t("cases-corr.close"),
    attorney: t("cases-corr.respond"),
    property_management: t("cases-corr.answer"),
  }[sender];

  return (
    <Container
      itemWidth="auto"
      style={{
        width: isMobile ? "80%" : "70%",
        marginLeft: "5%",
        marginTop: isMobile ? "10%" : "",
      }}
    >
      <div>
        {offer && offer.offer === true ? (
          <p
            style={{
              fontWeight: 700,
              fontSize: isMobile ? "2.8rem" : "1.2rem",
              margin: 0,
            }}
          >
            {t("cases-corr.offer2")}
          </p>
        ) : undefined}
        <Container
          style={{ marginTop: "3%", width: "90%" }}
          itemsPerRow={2}
          columnWidths={["auto", "auto"]}
        >
          {offer && offer.compensation ? (
            <Pill
              style={{
                height: isMobile ? "4rem" : "3rem",
                width: isMobile ? "18rem" : "",
              }}
              label={offer.compensation}
            ></Pill>
          ) : undefined}
          {offer && offer.proposal ? (
            <Pill
              style={{
                height: isMobile ? "4rem" : "3rem",
                width: isMobile ? "18rem" : "",
              }}
              label={offer.proposal}
            ></Pill>
          ) : undefined}
        </Container>
      </div>

      <Container
        margin="0"
        style={{ width: "95%", marginTop: isMobile ? "5%" : "" }}
        itemsPerRow={2}
        itemWidth="auto"
        columnWidths={isMobile ? ["90%", "10%"] : ["50%", "50%"]}
      >
        <p
          style={{
            fontWeight: 700,
            fontSize: isMobile ? "2.8rem" : "1.2rem",
            margin: 0,
          }}
        >
          {title}
        </p>
        <p
          style={{
            color: "#868080",
            margin: 0,
            fontSize: isMobile ? "2rem" : "1rem",
            whiteSpace: "nowrap",
            textAlign: "right",
          }}
        >
          {date}
        </p>
      </Container>
      <p
        style={{
          fontSize: isMobile ? "2rem" : "1rem",
          width: isMobile ? "85%" : "100%",
        }}
      >
        {message?.substring(0, 1500)}
      </p>
      <Container style={{ bottom: 0, right: 0 }}>
        <Button
          style={{
            borderRadius: "100px",
            bottom: "5%",
            right: "5%",
            position: "absolute",
            width: isMobile ? "40%" : "25%",
            height: isMobile ? "6rem" : "3rem",
          }}
          onClick={onClick}
          label={label}
        ></Button>
      </Container>
    </Container>
  );
};
