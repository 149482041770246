import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import { Header } from "../shared/header";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Upload, Result } from "../shared/file-components";
import { Styles } from "./styles";
import axiosInstance from "../axiosInstance";

export const Servicecharge = () => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();
  const navigate = useNavigate();

  const [uploadClicked, setUploadClicked] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [analysis, setAnalysis] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [servicechargeStatus, setServicechargeStatus] = useState(null);
  const [servicecharge, setServicecharge] = useState(null);

  useAnalytics();

  const handleUploadClick = () => {
    setUploadClicked(true);
  };

  const fetchServicecharge = async () => {
    try {
      const result = await axiosInstance.get(`/service-charges`);

      setServicecharge(result.data);
    } catch (error) {
      console.error("Error fetching servicecharge", error);
    }
  };

  const pollServicechargeStatus = async (servicechargeId) => {
    setResultVisible(true);
    let maxAttempts = 30;
    let attempt = 0;
    const pollingInterval = 5000;

    while (attempt < maxAttempts) {
      try {
        const response = await axiosInstance.get(
          `/service-charges/${servicechargeId}`
        );

        if (response.status === 200) {
          setLoading(true);
          fetchServicecharge();

          if (response.data.processingStatus !== "processing") {
            setLoading(false);
            setAnalysis(response.data.analysis);
            setServicecharge(response.data);
            setSuccess(true);
            setServicechargeStatus(response.data.analysis.status);
            return;
          }
        }
      } catch (error) {
        console.error("Error polling contract status:", error);
        return;
      }

      attempt++;
      await new Promise((resolve) => setTimeout(resolve, pollingInterval));
    }
  };

  const renderActionItem = (icon, titleKey, subtitleKey, onClick) => (
    <div onClick={onClick} style={Styles().actionItemStyle}>
      <div style={Styles().iconStyle}>{icon}</div>
      <div style={Styles().actionTextContainerStyle}>
        <div style={Styles().actionTitleStyle}>{t(titleKey)}</div>
        <div style={Styles().actionSubtitleStyle}>{t(subtitleKey)}</div>
      </div>
      <div style={Styles().arrowStyle}>→</div>
    </div>
  );

  return (
    <div style={{ backgroundColor: "#F6F6F6", height: "100vh" }}>
      <Header staticColor={true} />
      <div style={Styles().containerStyle}>
        <h1 style={Styles().titleStyle}>{t("servicecharge.title")}</h1>
        <p style={Styles().subtitleStyle}>{t("servicecharge.subtitle")}</p>

        <div style={Styles().infoBoxStyle}>
          <div>
            <div style={Styles().infoTitleStyle}>
              {t("servicecharge.info.title")}
            </div>
            <p style={Styles().infoTextStyle}>
              {t("servicecharge.info.description")}
            </p>
          </div>
        </div>

        {renderActionItem(
          "📄",
          "servicecharge.actions.upload.title",
          "servicecharge.actions.upload.subtitle",
          handleUploadClick
        )}

        {renderActionItem(
          "📋",
          "servicecharge.actions.view.title",
          "servicecharge.actions.view.subtitle",
          () => navigate("/servicecharges/overview")
        )}
      </div>

      {uploadClicked && (
        <Upload
          pollServicechargeStatus={pollServicechargeStatus}
          isMobile={isMobile}
          onCancel={() => setUploadClicked(false)}
        />
      )}

      {resultVisible && (
        <Result
          isMobile={isMobile}
          onCancel={() => setResultVisible(false)}
          analysis={analysis}
          loading={loading}
        />
      )}
    </div>
  );
};
