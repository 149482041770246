import { reasonTranslations, statusTranslations } from "./translations";

export const getDateTimeByISO = (isoDate) => {
  const date = new Date(isoDate);

  return `${padNumber(date.getDate())}.${padNumber(date.getMonth() + 1)}.${date.getFullYear()} ${padNumber(date.getUTCHours())}:${padNumber(date.getUTCMinutes())} Uhr`;
};

export const getDateByISO = (isoDate) => {
  const date = new Date(isoDate);

  return `${padNumber(date.getDate())}.${padNumber(date.getMonth() + 1)}.${date.getFullYear()}`;
};

const padNumber = (number) => {
  return number.toString().length == 1 ? "0" + number : number;
};

export const insertName = (string, value) => {
  return string.replace("<<<value>>>", value);
};

export const mapReason = (reason) => {
  return reasonTranslations[reason];
};

export const mapStatus = (status) => {
  return statusTranslations[status];
};
