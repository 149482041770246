import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import { useRef, useState } from "react";

export const AuthError = ({ visibility, type }) => {
  const { t, i18n } = useTranslation();
  const errorMessages = {
    emailMissing: t("auth-components.missing"),
    passwordMissing: t("auth-components.password"),
    nameMissing: t("auth-components.name"),
    cityMissing: t("auth-components.residence"),
    inputMissing: t("auth-components.email-password"),
    invalidEmail: t("auth-components.email"),
    weakPassword: t("auth-components.invalid-password"),
    denied: t("auth-components.denied"),
    default: t("auth-components.error"),
  };

  const errorMessage = errorMessages[type] || errorMessages.default;

  if (!visibility) return null;

  return <p style={{ color: "#721c24" }}>{errorMessage}</p>;
};

export const HomeButton = ({ style }) => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/"); // TODO: Redirect to the specified URL
  };

  const desktopStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 700,
    fontSize: "2rem",
    color: "black",
    marginLeft: "20%",
    marginTop: "3%",
    cursor: "pointer",
    whiteSpace: "nowrap",
  };

  const mobileStyle = {
    border: "none",
    appearance: "none",
    backgroundColor: "transparent",
    fontWeight: 900,
    fontSize: "10rem",
    color: "black",
    fontStyle: "italic",
    textAlign: "left",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: isMobile ? 0 : "",
    marginLeft: "10%",
    marginTop: isMobile ? "0" : "3%",
  };

  return (
    <button
      onClick={() => handleClick()}
      style={isMobile ? mobileStyle : desktopStyle}
    >
      {isMobile ? "s" : "smartmieter"}
    </button>
  );
};

export const OTPInput = ({ length = 6, onComplete }) => {
  const [otp, setOtp] = useState(new Array(length).fill(""));
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    if (!/^\d*$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);

    setOtp(newOtp);

    if (value && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (newOtp.join("").length === length) {
      onComplete(newOtp.join(""));
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  return (
    <div style={{ display: "flex", gap: 4, justifyContent: "center" }}>
      {otp.map((digit, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          type="text"
          inputMode="numeric"
          maxLength={1}
          style={{
            border: "1px solid lightgray",
            borderRadius: "10px",
            outline: "none",
            width: "3rem",
            height: "3rem",
            textAlign: "center",
            fontSize: "18px",
            transition: "border-color 0.3s",
          }}
          value={digit}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
        />
      ))}
    </div>
  );
};
