import { useState } from "react";
import { Container } from "../../../shared/components";
import { useAnalytics } from "../../../analytics";
import { getStageConfig } from "../../../config/config";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";
import axiosInstance from "../../../axiosInstance";
import "../../../text.css";

export const WaitList = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const config = getStageConfig();
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  useAnalytics();

  const addToWaitlist = async () => {
    let result;
    try {
      result = await axiosInstance.post(`/leads/${email}/waitlist`);
    } catch (error) {
      console.error(error);
    }

    if (result && result.status === 201) {
      setStatus(t("home.comp-waitlist.text1"));
    } else {
      setStatus(t("home.comp-waitlist.text2"));
    }
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        width: "100%",
        height: "40rem",
        marginTop: "20%",
      }}
    >
      <Container
        itemWidth="100%"
        columnWidths={["100%"]}
        style={{ width: "100%" }}
      >
        <p
          style={{
            fontWeight: 800,
            width: "80%",
            marginLeft: "10%",
            fontSize: "2rem",
            color: "white",
            textAlign: "center",
            marginTop: "10%",
            marginBottom: "0",
          }}
        >
          {t("home.comp-waitlist.text3")}
        </p>
        <p
          style={{
            fontWeight: 700,
            width: "80%",
            marginLeft: "10%",
            fontSize: isMobile ? "1.5rem" : "1.5rem",
            color: "lightgray",
            textAlign: "center",
            marginTop: "0",
          }}
        >
          {t("home.comp-waitlist.text4")}
        </p>
        <Container
          itemsPerRow={1}
          columnWidths={["100%"]}
          itemWidth="auto"
          style={{
            marginTop: "4%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
            <input
              style={{
                borderRadius: "30px",
                width: isMobile ? "20rem" : "26rem",
                height: isMobile ? "5rem" : "2.5rem",
                paddingLeft: "20px",
                border: "none",
                backgroundColor: "lightgray",
                color: "black",
                fontSize: isMobile ? "2rem" : "1.1rem",
              }}
              type="email"
              placeholder="E-Mail"
              onChange={(event) => setEmail(event.target.value)}
            ></input>
            <button
              onClick={() => addToWaitlist()}
              style={{
                borderRadius: "30px",
                width: isMobile ? "20rem" : "26rem",
                height: isMobile ? "5rem" : "2.5rem",
                backgroundColor: "white",
                color: "black",
                border: "none",
                cursor: "pointer",
                fontSize: isMobile ? "2rem" : "1.1rem",
                marginTop: "1.5rem",
                marginLeft: "0",
              }}
            >
              {t("home.comp-waitlist.text5")}
            </button>
            <p
              style={{
                color: "white",
                textAlign: "center",
                fontSize: "0.9rem",
              }}
            >
              {status}
            </p>
          </div>
        </Container>
      </Container>
    </div>
  );
};
