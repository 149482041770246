import { storeAnalyticsEvent, useAnalytics } from "../../../analytics";
import { Container, Input } from "../../../shared/components";
import { forwardRef } from "react";
import { useState } from "react";
import { getStageConfig } from "../../../config/config";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";
import axiosInstance from "../../../axiosInstance";
import "../../../text.css";

export const HeroSection = forwardRef(({ url }, ref) => {
  const config = getStageConfig();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsiveStyles();

  const { t, i18n } = useTranslation();
  const styles = {
    backgroundColor: "#69ADFF",
    height: isMobile ? "50rem" : isTablet ? "60rem" : "100vh",
    width: "100%",
    marginBottom: "5%",
    overflow: "hidden",
    boxSizing: "border-box",
    position: "relative",
    overflowY: "hidden",
  };

  const noteStyle = {
    margin: 0,
    fontSize: isMobile ? "3rem" : "2rem",
    zIndex: 10,
    fontWeight: 700,
    position: "relative",
    marginTop: isMobile ? "40%" : "15rem",
    maxWidth: isMobile ? "100%" : "70%",
  };

  const heroTitleStyle = {
    color: "white",
    zIndex: 10,
    position: "relative",
  };

  const subtitleStyle = {
    color: "white",
    position: "relative",
    zIndex: 10,
    maxWidth: "70%",
  };

  const roomImageSrc = {
    mobile: require("../assets/smartmieter_room_mobile.png"),
    desktop: require("../assets/smartmieter_room.png"),
  };

  useAnalytics();

  const createLead = async () => {
    if (email.includes("@")) {
      try {
        await axiosInstance.post(`/leads/${email}`);

        localStorage.setItem("lead_email", email);
        // after BETA: navigate to /funnel
        navigate("/alpha", { state: { email } });
      } catch (error) {
        console.error(error);
        if (error.response && error.response.status === 409) {
          setError(t("home.comp-hero.email-exists"));
        }
      }
    }

    storeAnalyticsEvent({
      category: "/home:hero",
      action: "clicked",
      label: "Email Input",
    });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    storeAnalyticsEvent({
      category: "/home:hero",
      action: "typed",
      label: t("home.comp-hero.email-input"),
    });
  };

  const mobileImageStyle = {
    width: "100%",
    position: "absolute",
    marginTop: "-80%",
  };

  return (
    <section style={styles} ref={ref}>
      <Container
        itemsPerRow={isMobile ? 1 : 2}
        itemWidth="100%"
        gap="0"
        style={{ width: "90%", marginLeft: "5%" }}
        columnWidths={isMobile ? ["100%"] : ["70%", "30%"]}
      >
        <div>
          <h2
            style={{
              fontSize: isMobile ? "1.5rem" : "",
              zIndex: 10,
              position: "relative",
              marginTop: isMobile ? "40%" : "15rem",
              maxWidth: isMobile ? "100%" : "70%",
            }}
          >
            {t("#1 in Rental Rights")}
          </h2>
          <h1
            style={{
              fontSize: isMobile ? "2rem" : "",
              color: "white",
              zIndex: 10,
              position: "relative",
            }}
          >
            {t("hero_headline")}
          </h1>
          <h2
            style={{
              fontSize: isMobile ? "1.5rem" : "",
              color: "white",
              position: "relative",
              zIndex: 10,
              maxWidth: "70%",
            }}
          >
            {t("home.comp-hero.compensated")}
          </h2>
          <Container
            style={{
              width: isMobile ? "90%" : "70%",
              marginTop: isMobile ? "5rem" : "4%",
            }}
            gap="0"
            itemWidth="auto"
            itemsPerRow={isMobile ? 1 : 2}
            columnWidths={isMobile ? ["100%"] : ["70%", "30%"]}
          >
            <Input
              style={{
                height: isMobile ? "4rem" : "3rem",
                width: isMobile ? "20rem" : "100%",
                borderRadius: "100px",
                fontSize: isMobile ? "1.5rem" : "1rem",
                paddingLeft: "24px",
                border: "none",
                position: "relative",
                fontWeight: 600,
                zIndex: 10,
              }}
              onEnter={createLead}
              onChange={(e) => handleEmailChange(e)}
              placeholder="name@example.com"
            ></Input>
            <button
              onClick={createLead}
              style={{
                backgroundColor: "black",
                color: "white",
                height: isMobile ? "4rem" : "3rem",
                width: isMobile ? "20rem" : "100%",
                borderRadius: "100px",
                textAlign: "center",
                marginLeft: isMobile ? "0" : "2rem",
                fontSize: isMobile ? "1.5rem" : "1rem",
                marginTop: isMobile ? "1rem" : "0",
                fontWeight: 600,
                zIndex: 10,
                position: "relative",
                border: "none",
                cursor: "pointer",
              }}
            >
              {t("home.comp-hero.start")}
            </button>
          </Container>
          <p style={{ color: "white" }}>{error}</p>
        </div>
        <img
          loading="eager"
          style={
            isMobile ? mobileImageStyle : { width: "110%", marginTop: "50%" }
          }
          src={isMobile ? roomImageSrc.mobile : roomImageSrc.desktop}
          alt="SmartMieter Hero Image"
        ></img>
        {isMobile ? undefined : (
          <Container
            style={{
              width: "80%",
              marginRight: "5%",
              bottom: "2%",
              position: "absolute",
            }}
            itemWidth="auto"
            columnWidths={["17%", "17%", "17%", "17%", "17%", "17%"]}
            itemsPerRow={6}
          >
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                loading="lazy"
                style={{ width: "2rem" }}
                src={require("../../../shared/assets/check-white.png")}
                alt="SmartMieter Feature - Check"
              ></img>
              <h4 style={{ color: "white" }}>{t("home.comp-hero.rent")}</h4>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                loading="lazy"
                style={{ width: "2rem" }}
                src={require("../../../shared/assets/check-white.png")}
                alt="SmartMieter Feature - Check"
              ></img>
              <h4 style={{ color: "white" }}>{t("home.comp-hero.water")}</h4>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                loading="lazy"
                style={{ width: "2rem" }}
                alt="SmartMieter Feature - Check"
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <h4 style={{ color: "white" }}>{t("home.comp-hero.heating")}</h4>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                loading="lazy"
                style={{ width: "2rem" }}
                alt="SmartMieter Feature - Check"
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <h4 style={{ color: "white" }}>
                {t("home.comp-hero.service-charges")}
              </h4>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                loading="lazy"
                style={{ width: "2rem" }}
                alt="SmartMieter Feature - Check"
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <h4 style={{ color: "white" }}>{t("home.comp-hero.mold")}</h4>
            </Container>
            <Container
              style={{ width: "70%" }}
              itemWidth="auto"
              itemsPerRow={2}
              columnWidths={["20%", "80%"]}
            >
              <img
                loading="lazy"
                style={{ width: "2rem" }}
                alt="SmartMieter Feature - Check"
                src={require("../../../shared/assets/check-white.png")}
              ></img>
              <h4 style={{ color: "white" }}>
                {t("home.comp-hero.more")}
                <br></br> {t("home.comp-hero.30")}
              </h4>
            </Container>
          </Container>
        )}
      </Container>
    </section>
  );
});
