import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { Header } from "../shared/header";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { getDateByISO } from "../shared/helpers";
import { useAuth } from "../Auth/provider";

export const ContractOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [contracts, setContracts] = useState([]);

  const fetchContracts = async () => {
    try {
      const result = await axiosInstance.get(`/users/${user?.tenantId}/contracts`);

      console.log("result", result);
      setContracts(result.data);
    } catch (error) {
      console.error("Error fetching contracts", error);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  useAnalytics();

  const titleStyle = {
    fontSize: "2.5rem",
    marginTop: "10rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1rem",
  };

  const subtitleStyle = {
    fontSize: "1.1rem",
    textAlign: "center",
    color: "#666",
    marginBottom: "2rem",
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F6F6",
        height: "100vh",
        marginTop: 0,
        overflowY: "hidden",
      }}
    >
      <Header staticColor={true}></Header>
      <h1 style={titleStyle}>{t("contract.overview.title")}</h1>
      <p style={subtitleStyle}>{t("contract.overview.subtitle")}</p>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "1rem",
          maxHeight: "calc(100vh - 25rem)",
          overflowY: "auto",
          paddingRight: "1rem",
          marginRight: "-1rem",
          scrollbarWidth: "none",
          marginTop: "5rem",
          msOverflowStyle: "none",
          width: "70%",
          margin: "0 auto",
        }}
      >
        {contracts?.map((contract) => (
          <div
            style={{
              display: "flex",
              height: "10vh",
              width: "20vw",
              gap: "1rem",
              cursor: "pointer",
              backgroundColor: "white",
              padding: "1rem",
              borderRadius: "15px",
            }}
            onClick={() => navigate(`/contract/${contract.contractId}`)}
            key={contract.id}
          >
            <img
              src={require("../shared/assets/contract2.png")}
              alt="Document"
              loading="lazy"
              style={{
                width: "3rem",
                height: "3rem",
                marginLeft: "1rem",
                objectFit: "contain",
                alignSelf: "center",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  marginBottom: "0.5rem",
                }}
              >
                {contract.contractName}
              </div>
              <div
                style={{
                  fontSize: "0.8rem",
                  color: "#666",
                }}
              >
                {getDateByISO(contract.createdAt)}
              </div>
            </div>
          </div>
        ))}
      </div>
      <p
        style={{
          ...subtitleStyle,
          textAlign: "center",
          position: "absolute",
          bottom: "0.5rem",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {contracts.length === 0 ? t("contract.overview.noContracts") : ""}
      </p>
    </div>
  );
};
