import { useAnalytics } from "../../../analytics";
import { Pill } from "../../../App/components";
import { Container, ImageButton } from "../../../shared/components";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";

export const ServiceCharge = ({
  highServiceChargeClicked,
  highRentClicked,
}) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  useAnalytics();

  const imageButtonStyle = {
    backgroundColor: "black",
    borderRadius: isMobile ? "20px" : "10px",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    fontSize: isMobile ? "1.5rem" : "1rem",
    width: isMobile ? "15rem" : "15rem",
    height: isMobile ? "4rem" : "56px",
    marginBottom: "100px",
    bottom: 0,
    marginTop: isMobile ? "3rem" : "",
  };

  return (
    <div
      style={{
        height: isMobile ? "70rem" : "30rem",
        width: "90%",
      }}
    >
      {!isMobile && (
        <>
          <Container
            style={{
              marginLeft: "5%",
              width: "90%",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridTemplateRows: "auto auto auto auto",
              gap: "1rem",
            }}
          >
            <Pill
              style={{
                gridColumn: "1",
                fontWeight: 600,
                minWidth: "176px",
                width: isMobile ? "80%" : "224px",
                marginLeft: "0",
              }}
              label={t("home.comp-service.text1")}
            />
            <Pill
              style={{
                gridColumn: "2",
                fontWeight: 600,
                minWidth: "176px",
                width: isMobile ? "80%" : "224px",
                marginLeft: "0",
              }}
              label={t("home.comp-service.text4")}
            />

            {/* Row 2: h1 titles */}
            <h1
              style={{
                gridColumn: "1",
                wordWrap: "break-word",
                whiteSpace: "normal",
                minWidth: "12rem",
                width: "30rem",
              }}
            >
              {t("home.comp-service.title1")}
            </h1>
            <h1
              style={{
                gridColumn: "2",
                wordWrap: "break-word",
                whiteSpace: "normal",
                minWidth: "12rem",
                width: "30rem",
              }}
            >
              {t("home.comp-service.title2")}
            </h1>

            {/* Row 3: h3 descriptions */}
            <h3
              style={{
                gridColumn: "1",
                width: isMobile ? "70%" : "448px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                minWidth: "12rem",
                width: "30rem",
                maxWidth: "35vw",
              }}
            >
              {t("home.comp-service.text2")}
            </h3>
            <h3
              style={{
                gridColumn: "2",
                width: isMobile ? "70%" : "448px",
                wordWrap: "break-word",
                whiteSpace: "normal",
                minWidth: "12rem",
                width: "30rem",
                maxWidth: "35vw",
              }}
            >
              {t("home.comp-service.text5")}
            </h3>

            {/* Row 4: Buttons */}
            <ImageButton
              style={{ ...imageButtonStyle, gridColumn: "1" }}
              onClick={highRentClicked}
              label={t("home.comp-service.text3")}
            />
            <ImageButton
              style={{ ...imageButtonStyle, gridColumn: "2" }}
              onClick={highServiceChargeClicked}
              label={t("home.comp-service.text6")}
            />
          </Container>
        </>
      )}
      {/* Mobile: Stack entire blocks */}
      {isMobile && (
        <>
          <Container
            itemWidth="80vw"
            itemsPerRow={1}
            columnWidths={["100%"]}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // ✅ THIS LINE CENTERS CONTENT
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Pill
                style={{
                  fontWeight: 600,
                  minWidth: "12rem",
                  width: "14rem",
                  marginLeft: "0",
                  marginRight: "0",
                }}
                label={t("home.comp-service.text1")}
              />
              <h1
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  width: "100%", // 💥 This fixes the right shift
                  maxWidth: "40rem",
                  marginTop: "2rem",
                }}
              >
                {t("home.comp-service.title1")}
              </h1>
              <h3
                style={{
                  fontSize: "1.5rem",
                  wordWrap: "break-word",
                  textAlign: "center",
                  whiteSpace: "normal",
                  minWidth: "12rem",
                  width: "22rem",
                }}
              >
                {t("home.comp-service.text2")}
              </h3>
              <ImageButton
                style={imageButtonStyle}
                onClick={highRentClicked}
                label={t("home.comp-service.text3")}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Pill
                style={{
                  fontWeight: 600,
                  minWidth: "12rem",
                  width: "14rem",
                  marginLeft: "0",
                  marginRight: "0",
                }}
                label={t("home.comp-service.text4")}
              />
              <h1
                style={{
                  fontSize: "2rem",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  width: "100%", // 💥 This fixes the right shift
                  maxWidth: "30rem",
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              >
                {t("home.comp-service.title2")}
              </h1>
              <h3
                style={{
                  fontSize: "1.5rem",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  textAlign: "center",
                  minWidth: "12rem",
                  width: "22rem",
                }}
              >
                {t("home.comp-service.text5")}
              </h3>
              <ImageButton
                style={imageButtonStyle}
                onClick={highServiceChargeClicked}
                label={t("home.comp-service.text6")}
              />
            </div>
          </Container>
        </>
      )}
    </div>
  );
};
