import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    supportedLngs: ["en", "de"],
    fallbackLng: "en",
    detection: {
      // Order of language detection methods
      order: [
        "navigator",
        "cookie",
        "localStorage",
        "htmlTag",
        "path",
        "subdomain",
      ],

      // Cache user language preferences
      caches: ["cookie", "localStorage"],

      // Options for language detection
      cookieMinutes: 10080, // Cache language for 7 days
      htmlTag: document.documentElement, // Set the HTML tag to detected language
    },
    backend: {
      // Load translation files from the specified path
      loadPath: "/languages/{{lng}}/translation.json",
    },
    react: {
      useSuspense: false, // Avoid rendering issues in React
    },
  });

export default i18n;
