import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AuthProvider } from "./Auth/provider";
import Auth from "./Auth/login";
import App from "./App";
import Register from "./Auth/register";
import { Routes, Route, HashRouter } from "react-router-dom";
import Home from "./Marketing/Home";
import { ProtectedRoute } from "./Auth/routeguard";
import Payment from "./Payment";
import { Contact } from "./Legal/Contact";
import { Chat } from "./Chat/chat";
import { Contract } from "./Contract/contract";
import { Terms } from "./Legal/Terms";
import { Privacy } from "./Legal/Privacy";
import ScrollToTop from "./scrollToTop";
import { AboutUs } from "./Legal/AboutUs";
import { ConfirmEmail } from "./Account/confim-email";
import { CasesOverview } from "./Cases/overview";
import { SignUpFunnel } from "./Funnel";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { Landlords } from "./Marketing/landlords";
import { Cards } from "./Marketing/cards";
import { Story } from "./Marketing/story";
import { Alpha } from "./Marketing/alpha";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analysis, ContractAnalysis } from "./Contract/analysis";
import { Confirm } from "./Auth/confirm";
import { AxiosInterceptor } from "./interceptor";
import { ResetPassword } from "./Auth/reset-password";
import { Servicecharge } from "./Servicecharge";
import { ServicechargeOverview } from "./Servicecharge/overview";
import { ServicechargeAnalysis } from "./Servicecharge/analysis";
import { ContractOverview } from "./Contract/overview";
import { HelmetProvider } from "react-helmet-async";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <HashRouter>
          <SpeedInsights />
          <Analytics />
          <ScrollToTop />
          <AuthProvider>
            <AxiosInterceptor />
            <Routes>
              <Route
                path="/cases"
                element={
                  <ProtectedRoute>
                    <CasesOverview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/chat"
                element={
                  <ProtectedRoute>
                    <Chat />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/app"
                element={
                  <ProtectedRoute>
                    <App />
                  </ProtectedRoute>
                }
              />

              <Route path="*" element={<Home />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/landlords" element={<Landlords />} />

              <Route path="/payment" element={<Payment />} />
              <Route path="/login" element={<Auth />} />
              <Route path="/register" element={<Register />} />

              <Route path="/terms" element={<Terms />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/:email/confirm-email" element={<ConfirmEmail />} />
              <Route path="/confirm-user" element={<Confirm />} />
              <Route
                path="/reset-password/:email"
                element={<ResetPassword />}
              />
              <Route path="/funnel" element={<SignUpFunnel />} />

              <Route path="/cards" element={<Cards />} />
              <Route path="/story" element={<Story />} />
              <Route path="/alpha" element={<Alpha />} />

              <Route
                path="/contract"
                element={
                  <ProtectedRoute>
                    <Contract />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contract/overview"
                element={
                  <ProtectedRoute>
                    <ContractOverview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/contract/:contractId"
                element={
                  <ProtectedRoute>
                    <ContractAnalysis />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/servicecharges"
                element={
                  <ProtectedRoute>
                    <Servicecharge />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/servicecharges/overview"
                element={
                  <ProtectedRoute>
                    <ServicechargeOverview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/servicecharges/:servicechargeId"
                element={
                  <ProtectedRoute>
                    <ServicechargeAnalysis />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AuthProvider>
        </HashRouter>
      </I18nextProvider>
    </HelmetProvider>
  </React.StrictMode>
);

//reportWebVitals();
