import { useTranslation } from "react-i18next";
import { useAnalytics } from "../analytics";
import { Container, BlurredBackground } from "../shared/components";
import { Header } from "../shared/header";
import { Footer } from "../shared/footer";
import "../i18n";
import { useState } from "react";
import { EmailInput } from "./Home/components/emailinput";
import { useResponsiveStyles } from "../shared/mobile";
import { SEO } from "../shared/components/SEO";
import "../text.css";

export const Landlords = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t, i18n } = useTranslation();
  useAnalytics();

  const [emailVisible, setEmailVisible] = useState(false);

  return (
    <>
      <SEO namespace="marketing" page="landlords" path="/landlords" />
      <div style={{ overflowX: "hidden" }}>
        <Header staticColor={true}></Header>
        <Container
          itemWidth="100%"
          columnWidths={["100%"]}
          style={{
            width: "100%",
            height: isMobile ? "500%" : "auto",
            marginBottom: isMobile ? 0 : "5%",
            textAlign: "center",
          }}
        >
          <h1
            style={{
              fontSize: isMobile ? "4.5rem" : "3rem",
              fontWeight: 900,
              width: isMobile ? "80%" : "auto",
              margin: "0 auto",
              marginTop: "25%",
            }}
          >
            {t("landlords.headline")}
          </h1>
          <h2
            style={{
              color: "#AAA6A6",
              width: isMobile ? "70%" : "50%",
              fontSize: "1.3rem",
              margin: "0 auto",
            }}
          >
            {t("landlords.subheadline")}
          </h2>

          <h2
            style={{
              color: "#AAA6A6",
              width: isMobile ? "70%" : "50%",
              fontSize: "1.3rem",
              margin: "0 auto",
              marginTop: "2%",
            }}
          >
            {t("landlords.subheadline2")}
          </h2>
          <img
            style={{
              width: "70%",
              display: "block",
              margin: "0 auto",
              marginTop: "5%",
            }}
            src={require("./assets/smartmieter_house_visualizer.png")}
          ></img>
          <div
            style={{ marginTop: isMobile ? 0 : "10%", position: "relative" }}
          >
            <h2
              style={{
                position: "absolute",
                marginLeft: "5%",
                marginTop: "5%",
                fontWeight: 600,
              }}
            >
              {t("landlords.how.headline")}
            </h2>

            <Container
              margin="0"
              style={{
                position: "absolute",
                width: "90%",
                marginTop: "15%",
                marginLeft: "5%",
              }}
              itemsPerRow={isMobile ? 1 : 3}
              itemWidth="auto"
            >
              <img
                style={{ width: isMobile ? "60%" : "90%" }}
                src={require(
                  `../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_landlord_step_1.png`
                )}
              ></img>
              <img
                style={{ width: isMobile ? "60%" : "90%" }}
                src={require(
                  `../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_landlord_step_2.png`
                )}
              ></img>
              <img
                style={{ width: isMobile ? "60%" : "90%" }}
                src={require(
                  `../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_landlord_step_3.png`
                )}
              ></img>
            </Container>
            <img
              style={{ width: "100%", marginTop: isMobile ? "40%" : "0%" }}
              src={require("./assets/smartmieter_background.png")}
            ></img>
          </div>

          <div>
            <img
              loading="lazy"
              onClick={() => setEmailVisible(true)}
              style={{ width: "80%", marginTop: "5%" }}
              alt="SmartMieter Landlord Waitlist Visualizer"
              src={require(
                `../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_landlord_waitlist_visualizer.png`
              )}
            ></img>
          </div>
        </Container>
        <Footer></Footer>
        {emailVisible ? <BlurredBackground></BlurredBackground> : undefined}
        {emailVisible ? (
          <EmailInput
            type="waitlist"
            onClose={() => setEmailVisible(false)}
            funnel={false}
            feature={"Landlord Portal"}
          ></EmailInput>
        ) : undefined}
      </div>
    </>
  );
};
