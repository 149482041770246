import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useResponsiveStyles } from "../../shared/mobile";
import { SEO } from "../../shared/components/SEO";
import "../../text.css";

export const AboutUs = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const styles = {
    backgroundColor: "#69ADFF",
    height: "60rem",
    width: "100%",
    marginBottom: "5%",
    top: 0,
    margin: 0,
    position: "relative",
  };

  useAnalytics();

  const imageSrc = {
    mobile: require("../assets/smartmieter_room_mobile.png"),
    desktop: require("../assets/room-image.png"),
  };

  return (
    <>
      <SEO namespace="marketing" page="about" path="/about" />
      <div style={{ overflowX: "hidden" }}>
        <Header staticColor={true}></Header>
        <div style={styles}>
          <Container
            itemWidth="100%"
            columnWidths={["100%"]}
            style={{ width: "100%", margin: 0 }}
          >
            <div
              style={{
                position: "absolute",
                marginLeft: "10%",
                marginTop: "30%",
                zIndex: 10,
                width: "80%",
              }}
            >
              <h2
                style={{
                  fontSize: "2rem",
                  fontWeight: 700,
                  bottom: 0,
                  padding: 0,
                  lineHeight: "auto",
                  color: "#E6E6E6",
                }}
              >
                {t("legal.about-index.text1")}
              </h2>
              <h1
                style={{
                  marginTop: "2.5rem",
                  fontSize: isMobile ? "3rem" : "5rem",
                  fontWeight: 900,
                  lineHeight: isMobile ? "" : "1rem",
                  top: 0,
                  marginBottom: 0,
                  color: "white",
                }}
              >
                {t("legal.about-index.title1")}
              </h1>
              <h3
                style={{
                  fontSize: "2rem",
                  fontWeight: 500,
                  marginTop: "2.5rem",
                  color: "white",
                }}
              >
                {t("legal.about-index.title2")}
              </h3>
            </div>
            <img
              loading="lazy"
              style={{
                position: "absolute",
                width: "80%",
                bottom: 0,
                marginBottom: 0,
                marginTop: isMobile ? "-20%" : "auto",
                marginRight: 0,
                right: 0,
              }}
              src={isMobile ? imageSrc.mobile : imageSrc.desktop}
              alt="Room for aesthetics"
            ></img>
          </Container>
        </div>
        <div
          style={{
            height: isMobile ? "90rem" : "80rem",
          }}
        >
          <h1
            style={{
              fontWeight: 900,
              fontSize: isMobile ? "2rem" : "3rem",
              marginTop: "10%",
              marginLeft: isMobile ? "10%" : "50%",
              marginBottom: 0,
            }}
          >
            {t("legal.about-index.title3")}
          </h1>
          <h3
            style={{
              color: "#AAA6A6",
              fontWeight: 500,
              fontSize: isMobile ? "1.5rem" : "1rem",
              marginTop: "0",
              marginLeft: isMobile ? "10%" : "50%",
              width: isMobile ? "80%" : "35%",
            }}
          >
            {t("legal.about-index.text2")}
          </h3>

          <Container
            itemWidth={isMobile ? "80%" : "50%"}
            itemsPerRow={isMobile ? 1 : 2}
            style={{
              marginTop: isMobile ? "30%" : "10%",
              marginLeft: "10%",
              marginRight: "10%",
            }}
            columnWidths={isMobile ? ["100%"] : ["30%", "70%"]}
          >
            {!isMobile ? (
              <div
                style={{
                  width: "25rem",
                  height: "25rem",
                  backgroundColor: "#69ADFF",
                  borderRadius: "1.5rem",
                }}
              ></div>
            ) : undefined}
            <div style={{ marginLeft: isTablet ? "25%" : "" }}>
              <h3
                style={{
                  color: "#AAA6A6",
                  marginTop: "0",
                  marginBottom: 0,
                  bottom: 0,
                  marginLeft: isMobile ? 0 : "10%",
                  fontSize: isMobile ? "1.5rem" : "",
                }}
              >
                {t("legal.about-index.text3")}
              </h3>
              <h1
                style={{
                  whiteSpace: "nowrap",
                  marginLeft: isMobile ? 0 : "10%",
                  fontSize: isMobile ? "2rem" : "",
                  top: 0,
                  marginTop: 0,
                  botton: 0,
                  marginBottom: 0,
                }}
              >
                FRIEDRICH BURK
              </h1>
              <h3
                style={{
                  width: "100%",
                  textAlign: "left",
                  marginLeft: isMobile ? 0 : "10%",
                  fontSize: isMobile ? "1.5rem" : "",
                  top: 0,
                  marginTop: 0,
                }}
              >
                {t("legal.about-index.text4")}
              </h3>
            </div>
          </Container>

          <Container
            itemWidth={isMobile ? "80%" : "50%"}
            itemsPerRow={isMobile ? 1 : 2}
            style={{
              marginTop: isMobile ? "20%" : "10%",
              marginRight: "10%",
              marginLeft: "4%",
            }}
            columnWidths={
              isMobile ? ["100%"] : isTablet ? ["45%", "55%"] : ["55%", "45%"]
            }
          >
            <div
              style={{ marginLeft: isMobile ? "10%" : "20%", width: "100%" }}
            >
              <h3
                style={{
                  width: "100%",
                  color: "#AAA6A6",
                  marginTop: "0",
                  textAlign: isMobile ? "left" : "right",
                  marginBottom: 0,
                  bottom: 0,
                  fontSize: isMobile ? "1.5rem" : "",
                }}
              >
                {t("legal.about-index.text5")}
              </h3>
              <h1
                style={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  textAlign: isMobile ? "left" : "right",
                  top: 0,
                  marginTop: 0,
                  bottom: 0,
                  marginBottom: 0,
                  fontSize: isMobile ? "2rem" : "",
                }}
              >
                MAX SCHNITZLER
              </h1>
              <h3
                style={{
                  width: isMobile ? "100%" : "25rem",
                  textAlign: isMobile ? "left" : "right",
                  top: 0,
                  marginTop: 0,
                  fontSize: isMobile ? "1.5rem" : "",
                }}
              >
                {t("legal.about-index.text6")}
              </h3>
            </div>
            {!isMobile ? (
              <div
                style={{
                  width: "25rem",
                  height: "25rem",
                  backgroundColor: "#69ADFF",
                  borderRadius: "1.5rem",
                }}
              ></div>
            ) : undefined}
          </Container>
        </div>
        <h4
          style={{
            marginLeft: "5%",
          }}
        >
          smartmieter UG (haftungsbeschränkt): Erbachstraße 13, Magstadt 71106
        </h4>
        <Footer></Footer>
      </div>
    </>
  );
};
