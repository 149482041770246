import { useEffect } from "react";
import { getStageConfig } from "../config/config";
import { HomeButton } from "../Auth/components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import axiosInstance from "../axiosInstance";
import "../text.css";

export const ConfirmEmail = () => {
  const config = getStageConfig();
  const { t, i18n } = useTranslation();

  const { email } = useParams();

  const [status, setStatus] = useState("");

  useEffect(() => {
    const confirmEmail = async () => {
      try {
        const result = await axiosInstance.post(
          `/leads/${email}/waitlist/confirm`
        );

        if (result.status === 200) {
          setStatus("success");
        } else {
          setStatus("error");
        }
      } catch (error) {
        console.error(error);
      }
    };

    confirmEmail();
  });

  return (
    <div>
      <HomeButton />
      {status === "success" ? (
        <div style={{ marginLeft: "5%" }}>
          <h1>{t("acc-confirm-email.thanks")}</h1>
          <p>{t("acc-confirm-email.confirmed")}</p>
        </div>
      ) : (
        <div style={{ marginLeft: "5%" }}>
          <h1>{t("acc-confirm-email.sorry")}</h1>
          <p>{t("acc-confirm-email.confirm-error")}</p>
        </div>
      )}
    </div>
  );
};
