import { useRef, useState, useEffect } from "react";
import {
  Input,
  Button,
  SplitPage,
  Container,
  HyperLink,
} from "../shared/components.js";
import { AuthError, HomeButton } from "./components.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./provider.jsx";
import { useTranslation } from "react-i18next";
import { storeAnalyticsEvent, useAnalytics } from "../analytics.js";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile.js";
import axiosInstance from "../axiosInstance.js";
import "../text.css";
import { SEO } from "../shared/components/SEO";
import { Styles } from "../Servicecharge/styles.js";

const Auth = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t, i18n } = useTranslation();
  const { signIn } = useAuth();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [readyForSignIn, setReadyForSignIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authState, setAuthError] = useState(false);
  const [authError, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const emailRef = useRef();
  const passwordRef = useRef();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useAnalytics();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const continueClicked = () => {
    if (!email) {
      setAuthError(true);
      setError("emailMissing");
      return;
    }

    if (!emailRegex.test(email)) {
      setAuthError(true);
      setError("invalidEmail");
      return;
    }
    setAuthError(false);
    setItems((prevItems) => [...prevItems, `Item ${prevItems.length + 1}`]);
    setReadyForSignIn(true);
  };

  const handleForgotPassword = async () => {
    try {
      await axiosInstance.post(`/user/${email}/auth/request-reset`);
    } catch (error) {
      console.error("Error requesting password reset");
    }

    navigate(`/reset-password/${email}`);
  };

  const handleSignIn = async () => {
    switch (true) {
      case !email.trim():
        setAuthError(true);
        setError("emailMissing");
        return;
      case !password.trim():
        setAuthError(true);
        setError("passwordMissing");
        return;
      case !email.trim() && !password.trim():
        setAuthError(true);
        setError("inputMissing");
        return;
    }

    setAuthError(false);
    setLoading(true);
    try {
      const authResult = await signIn(email, password);
      if (authResult.redirect) {
        navigate(authResult.redirect);
      }
    } catch (error) {
      setLoading(false);
      setAuthError(error.authError);
      setError(error.type);
    }
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        if (readyForSignIn) {
          handleSignIn();
        } else {
          // focus the password input if email is valid
          if (email && emailRegex.test(email)) {
            passwordRef.current?.focus();
          } else {
            continueClicked(); // run email validation, etc.
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [readyForSignIn, handleSignIn, continueClicked, email]);

  const styles = Styles();

  return (
    <>
      <SEO namespace="marketing" page="auth.login" path="/login" />
      <Container
        gap="0"
        margin="0"
        itemsPerRow={isMobile ? 1 : 2}
        alignItems="auto"
        itemWidth="auto"
        columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
        style={{ overflow: "hidden", height: isMobile ? "110vh" : "100vh" }}
      >
        <div>
          <HomeButton />
          <Container
            style={{
              width: isMobile ? "80%" : "30vw",
              marginLeft: isMobile ? "10%" : "20%",
              marginTop: isMobile ? "10%" : "20%",
            }}
            itemsPerRow={1}
            itemWidth="auto"
            margin="0"
          >
            <h1 style={{ fontSize: isMobile ? "2rem" : "2rem" }}>Login</h1>
            <div>
              <h2
                style={{
                  fontSize: isMobile ? "1.5rem" : "1.25rem",
                  marginBottom: "0",
                }}
              >
                E-Mail
              </h2>
              <Input
                ref={emailRef}
                style={{
                  height: isMobile ? "4rem" : "3rem",
                  borderRadius: isMobile ? "10px" : "7px",
                  paddingLeft: isMobile ? "20px" : "10px",
                  marginTop: "3%",
                  border: "1px solid lightgray",
                  fontSize: isMobile ? "1.25rem" : "1rem",
                }}
                onChange={handleEmailChange}
                placeholder={t("auth-login.email-prompt")}
                type="email"
                onEnter={() => passwordRef.current?.focus()}
              />
            </div>
            {items.map((item, index) => (
              <div>
                <h2
                  style={{
                    fontSize: isMobile ? "1.5rem" : "1.25rem",
                    marginBottom: "0",
                  }}
                >
                  {t("common.password")}
                </h2>
                <Input
                  ref={passwordRef}
                  onChange={handlePasswordChange}
                  style={{
                    height: isMobile ? "4rem" : "3rem",
                    borderRadius: isMobile ? "10px" : "7px",
                    paddingLeft: isMobile ? "20px" : "10px",
                    marginTop: "3%",
                    marginBottom: "3%",
                    border: "1px solid lightgray",
                    fontSize: isMobile ? "1.25rem" : "1rem",
                  }}
                  type="password"
                  placeholder={t("auth-login.password")}
                  onEnter={readyForSignIn ? handleSignIn : continueClicked}
                ></Input>
                <HyperLink
                  onClick={handleForgotPassword}
                  style={{ color: "blue", fontWeight: 500, color: "#69ADFF" }}
                >
                  {t("auth-login.forgot-password")}
                </HyperLink>
              </div>
            ))}
            {loading ? (
              <div style={styles.loadingStyle}>
                <div style={styles.spinnerStyle} />
                <style>
                  {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
                </style>
                <p style={{ color: "#666" }}>{t("marketing-alpha.loading")}</p>
              </div>
            ) : (
              <Button
                onClick={readyForSignIn ? handleSignIn : continueClicked}
                style={{
                  height: isMobile ? "4rem" : "3rem",
                  borderRadius: isMobile ? "10px" : "7px",
                  marginTop: isMobile ? "3%" : "2%",
                  fontSize: isMobile ? "2rem" : "1.15rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                label={
                  readyForSignIn
                    ? t("auth-login.login")
                    : t("auth-login.continue")
                }
              />
            )}

            <AuthError visibility={authState} type={authError}></AuthError>
            {/*<h1 style={{ textAlign: "center" }}>Social Login</h1>*/}
            <HyperLink
              onClick={() =>
                storeAnalyticsEvent({
                  category: "/login",
                  action: "clicked",
                  label: "Register",
                })
              }
              // BETA: change back to /register
              href="/alpha"
            >
              {t("auth-login.register")}
            </HyperLink>
          </Container>
        </div>
        {!isMobile && (
          <div style={{ height: "100vh" }}>
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              loading="eager"
              alt="SmartMieter Login"
              src={require("../shared/assets/smartmieter_login_image.png")}
            ></img>
          </div>
        )}
      </Container>
    </>
  );
};
export default Auth;
