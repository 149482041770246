import { useAnalytics } from "../../analytics";
import { Container } from "../../shared/components";
import { Footer } from "../../shared/footer";
import { Header } from "../../shared/header";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { useResponsiveStyles } from "../../shared/mobile";
import { Account } from "../../Account";
import { SEO } from "../../shared/components/SEO";
import "../../text.css";

export const Privacy = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  useAnalytics();

  return (
    <>
      <SEO namespace="marketing" page="privacy" path="/privacy" />
      <div style={{ overflowX: "hidden" }}>
        <Header staticColor={true}></Header>
        <Container
          itemWidth="100%"
          columnWidths={["100%"]}
          style={{
            width: isMobile ? "90%" : "100%",
            marginLeft: isMobile ? "5%" : "0",
            marginRight: isMobile ? "5%" : "0",
          }}
        >
          <h1
            style={{
              marginLeft: isMobile ? "5%" : "0",
              marginRight: isMobile ? "5%" : "0",
              textAlign: "center",
              marginTop: isMobile ? "50%" : "25%",
            }}
          >
            {t("legal.privacy-index.title1")}
          </h1>
          <h3
            style={{
              color: "#AAA6A6",
              width: isMobile ? "80%" : "50%",
              marginLeft: isMobile ? "10%" : "25%",
            }}
          >
            {t("legal.privacy-index.text1")}
          </h3>
          <Notice></Notice>
        </Container>
        <Footer></Footer>
        <Account></Account>
      </div>
    </>
  );
};

const SectionTitle = ({ children }) => {
  const { isMobile, isTablet } = useResponsiveStyles();

  return (
    <h3
      style={{
        fontSize: isMobile ? "4em" : "",
        margin: "1em 0",
        fontWeight: "bold",
      }}
    >
      {children}
    </h3>
  );
};

const Notice = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  return (
    <div
      style={{
        width: isMobile ? "80%" : "50%",
        marginLeft: isMobile ? "5%" : "25%",
        marginRight: isMobile ? "5%" : "25%",
        marginTop: "10%",
      }}
    >
      <h2>{t("legal.privacy-index.title2")}</h2>
      <h3>{t("legal.privacy-index.text2")}</h3>

      <SectionTitle>{t("legal.privacy-index.subtitle1")}</SectionTitle>
      <h4>
        {t("legal.privacy-index.text3")}
        <br />
        {"smartmieter UG (haftungsbeschränkt)"}
        <br />
        {"Erbachstraße 13, Magstadt 71106"}
        <br />
        {"contact@smartmieter.com"}
        <br />
      </h4>

      <SectionTitle>{t("legal.privacy-index.subtitle2")}</SectionTitle>
      <h4>
        {t("legal.privacy-index.text4")}
        <ul>
          <li>{t("legal.privacy-index.text5")}</li>
          <li>{t("legal.privacy-index.text6")}</li>
          <li>{t("legal.privacy-index.text7")}</li>
          <li>{t("legal.privacy-index.text8")}</li>
        </ul>
      </h4>

      <SectionTitle>{t("legal.privacy-index.subtitle3")}</SectionTitle>
      <h4>
        {t("legal.privacy-index.text9")}
        <ul>
          <li>{t("legal.privacy-index.text10")}</li>
          <li>{t("legal.privacy-index.text11")}</li>
          <li>{t("legal.privacy-index.text12")}</li>
          <li>{t("legal.privacy-index.text13")}</li>
        </ul>
      </h4>

      <SectionTitle>{t("legal.privacy-index.subtitle4")}</SectionTitle>
      <h4>
        {t("legal.privacy-index.text14")}
        <ul>
          <li>{t("legal.privacy-index.text15")}</li>
          <li>{t("legal.privacy-index.text16")}</li>
          <li>{t("legal.privacy-index.text17")}</li>
        </ul>
      </h4>

      <SectionTitle>{t("legal.privacy-index.subtitle5")}</SectionTitle>
      <h4>{t("legal.privacy-index.text18")}</h4>

      <SectionTitle>{t("legal.privacy-index.subtitle6")}</SectionTitle>
      <h4>
        {t("legal.privacy-index.text19")}
        <ul>
          <li>{t("legal.privacy-index.text20")}</li>
          <li>{t("legal.privacy-index.text21")}</li>
          <li>{t("legal.privacy-index.text22")}</li>
          <li>{t("legal.privacy-index.text23")}</li>
          <li>{t("legal.privacy-index.text24")}</li>
          <li>{t("legal.privacy-index.text25")}</li>
          <li>{t("legal.privacy-index.text26")}</li>
        </ul>
        {t("legal.privacy-index.text27")}
      </h4>

      <SectionTitle>{t("legal.privacy-index.subtitle7")}</SectionTitle>
      <h4>{t("legal.privacy-index.text28")}</h4>

      <SectionTitle>{t("legal.privacy-index.subtitle8")}</SectionTitle>
      <h4>{t("legal.privacy-index.text29")}</h4>

      <SectionTitle>{t("legal.privacy-index.subtitle9")}</SectionTitle>
      <h4>{t("legal.privacy-index.text30")}</h4>

      <h4>{"Status/Stand: January 24, 2024"}</h4>
    </div>
  );
};
