import { Container } from "../../shared/components";
import { useTranslation } from "react-i18next";
import "../../text.css";
import { useResponsiveStyles } from "../../shared/mobile";

export const SideMenu = ({ step = 1 }) => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();
  const stepIndicator = {
    1: require("../assets/steps/1.png"),
    2: require("../assets/steps/1.png"),
    3: require("../assets/steps/2.png"),
    4: require("../assets/steps/2.png"),
    5: require("../assets/steps/3.png"),
    6: require("../assets/steps/3.png"),
    7: require("../assets/steps/4.png"),
  }[step];

  const stepHierarchy = {
    1: [1, 2],
    2: [3, 4],
    3: [5, 6],
    4: [7],
  };

  const stepImage = {
    done: require("../../shared/assets/confirmation.png"),
    current: require("../assets/smartmieter_current.png"),
    future: require("../assets/smartmieter_future.png"),
  };

  const getMainStep = (currentStep) => {
    for (const [mainStep, subSteps] of Object.entries(stepHierarchy)) {
      if (subSteps.includes(currentStep)) {
        return parseInt(mainStep, 10);
      }
    }
    return null;
  };

  const getStepImage = (mainStep) => {
    const currentMainStep = getMainStep(step);

    if (step === 7) {
      return stepImage.done;
    }

    if (mainStep === currentMainStep) {
      return stepImage.current;
    }

    if (mainStep < currentMainStep) {
      return stepImage.done;
    }

    return stepImage.future;
  };

  return (
    <div>
      <Container
        style={{
          backgroundColor: "#F6F6F6",
          height: isMobile
            ? "120%"
            : isTablet
              ? "110%"
              : step === 6
                ? "140vh"
                : "100%",
          width: isMobile ? "23rem" : isTablet ? "23rem" : "25rem",
        }}
        margin="0"
        itemWidth="auto"
        alignItems="auto"
        itemsPerRow={1}
      >
        <h1 style={{ marginLeft: "10%", marginTop: "20%", bottom: 0 }}>
          smartmieter
        </h1>
        <Container
          style={{
            marginLeft: "2%",
            position: "absolute",
            top: "15%",
            width: "20rem",
          }}
          itemWidth="auto"
          itemsPerRow={2}
          margin="0"
          gap="0"
          columnWidths={["10%", "90%"]}
        >
          <img
            loading="lazy"
            alt="SmartMieter Step Indicator"
            style={{
              height: isMobile ? "50rem" : "13rem",
              marginTop: "2rem",
            }}
            src={stepIndicator}
          ></img>
          <Container
            margin="0"
            gap="0"
            itemsPerRow={1}
            itemWidth="auto"
            style={{ height: isMobile ? "50rem" : "13rem" }}
            columnWidths={["100%"]}
          >
            <Container
              itemWidth="auto"
              itemsPerRow={2}
              alignItems="center"
              columnWidths={["5%", "95%"]}
            >
              <img
                loading="lazy"
                key={1}
                alt="SmartMieter Step 1"
                style={{ width: step > 2 ? "95%" : "70%" }}
                src={getStepImage(1)}
              ></img>
              <h4
                style={{
                  fontSize: isMobile ? "3rem" : "",
                  fontWeight: step == 1 ? 600 : step == 2 ? 600 : "",
                  marginTop: isMobile ? "2rem" : "",
                }}
              >
                {t("signup.comp-sidemenu.text1")}
              </h4>
            </Container>
            <Container
              itemWidth="auto"
              itemsPerRow={2}
              alignItems="center"
              columnWidths={["5%", "95%"]}
            >
              <img
                loading="lazy"
                key={2}
                alt="SmartMieter Step 2"
                style={{ width: step > 4 ? "95%" : "70%" }}
                src={getStepImage(2)}
              ></img>
              <h4
                style={{
                  fontSize: isMobile ? "3rem" : "",
                  fontWeight: step == 3 ? 600 : step == 4 ? 600 : "",
                  marginTop: isMobile ? "2rem" : "",
                }}
              >
                {t("Additional Information")}
              </h4>
            </Container>
            <Container
              itemWidth="auto"
              itemsPerRow={2}
              alignItems="center"
              columnWidths={["5%", "95%"]}
            >
              <img
                loading="lazy"
                key={3}
                alt="SmartMieter Step 3"
                style={{ width: step > 6 ? "95%" : "70%" }}
                src={getStepImage(3)}
              ></img>
              <h4
                style={{
                  fontSize: isMobile ? "3rem" : "",
                  fontWeight: step == 5 ? 600 : step == 6 ? 600 : "",
                  marginTop: isMobile ? "2rem" : "",
                }}
              >
                {t("Optional: Documents")}
              </h4>
            </Container>
            <Container
              itemWidth="auto"
              itemsPerRow={2}
              alignItems="center"
              columnWidths={["5%", "95%"]}
            >
              <img
                loading="lazy"
                key={4}
                alt="SmartMieter Step 4"
                style={{ width: step >= 7 ? "95%" : "70%" }}
                src={getStepImage(4)}
              ></img>
              <h4
                style={{
                  fontSize: isMobile ? "3rem" : "",
                  fontWeight: step >= 7 ? 600 : "",
                  marginTop: isMobile ? "2rem" : "",
                }}
              >
                {t("Done")}
              </h4>
            </Container>
          </Container>
        </Container>

        <img
          loading="lazy"
          src={
            step === 6
              ? require("../assets/smartmieter_funnel_payment_deco.png")
              : require("../assets/smartmieter_funnel_decoration.png")
          }
          alt="SmartMieter Funnel Decoration"
          style={{
            width: isMobile ? "23rem" : isTablet ? "23rem" : "25rem",
            height: isMobile ? "22rem" : isTablet ? "23rem" : "25rem",
            position: step === 6 ? "relative" : "absolute",
          }}
        />
      </Container>
    </div>
  );
};
