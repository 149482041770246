import { useState, useEffect } from "react";
import { CancelButton } from "../shared/components";
import { Container } from "../shared/components";
import { getDateTimeByISO, mapReason } from "../shared/helpers";
import { caseStatus } from "../shared/models";
import { CaseCardMenu } from "./CaseCardMenu";
import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useAuth } from "../Auth/provider";
import { useResponsiveStyles } from "../shared/mobile";
import axiosInstance from "../axiosInstance";

export const CasesMenu = ({ Case, onClick, onCancel }) => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  const { user } = useAuth();
  const navigate = useNavigate();
  const [events, setEvents] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(`/events/${Case.caseId}`);
        setEvents(response.data);
      } catch (err) {
      } finally {
      }
    };

    // fetchData();
  }, []);

  useAnalytics();

  const styles = {
    backgroundColor: "white",
    height: "100%",
    width: isMobile ? "70%" : "30%",
    position: "fixed",
    top: 0,
    right: 0,
    zIndex: 20,
  };

  const handleAiChatClick = () => {
    navigate(`/chat?chatId=${Case.chatId}`);
  };

  const handleMessagesClick = () => {
    onClick();
  };

  return (
    <div style={styles}>
      <Container
        itemsPerRow={1}
        itemWidth="auto"
        style={{ marginLeft: "10%", width: "100%" }}
      >
        <Container
          itemsPerRow={2}
          itemWidth="auto"
          columnWidths={isMobile ? ["70%", "30%"] : ["50%", "50%"]}
          style={{ marginTop: "5%", width: "100%" }}
        >
          <p
            style={{
              textAlign: "left",
              fontSize: isMobile ? "4rem" : "1.5rem",
              fontWeight: "600",
            }}
          >
            {mapReason(Case.reason)[user?.language.substring(0, 2) ?? "de"]}
          </p>
          <CancelButton onClick={onCancel}></CancelButton>
        </Container>

        <Container itemsPerRow={1}>
          <CaseCardMenu
            showStatus={false}
            title={t("cases-index.chat")}
            onClick={handleAiChatClick}
          ></CaseCardMenu>
          <CaseCardMenu
            showStatus={false}
            title={t("cases-index.messages")}
            onClick={handleMessagesClick}
          ></CaseCardMenu>
        </Container>
        <p
          style={{
            fontWeight: 600,
            fontSize: isMobile ? "2.5rem" : "1.4rem",
            marginTop: "1rem",
          }}
        >
          {t("cases-index.events")}
        </p>
        <div>
          {events
            ? events.map((item, index) => (
                <Container
                  itemsPerRow={2}
                  itemWidth="fit-content"
                  style={{ width: "19rem" }}
                  columnWidths={["60%", "40%"]}
                >
                  <p style={{ whiteSpace: "nowrap" }}>
                    {caseStatus[item.eventType] ?? ""}
                  </p>
                  <p style={{ fontSize: ".9rem", whiteSpace: "nowrap" }}>
                    {getDateTimeByISO(item.createdAt)}
                  </p>
                </Container>
              ))
            : undefined}
        </div>
      </Container>
    </div>
  );
};
