import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "./mobile";
import PropTypes from "prop-types";

const variants = (type) => ({
  legal: {
    border: "2px solid green",
    icon: require("../shared/assets/check.png"),
    translationKey:
      type === "contract"
        ? "contract-contract.legal-contract"
        : "servicecharge.result.legal",
    showAppealButton: false,
  },
  illegal: {
    border: "2px solid red",
    icon: require("./assets/1904654_cancel_close_cross_delete_reject_icon.png"),
    translationKey:
      type === "contract"
        ? "contract-contract.illegal-contract"
        : "servicecharge.result.illegal",
    showAppealButton: true,
  },
  invalid: {
    border: "2px solid #FFC048",
    icon: require("../shared/assets/no-contract.png"),
    translationKey:
      type === "contract"
        ? "contract-contract.no-contract"
        : "servicecharge.result.invalid",
    showAppealButton: false,
  },
  failed: {
    border: "2px solid red",
    icon: require("./assets/1904654_cancel_close_cross_delete_reject_icon.png"),
    translationKey:
      type === "contract"
        ? "contract-contract.illegal-contract"
        : "servicecharge.result.failed",
    showAppealButton: false,
  },
});

const defaultVariant = variants("contract").invalid;

export const AnalysisCard = ({
  type = "contract",
  state,
  analysis,
  onClick,
  cardStyles,
}) => {
  const { t } = useTranslation();
  const isMobile = useResponsiveStyles();

  const variant = variants(type)[state] || defaultVariant;

  if (!variants(type)[state]) {
    console.warn(
      `AnalysisCard received invalid state: "${state}". Using default variant.`
    );
  }

  const styles = {
    card: {
      display: "grid",
      gridTemplateColumns: "6vw 24vw 10vw",
      gap: "1vw",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "3%",
      width: isMobile ? "68vw" : "40vw",
      borderRadius: "20px",
      marginTop: "4rem",
      height: isMobile ? "10rem" : "7vw",
      border: variant.border,
      ...cardStyles,
    },
    image: {
      marginLeft: "1vw",
      width: isMobile ? "44px" : "3vw",
      height: isMobile ? "40px" : "3vw",
      objectFit: "scale-down",
    },
    title: {
      overflow: "auto",
      fontSize: "1rem",
    },
    appealButton: {
      border: "solid",
      display: "flex",
      width: isMobile ? "8rem" : "7vw",
      height: isMobile ? "4rem" : "3.5rem",
      borderRadius: "20px",
      backgroundColor: "black",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
      textAlign: "center",
      cursor: "pointer",
    },
    buttonText: {
      color: "white",
    },
    buttonArrow: {
      width: isMobile ? "44px" : "22px",
      height: isMobile ? "40px" : "20px",
      objectFit: "cover",
    },
  };

  return (
    <div style={styles.card}>
      <img
        style={styles.image}
        src={variant.icon}
        alt={t(variant.translationKey)}
      />
      <h3 style={styles.title}>{t(variant.translationKey)}</h3>
      {variant.showAppealButton && (
        <button style={styles.appealButton} onClick={() => onClick(analysis)}>
          <h3 style={styles.buttonText}>{t("contract-contract.start")}</h3>
          <img
            src={require("./assets/continue-arrow.png")}
            style={styles.buttonArrow}
            alt="Continue"
          />
        </button>
      )}
    </div>
  );
};

AnalysisCard.propTypes = {
  state: PropTypes.oneOf(["legal", "illegal", "invalid", "failed"]),
  analysis: PropTypes.object,
  handleContractClick: PropTypes.func.isRequired,
};
