import { useNavigate } from "react-router-dom";
import { useAnalytics } from "../../analytics";
import { Button, Container } from "../../shared/components";
import { useTranslation } from "react-i18next";
import "../../text.css";
import { useResponsiveStyles } from "../../shared/mobile";

export const Finish = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  localStorage.setItem("funnel_step", 7);

  useAnalytics();

  const handleContinueClick = () => {
    navigate("/app");
  };

  return (
    <div>
      <Container
        itemsPerRow={2}
        itemWidth="auto"
        columnWidths={["10%", "90%"]}
        style={{ marginLeft: "10rem", marginTop: "10%", marginBottom: "2%" }}
      >
        <img
          loading="lazy"
          style={{ width: "40%" }}
          src={require("../assets/smartmieter_check-circle.png")}
          alt="check circle"
        ></img>
        <h2 style={{ fontSize: isMobile ? "3rem" : "" }}>
          {t("signup.comp-finish.text1")}
        </h2>
      </Container>
      <h3
        style={{
          fontSize: isMobile ? "3rem" : "",
          width: isMobile ? "70%" : "100%",
          marginLeft: "10rem",
          marginBottom: "2%",
        }}
      >
        {t("signup.comp-finish.text2")}
        <br></br> {t("signup.comp-finish.text3")}
      </h3>
      <Button
        onClick={handleContinueClick}
        style={{
          width: isMobile ? "20rem" : "25%",
          height: isMobile ? "7rem" : "3rem",
          marginLeft: "10rem",
          marginTop: "2%",
          padding: "0",
        }}
        label={t("signup.comp-finish.text4")}
      ></Button>
    </div>
  );
};
