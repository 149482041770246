import axios from "axios";
import { getStageConfig } from "./config/config";

const config = getStageConfig();

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const configureAxiosInterceptor = (jwtToken, navigate) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (jwtToken) {
        config.headers.Authorization = `Bearer ${jwtToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      console.log("response axiosInstance", JSON.stringify(response));
      return Promise.resolve(response);
    },
    (error) => {
      console.log("error axiosInstance", JSON.stringify(error));
      if (error.response && error.response.status === 403) {
        navigate("/login");

        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
