import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "../../shared/mobile";
import { Button } from "../../shared/components";

export const Message = ({
  sender,
  reduction,
  message,
  index,
  reductionClicked,
}) => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t } = useTranslation();

  const botStyle = {
    alignSelf: "flex-start",
    backgroundColor: "#e5e5ea",
    color: "#000000",
    borderRadius: "15px 15px 15px 0px",
  };

  const userStyle = {
    alignSelf: "flex-end",
    backgroundColor: "#007bff",
    color: "#ffffff",
    float: "right",
    position: "relative",
    borderRadius: "15px 15px 0px 15px",
  };

  const messageStyle = {
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    maxWidth: "75%",
    padding: isMobile ? "15px 40px" : "10px 15px",
    borderRadius: isMobile ? "50px" : "18px",
    lineHeight: "1.4",
    wordWrap: "break-word",
    fontSize: isMobile ? "2.6rem" : "1rem",
  };

  const buttonStyle = {
    width: isMobile ? "70%" : "15rem",
    marginTop: isMobile ? "2%" : "3%",
    fontSize: isMobile ? "2rem" : "",
    padding: isMobile ? "20px 40px" : "",
    fontWeight: 500,
    height: isMobile ? "7rem" : "3rem",
    borderRadius: "100px",
  };

  return (
    <div
      key={index}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "1rem",
        maxWidth: "80%",
        marginBottom: "1rem",
        ...messageStyle,
        ...(sender === "user" ? userStyle : botStyle),
      }}
    >
      <span style={{ width: " 100%", borderRadius: "4px", overflowY: "auto" }}>
        {message}
      </span>
      {reduction && reduction !== "False" && sender !== "user" ? (
        <Button
          label={t("chat-chat.case")}
          style={buttonStyle}
          onClick={() => reductionClicked(reduction)}
        ></Button>
      ) : undefined}
    </div>
  );
};
