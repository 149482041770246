import { Header } from "../shared/header";
import React, { useState } from "react";
import { useAuth } from "../Auth/provider";
import { useAnalytics } from "../analytics";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useNavigate } from "react-router-dom";
import { useResponsiveStyles } from "../shared/mobile";
import axiosInstance from "../axiosInstance";
import { Upload, Result } from "../shared/file-components";
import { Styles } from "../Servicecharge/styles";
import "../text.css";
import { AnalysisCard } from "../shared/analysis-card";

export const Contract = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [uploadClicked, setUploadClicked] = useState(false);
  const [resultVisible, setResultVisible] = useState(false);
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contractStatus, setContractStatus] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [existingContracts, setExistingContracts] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  useAnalytics();

  const fetchContracts = async () => {
    try {
      const result = await axiosInstance.get(
        `/users/${user?.tenantId}/contracts/`
      );
      setContracts(result.data);
      setExistingContracts(true);
      if (result.status === 404) {
        setExistingContracts(false);
      }
    } catch (error) {
      console.error("Error fetching contracts", error);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const handleUploadClick = () => {
    setUploadClicked(true);
  };

  const pollContractStatus = async (contractId) => {
    setResultVisible(true);
    let maxAttempts = 20;
    let attempt = 0;
    const pollingInterval = 5000;

    while (attempt < maxAttempts) {
      try {
        const response = await axiosInstance.get(
          `/users/${user?.tenantId}/contracts/${contractId}`
        );

        if (response.status === 200) {
          setLoading(true);
          fetchContracts();

          if (response.data.processingStatus !== "processing") {
            setLoading(false);
            setContract(response.data);
            setSuccess(true);
            setContractStatus(response.data.analysis.status);
            return;
          }
        }
      } catch (error) {
        console.error("Error polling contract status:", error);
        return;
      }

      attempt++;
      await new Promise((resolve) => setTimeout(resolve, pollingInterval));
    }

    console.error("Polling timed out.");
    setLoading(false);
    setDialogError(true);
    setDialogMessage(
      "Contract processing took too long. Please try again later."
    );
  };

  const renderActionItem = (icon, titleKey, subtitleKey, onClick) => (
    <div onClick={onClick} style={Styles().actionItemStyle}>
      <div style={Styles().iconStyle}>{icon}</div>
      <div style={Styles().actionTextContainerStyle}>
        <div style={Styles().actionTitleStyle}>{t(titleKey)}</div>
        <div style={Styles().actionSubtitleStyle}>{t(subtitleKey)}</div>
      </div>
      <div style={Styles().arrowStyle}>→</div>
    </div>
  );

  const handleContractClick = (contract) => {
    navigate(`/contract/${contract.contractId}`, { state: { contract } });
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F6F6",
        height: isMobile ? "110vh" : "100vh",
      }}
    >
      <Header staticColor={true} />
      <div style={Styles().containerStyle}>
        <h1 style={Styles().titleStyle}>{t("contract.title")}</h1>
        <p style={Styles().subtitleStyle}>{t("contract.subtitle")}</p>

        <div style={Styles().infoBoxStyle}>
          <div>
            <div style={Styles().infoTitleStyle}>
              {t("contract.info.title")}
            </div>
            <p style={Styles().infoTextStyle}>
              {t("contract.info.description")}
            </p>
          </div>
        </div>

        {renderActionItem(
          "📄",
          "contract.actions.upload.title",
          "contract.actions.upload.subtitle",
          handleUploadClick
        )}

        {renderActionItem(
          "📋",
          "contract.actions.contracts.title",
          "contract.actions.contracts.subtitle",
          () => navigate("/contract/overview")
        )}
      </div>

      {uploadClicked && (
        <Upload
          pollServicechargeStatus={pollContractStatus}
          isMobile={isMobile}
          isTablet={isTablet}
          type="contract"
          onCancel={() => setUploadClicked(false)}
        />
      )}

      {resultVisible && (
        <Result
          isMobile={isMobile}
          onCancel={() => setResultVisible(false)}
          analysis={contract}
          loading={loading}
          type="contract"
        />
      )}
    </div>
  );
};
