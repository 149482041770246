import { useAnalytics } from "../../analytics";
import { getStageConfig } from "../../config/config";
import { Button, TextArea } from "../../shared/components";
import { Container } from "../../shared/components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../axiosInstance";
import "../../text.css";
import { useResponsiveStyles } from "../../shared/mobile";
export const Problem = ({ onClick, resolve, reject }) => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t } = useTranslation();
  localStorage.setItem("funnel_step", 1);
  const config = getStageConfig();
  const [description, setDescription] = useState("");

  useAnalytics();

  const getProblemEvaluation = async () => {
    try {
      const result = await axiosInstance.post(`/compensation`, { description });

      if (result.status === 200) {
        resolve(result.data);
      } else {
        console.error(`result status: ${JSON.stringify(result)}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleContinueClick = async () => {
    await getProblemEvaluation();
    localStorage.setItem("funnel_prompt", description);
    onClick();
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    localStorage.setItem("funnel_prompt", event.target.value);
  };

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "10%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <h3 style={{ fontSize: isMobile ? "3rem" : "" }}>
          {t("signup.comp-problem.text1")}
        </h3>
        <TextArea
          onEnter={() => {}}
          value={localStorage.getItem("funnel_prompt")}
          onChange={handleDescriptionChange}
          style={{ width: "70%", height: "20rem" }}
          placeholder={t("signup.comp-problem.text2")}
        ></TextArea>
        <h4 style={{ fontSize: isMobile ? "2rem" : "" }}>
          {t("signup.comp-problem.text3")}
        </h4>
        <Button
          style={{
            backgroundColor: "#69ADFF",
            fontWeight: 600,
            width: isMobile ? "20rem" : "25%",
            height: isMobile ? "7rems" : "3rem",
          }}
          active={description.length > 15}
          onClick={handleContinueClick}
          label={t("signup.comp-problem.continue")}
        ></Button>
      </Container>
    </div>
  );
};
