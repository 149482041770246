import { useState, useEffect } from "react";
import {
  Input,
  Button,
  SplitPage,
  Container,
  HyperLink,
  SelectionInput,
} from "../shared/components";
import { AuthError, HomeButton } from "./components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import axiosInstance from "../axiosInstance";
import "../text.css";
import { useResponsiveStyles } from "../shared/mobile.js";
import { SEO } from "../shared/components/SEO";

const Register = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [readyForRegister, setReadyForRegister] = useState(false);
  const [authState, setAuthError] = useState(false);
  const [authError, setError] = useState("");
  const [label, setButtonLabel] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("mrs");
  const [city, setCity] = useState("");
  const [password, setPassword] = useState("");
  const [inputs, setInputs] = useState([]);
  const [language, setLanguage] = useState("");

  useEffect(() => {
    setLanguage(navigator.language || navigator.languages[0]);
  }, []);

  useEffect(() => {
    setButtonLabel(t("common.continue"));
  }, [t]);

  const handleRegister = async () => {
    if (!password) {
      setAuthError(true);
      setError("passwordMissing");
      return;
    } else if (!validatePassword()) {
      console.log("password invalid");
      setAuthError(true);
      setError("weakPassword");
      return;
    }

    setAuthError(false);
    try {
      const response = await axiosInstance.post(`/users`, {
        fullName,
        email,
        city,
        password,
        language,
        address,
      });

      switch (response.status) {
        case 201:
          navigate("/payment");
          break;
        case 400:
        case 409:
          setAuthError(true);
          setError("default");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

  const inputPlaceholders = [
    t("auth-register.email"),
    t("auth-register.residence"),
    t("auth-register.password"),
  ];

  const inputTypes = ["email", "text", "password"];

  const handleNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleInputChange = (index, newValue) => {
    setAuthError(false);
    setError(false);
    const updatedInputs = inputs.map((input, i) =>
      i === index ? { ...input, value: newValue } : input
    );

    switch (index) {
      case 0:
        setEmail(newValue);
        break;
      case 1:
        setCity(newValue);
        break;
      case 2:
        setPassword(newValue);
        break;
    }

    setInputs(updatedInputs);
  };

  const addInput = () => {
    const nextIndex = inputs.length;
    switch (nextIndex) {
      case 0:
        if (!validateName()) {
          setAuthError(true);
          setError("nameMissing");
          return;
        }
        break;
      case 1:
        if (!email) {
          setAuthError(true);
          setError("emailMissing");
          return;
        } else if (!validateEmail()) {
          setAuthError(true);
          setError("invalidEmail");
          return;
        }
        break;
      case 2:
        if (!city) {
          setAuthError(true);
          setError("cityMissing");
          return;
        }
        setButtonLabel(t("auth-register.payment"));
        setReadyForRegister(true);
        break;
      case 3:
        if (!password) {
          setAuthError(true);
          setError("passwordMissing");
          return;
        } else if (!validatePassword()) {
          setAuthError(true);
          setError("weakPassword");
          return;
        }

        setAuthError(false);
        break;
    }

    if (nextIndex < inputPlaceholders.length) {
      setInputs([
        ...inputs,
        {
          placeholder: inputPlaceholders[nextIndex],
          inputType: inputTypes[nextIndex],
        },
      ]);
    } else {
      return;
    }
  };

  const validateName = () => fullName.length > 3;

  const validateEmail = () => emailRegex.test(email);

  const validatePassword = () => passwordRegex.test(password);

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  return (
    <>
      <SEO namespace="marketing" page="auth.register" path="/register" />
      <div>
        {!isMobile ? (
          <div>
            <SplitPage color="white" float="left">
              <HomeButton />
              <Container
                style={{ width: "30vw" }}
                itemsPerRow={1}
                itemWidth="30vw"
                margin={
                  isTablet ? "10rem 0rem 50rem 5rem" : "10rem 0rem 50rem 7.5rem"
                }
              >
                <h1>{t("auth-register.registration")}</h1>
                <h2>{t("auth-register.name-question")}</h2>
                <SelectionInput
                  style={{ border: "1px solid lightgray" }}
                  onChange={(event) => handleAddressChange(event)}
                  options={[
                    { label: t("auth-register.title1"), value: "ms" },
                    { label: t("auth-register.title2"), value: "mr" },
                    { label: t("auth-register.other"), value: "other" },
                  ]}
                ></SelectionInput>
                <Input
                  style={{ border: "1px solid lightgray" }}
                  onChange={handleNameChange}
                  placeholder={t("auth-register.full-name")}
                  type="text"
                  onEnter={readyForRegister ? handleRegister : addInput}
                ></Input>
                {inputs.map((input, index) => (
                  <div>
                    <Input
                      style={{
                        border:
                          (index === 0 &&
                            (authError === "invalidEmail" ||
                              authError === "emailMissing")) ||
                          (index === 1 && authError === "cityMissing") ||
                          (index === 2 &&
                            (authError === "weakPassword" ||
                              authError === "passwordMissing"))
                            ? "1px solid rgb(211, 21, 40)"
                            : "1px solid lightgray",
                      }}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      placeholder={input.placeholder}
                      type={input.inputType}
                      onEnter={readyForRegister ? handleRegister : addInput}
                    ></Input>
                  </div>
                ))}
                <Button
                  onClick={readyForRegister ? handleRegister : addInput}
                  label={label}
                ></Button>
                <AuthError visibility={authState} type={authError}></AuthError>
                {/*<h1 style={{ textAlign: "center" }}>Social Sign Up</h1>*/}
                <HyperLink onClick={() => navigate("/login")} href="/login">
                  {t("auth-register.login")}
                </HyperLink>
              </Container>
            </SplitPage>
            <SplitPage float="right">
              <div style={{ height: "100vh" }}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  src={require("../shared/assets/smartmieter_login_image.png")}
                ></img>
              </div>
            </SplitPage>
          </div>
        ) : (
          <div>
            <HomeButton />
            <Container
              style={{ marginLeft: "10%", marginRight: "10%" }}
              itemsPerRow={1}
              itemWidth="90%"
              margin={"2rem 0rem 10rem 7.5rem"}
            >
              <h1>{t("auth-register.registration")}</h1>
              <h2>{t("auth-register.name-question")}</h2>
              <SelectionInput
                style={{
                  border: "1px solid lightgray",
                  fontSize: "1.5rem",
                  height: "4rem",
                  borderRadius: "10px",
                }}
                onChange={(event) => handleAddressChange(event)}
                options={[
                  { label: t("auth-register.title1"), value: "ms" },
                  { label: t("auth-register.title2"), value: "mr" },
                  { label: t("auth-register.other"), value: "other" },
                ]}
              ></SelectionInput>
              <Input
                style={{
                  border: "1px solid lightgray",
                  height: "4rem",
                  borderRadius: "10px",
                  fontSize: "1.5rem",
                }}
                onChange={handleNameChange}
                placeholder={t("auth-register.full-name")}
                type="text"
                onEnter={readyForRegister ? handleRegister : addInput}
              ></Input>
              {inputs.map((input, index) => (
                <div>
                  <Input
                    style={{
                      border:
                        (index === 0 &&
                          (authError === "invalidEmail" ||
                            authError === "emailMissing")) ||
                        (index === 1 && authError === "cityMissing") ||
                        (index === 2 &&
                          (authError === "weakPassword" ||
                            authError === "passwordMissing"))
                          ? "1px solid rgb(211, 21, 40)"
                          : "1px solid lightgray",
                      height: "4rem",
                      borderRadius: "10px",
                      fontSize: "1.5rem",
                    }}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    placeholder={input.placeholder}
                    type={input.inputType}
                    onEnter={readyForRegister ? handleRegister : addInput}
                  ></Input>
                </div>
              ))}
              <Button
                onClick={readyForRegister ? handleRegister : addInput}
                label={label}
                style={{
                  height: isMobile ? "4rem" : "3rem",
                  borderRadius: isMobile ? "10px" : "7px",
                  marginTop: isMobile ? "3%" : "2%",
                  display: "flex", // ✅ Ensure it's a flex container
                  justifyContent: "center", // ✅ Horizontally center text
                  alignItems: "center",
                }}
              ></Button>
              <AuthError visibility={authState} type={authError}></AuthError>
              {/*<h1 style={{ textAlign: "center" }}>Social Sign Up</h1>*/}
              <HyperLink onClick={() => navigate("/login")} href="/login">
                {t("auth-register.login")}
              </HyperLink>
            </Container>
          </div>
        )}
      </div>
    </>
  );
};

export default Register;
