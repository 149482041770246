import { useAnalytics } from "../analytics";
import { Header } from "../shared/header";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Container, SelectionInput, Input, Button } from "../shared/components";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/provider";
import { useLocation } from "react-router-dom";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import axiosInstance from "../axiosInstance";
import { SEO } from "../shared/components/SEO";
import "../text.css";

export const Alpha = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t } = useTranslation();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [address, setAddress] = useState("mrs");
  const location = useLocation();
  const [email, setEmail] = useState(location.state?.email || "");
  const [language, setLanguage] = useState("");
  const [error, setError] = useState("");
  const { signIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d\w\W]{8,}$/;
  const validatePassword = (pword) => passwordRegex.test(pword);

  useEffect(() => {
    setLanguage(navigator.language || navigator.userLanguage);
  }, []);

  useAnalytics();

  const checkIfAllFilled = () => {
    return name.trim() && email.trim() && password.trim();
  };

  const handleValidationAndSubmit = () => {
    setError(""); // Reset errors

    if (!name.trim()) return setError("name");
    if (!email.trim()) return setError("email");
    if (!email.includes("@")) return setError("invalidEmail");
    if (!password.trim()) return setError("password");
    if (!validatePassword(password)) return setError("weakPassword");

    createUser();
  };

  const createUser = async () => {
    setIsLoading(true);
    try {
      const result = await axiosInstance.post(`/users`, {
        fullName: name,
        email,
        address,
        language,
        password,
        city: "random",
        beta: true,
      });

      if (result.data && result.data.tenantId) {
        const newTenantId = result.data.tenantId;
        localStorage.setItem("funnel_tenant_id", newTenantId);

        const authResult = await signIn(email, password);
        if (authResult.redirect) navigate("/app");
      } else {
        console.error("User creation failed:", result);
      }
    } catch (err) {
      setError("accountCreation");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = (password) => {
    setPassword(password);
    setError("");
  };
  const handleNameChange = (name) => {
    setName(name);
    setError("");
  };

  const handleAddressChange = (address) => {
    setAddress(address);
    setError("");
  };

  const handleEmailChange = (email) => {
    setEmail(email);
    setError("");
  };

  const image = {
    mobile: require("./assets/smartmieter_retro_background_mobile.png"),
    desktop: require("./assets/smartmieter_retro_background.png"),
  };

  return (
    <>
      <SEO namespace="marketing" page="alpha" path="/alpha" />
      <div style={{ minHeight: "100vh" }}>
        <Header staticColor={isMobile} hidden hideSignUp />
        <Container
          itemWidth="100%"
          margin="0"
          gap="0"
          columnWidths={["100%"]}
          style={{ width: "100%" }}
        >
          {!isMobile && (
            <h1
              style={{
                color: "white",
                position: "fixed",
                marginLeft: "5%",
                fontSize: isMobile ? "2rem" : "",
                marginTop: isMobile ? "20%" : "72px",
                zIndex: 10,
              }}
            >
              {t("alpha.title")}
            </h1>
          )}

          <div
            style={{
              position: isMobile ? "static" : "fixed",
              zIndex: 10,
              backgroundColor: "white",
              height: isMobile ? "auto" : "37.5rem",
              width: isMobile ? "90%" : "40rem",
              top: isTablet ? "50%" : "55%",
              marginTop: isMobile ? "20%" : "",
              left: isMobile ? "" : "50%",
              marginLeft: isMobile ? "5%" : "",
              marginRight: isMobile ? "5%" : "",
              transform: isMobile ? "none" : "translate(-50%, -50%)",
              borderRadius: "20px",
              overflowX: "hidden",
              overflowY: isMobile ? "auto" : "hidden",
            }}
          >
            <Container
              itemsPerRow={1}
              itemWidth="auto"
              gap="0"
              style={{
                width: "100%",
                marginLeft: isMobile ? "15%" : "25%",
              }}
              columnWidths={["100%"]}
            >
              {/* Gender */}
              <h4 style={labelStyle(isMobile)}>{t("alpha.gender")}</h4>
              <SelectionInput
                onChange={(e) => handleAddressChange(e.target.value)}
                style={inputStyle(isMobile)}
                options={[
                  { label: t("common.female"), value: "ms" },
                  { label: t("common.male"), value: "mr" },
                  { label: t("common.other"), value: "other" },
                ]}
              />

              {/* Full Name */}
              <h4 style={labelStyle(isMobile)}>{t("common.fullname")}</h4>
              <Input
                style={{
                  ...inputStyle(isMobile),
                  border:
                    error === "name"
                      ? "1px solid rgb(211, 21, 40)"
                      : "1px solid lightgray",
                }}
                placeholder={t("components.enter.name")}
                value={name}
                onChange={(e) => handleNameChange(e.target.value)}
              />

              {/* Email */}
              <h4 style={labelStyle(isMobile)}>{t("common.email")}</h4>
              <Input
                style={{
                  ...inputStyle(isMobile),
                  border:
                    error === "email" || error === "invalidEmail"
                      ? "1px solid rgb(211, 21, 40)"
                      : "1px solid lightgray",
                }}
                placeholder="email@example.com"
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
              />

              {/* Password */}
              <h4 style={labelStyle(isMobile)}>{t("common.password")}</h4>
              <Input
                style={{
                  ...inputStyle(isMobile),
                  border:
                    error === "password" || error === "weakPassword"
                      ? "1px solid rgb(211, 21, 40)"
                      : "1px solid lightgray",
                }}
                placeholder={t("components.choose.password")}
                type="password"
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
              />

              <h5
                style={{
                  fontSize: isMobile ? "1rem" : "",
                  width: isMobile ? "70%" : "50%",
                  marginTop: "24px",
                }}
              >
                {t("alpha.password_info")}
              </h5>

              {/* Error Message */}
              {error && error !== "accountCreation" && (
                <h5 style={{ color: "red", width: "50%" }}>
                  {t(`errors.${error}`)}
                </h5>
              )}
              {error === "accountCreation" && (
                <h5 style={{ color: "red", width: "50%" }}>
                  {t("errors.account_creation")}
                </h5>
              )}

              {/* Continue Button */}
              <Button
                style={buttonStyle(isMobile, checkIfAllFilled())}
                active={checkIfAllFilled()}
                onClick={checkIfAllFilled() ? handleValidationAndSubmit : null}
                disabled={!checkIfAllFilled()}
                label={t("common.continue")}
              />

              {/* Login Link */}
              <Button
                onClick={() => navigate("/login")}
                label={t("alpha.login")}
                style={{
                  backgroundColor: "transparent",
                  color: "black",
                  fontWeight: 450,
                  width: isMobile ? "70%" : "50%",
                  height: isMobile ? "4rem" : "3rem",
                  fontSize: isMobile ? "1rem" : ".8rem",
                }}
              />
            </Container>
          </div>

          {!isMobile && (
            <img
              src={image.desktop}
              style={{
                width: "100%",
                height: isMobile ? "100vh" : "110vh",
                position: "relative",
              }}
              alt="Background"
            />
          )}
        </Container>
      </div>
    </>
  );
};

/* Helper Styling Functions */
const labelStyle = (isMobile) => ({
  fontSize: isMobile ? "2rem" : "",
  margin: 0,
  fontWeight: 400,
  marginTop: isMobile ? "6%" : "24px",
});

const inputStyle = (isMobile) => ({
  width: isMobile ? "70%" : "50%",
  height: isMobile ? "4rem" : "3rem",
  fontWeight: 500,
  marginTop: "1%",
  fontSize: isMobile ? "1.5rem" : "1rem",
  border: "1px solid lightgray",
  fontSize: isMobile ? "1.5rem" : "",
});

const buttonStyle = (isMobile, active) => ({
  backgroundColor: "#69ADFF",
  fontWeight: 600,
  width: isMobile ? "70%" : "50%",
  height: isMobile ? "4rem" : "3rem",
  fontSize: isMobile ? "2rem" : "1.5rem",
  opacity: active ? 1 : 0.5,
  cursor: active ? "pointer" : "not-allowed",
});

export default Alpha;
