import { useEffect } from "react";
import { Button } from "../../shared/components";
import { Container } from "../../shared/components";
import { mapReason } from "../../shared/helpers";
import { useState } from "react";
import { useAnalytics } from "../../analytics";
import { useTranslation } from "react-i18next";
import "../../text.css";
import { useResponsiveStyles } from "../../shared/mobile";

export const Result = ({ reason, onClick }) => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t } = useTranslation();
  localStorage.setItem("funnel_step", 2);
  const [language, setLanguage] = useState("de");

  useAnalytics();

  const safeReason = reason
    ? reason
    : localStorage.getItem("compensation_reason");
  const possibleReduction = localStorage.getItem("compensation_estimate");

  const mappedReason = mapReason(safeReason)
    ? mapReason(safeReason)[language.substring(0, 2)]
    : t("Unknown issue");

  useEffect(() => {
    setLanguage(navigator.language || navigator.userLanguage);
  }, []);

  return (
    <div>
      <Container
        style={{ marginLeft: "10rem", marginTop: "10%" }}
        columnWidths={["100%"]}
        itemWidth="auto"
        itemsPerRow={1}
      >
        <h2
          style={{
            fontWeight: 700,
            fontSize: isMobile ? "3rem" : "1.1rem",
            bottom: 0,
            margin: 0,
            maxWidth: "40rem",
            wordWrap: "break-word",
            whiteSpace: "normal",
          }}
        >
          {t("signup.comp-result.text1")}
        </h2>
        <div
          style={{
            backgroundColor: "#F6F6F6",
            width: isMobile ? "35rem" : "40rem",
            height: isMobile ? "40rem" : "15rem",
            borderRadius: "10px",
            top: 0,
          }}
        >
          <p
            style={{
              fontSize: isMobile ? "3rem" : "",
              marginLeft: "5%",
              paddingTop: "3%",
              fontWeight: 600,
            }}
          >
            {t("signup.comp-result.text2")}{" "}
            <span style={{ fontWeight: 700 }}>{mappedReason}</span>{" "}
            {t("signup.comp-result.text3")}
          </p>
          <Container
            style={{ marginLeft: "5%", marginTop: "3%" }}
            itemsPerRow={2}
            itemWidth="auto"
            columnWidths={["10%", "90%"]}
          >
            <img
              loading="lazy"
              style={{ width: "50%" }}
              alt="SmartMieter Wrench"
              src={require("../assets/smartmieter_wrench.png")}
            ></img>
            <h3 style={{ fontSize: isMobile ? "2rem" : "", width: "70%" }}>
              {t("signup.comp-result.text4")} {mappedReason}{" "}
              {t("signup.comp-result.text5")}
            </h3>
          </Container>
          <Container
            style={{ marginLeft: "5%" }}
            itemsPerRow={2}
            itemWidth="auto"
            columnWidths={["10%", "90%"]}
          >
            <img
              loading="lazy"
              style={{ width: "50%" }}
              alt="SmartMieter Dollar"
              src={require("../assets/smartmieter_dollar.png")}
            ></img>
            <h3 style={{ fontSize: isMobile ? "2rem" : "", width: "70%" }}>
              {t("signup.comp-result.text7")} {possibleReduction}{" "}
              {t("signup.comp-result.text8")}
            </h3>
          </Container>
        </div>

        <Button
          style={{
            backgroundColor: "#69ADFF",
            fontWeight: 600,
            width: isMobile ? "20rem" : "25%",
            height: isMobile ? "7rem" : "3rem",
            marginTop: "5%",
          }}
          onClick={onClick}
          label={t("signup.comp-result.continue")}
        ></Button>
      </Container>
    </div>
  );
};
