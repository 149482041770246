import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export const SEO = ({ namespace = "marketing", page, path, ogImage }) => {
  const { t, i18n } = useTranslation();

  const title = t(`${namespace}.${page}.seo.title`);
  const description = t(`${namespace}.${page}.seo.description`);
  const keywords = t(`${namespace}.${page}.seo.keywords`);
  const currentLanguage = i18n.language;

  // Add specific rental-related meta tags
  const rentalKeywords = "rental problems, cold water, mold, broken heating, service charges, rental contract, tenant rights, compensation, legal support, rental assistance, Mietrecht, Nebenkosten, Schimmel, Heizung, Warmwasser, Kaltwasser, Mietvertrag, Mieterrechte, Mietminderung, Rechtsberatung";

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={`${keywords}, ${rentalKeywords}`} />
      
      {/* Language Tags */}
      <html lang={currentLanguage} />
      <link rel="alternate" hreflang="en" href={`https://smartmieter.com/en${path}`} />
      <link rel="alternate" hreflang="de" href={`https://smartmieter.com/de${path}`} />
      <link rel="alternate" hreflang="x-default" href={`https://smartmieter.com${path}`} />
      
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://smartmieter.com${path}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`https://smartmieter.com${path}`} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      {ogImage && <meta property="twitter:image" content={ogImage} />}
      
      {/* Canonical URL */}
      <link rel="canonical" href={`https://smartmieter.com${path}`} />

      {/* Additional Meta Tags */}
      <meta name="geo.region" content="DE" />
      <meta name="geo.placename" content="Germany" />
      <meta name="geo.position" content="48.7444;8.9667" />
      <meta name="ICBM" content="48.7444, 8.9667" />
      
      {/* Service-specific meta tags */}
      <meta name="service-type" content="rental assistance, legal support, contract analysis" />
      <meta name="target-audience" content="tenants, renters, property tenants" />
      <meta name="service-area" content="Germany" />
    </Helmet>
  );
}; 