import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import "../i18n";
import { useResponsiveStyles } from "../shared/mobile";
import { Header } from "../shared/header";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { ContractElement, ImageButton } from "../shared/components";
import { useAuth } from "../Auth/provider";
import { Send } from "../Cases/send";

export const ContractAnalysis = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isMobile = useResponsiveStyles();

  const [contract, setContract] = useState();
  const { contractId } = useParams();
  const [selectedClauses, setSelectedClauses] = useState([]);
  const [caseId, setCaseId] = useState(null);
  const [clauseClicked, setClauseClicked] = useState(false);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  const toggleClauseSelection = (clause) => {
    setSelectedClauses((prevSelectedClauses) => {
      const isSelected = prevSelectedClauses.includes(clause);
      if (isSelected) {
        return prevSelectedClauses.filter((item) => item !== clause);
      } else {
        return [...prevSelectedClauses, clause];
      }
    });
  };

  const getContract = async (contractId) => {
    try {
      const response = await axiosInstance.get(
        `/users/${user?.tenantId}/contracts/${contractId}`
      );

      setContract(response.data);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  useEffect(() => {
    if (contractId) {
      getContract(contractId);
    }

    console.log(selectedClauses);
    setButtonEnabled(selectedClauses.length > 0);
  }, [contractId, selectedClauses]);

  useAnalytics();

  const containerStyle = {
    padding: "2rem",
    width: "80%",
    margin: "0 auto",
  };

  const titleStyle = {
    fontSize: "2.5rem",
    marginTop: "10rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1rem",
  };

  const subtitleStyle = {
    fontSize: "1.1rem",
    textAlign: "center",
    color: "#666",
    marginBottom: "2rem",
  };

  const handleContinueClick = async () => {
    setClauseClicked(true);

    try {
      const result = await axiosInstance.post(`/cases`, {
        reason: "illegal_contract",
        status: "opened",
        tenantId: user?.tenantId,
        tenantName: user?.fullName,
        language: user?.language,
      });

      setCaseId(result.data.caseId);
    } catch (error) {
      console.error(`error: ${error}`);
    }
  };

  const handleVisibility = () => {
    setClauseClicked(false);
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F6F6",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Header staticColor={true}></Header>
      <div style={containerStyle}>
        {clauseClicked ? (
          <Send
            externalBackgroundStyle={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(10%, 30%)",
              width: isMobile ? "85vw" : "65vw",
              height: isMobile ? "70vh" : "60vh",
              borderRadius: "30px",
              backgroundColor: "white",
              zIndex: 10,
            }}
            landlordInformation={
              user ? user?.landlord || user?.propertyManagement : undefined
            }
            reason={"illegal_contract"}
            caseId={caseId}
            onCancel={() => handleVisibility()}
          ></Send>
        ) : undefined}
        <h1 style={titleStyle}>{t("contract.analysis.title")}</h1>
        <p style={subtitleStyle}>{t("contract.analysis.subtitle")}</p>

        <div
          style={{
            marginTop: "4rem",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "1rem",
            maxHeight: "calc(100vh - 25rem)",
            overflowY: "auto",
            paddingRight: "1rem",
            marginRight: "-1rem",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          {contract &&
          contract.analysis.illegalClauses &&
          contract.analysis.illegalClauses.length > 0 ? (
            [...contract.analysis.illegalClauses].map((clause) => (
              <ContractElement
                key={clause.id || clause.label}
                onClickAction={() => toggleClauseSelection(clause)}
                imgstyle={{
                  width: "40%",
                  marginTop: "0.5rem",
                  marginBottom: "1rem",
                  paddingLeft: "1rem",
                }}
                title={clause.label}
                img={
                  selectedClauses.includes(clause)
                    ? require("../shared/assets/Checked.png")
                    : require("../shared/assets/Unchecked.png")
                }
              >
                {clause.analysis}
              </ContractElement>
            ))
          ) : (
            <div
              style={{
                textAlign: "center",
                marginTop: "2rem",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                width: "100%",
              }}
            >
              <p>{t("contract.analysis.noClauses")}</p>
            </div>
          )}
        </div>
        {clauseClicked ? undefined : (
          <ImageButton
            colorScheme="light"
            style={{
              marginTop: "2rem",
              width: "20%",
              height: "3rem",
              position: "absolute",
              right: "10%",
              bottom: "2%",
              color: "white",
              borderRadius: "10px",
              fontSize: "1.1rem",
              backgroundColor: buttonEnabled ? "black" : "lightgray",
              cursor: buttonEnabled ? "pointer" : "not-allowed",
            }}
            label={t("contract.analysis.continue")}
            onClick={() => {
              handleContinueClick();
            }}
          ></ImageButton>
        )}
      </div>
    </div>
  );
};
