import { Container, ImageButton } from "../../../shared/components";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { useResponsiveStyles } from "../../../shared/mobile";
import "../../../text.css";

export const Numbers = ({ onClick }) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  return (
    <div
      style={{
        height: isMobile ? "45rem" : "40rem",
        marginTop: isMobile ? "" : "10rem",
      }}
    >
      <h1
        style={{
          fontSize: isMobile ? "2rem" : "",
          margin: 0,
          marginLeft: "5%",
          maxWidth: "80%",
        }}
      >
        {t("home.comp-numbers.fact1")}
        <br></br> {t("home.comp-numbers.fact2")} <br></br>
        {t("home.comp-numbers.fact3")}
      </h1>

      <ImageButton
        style={{
          width: isMobile ? "80%" : "20%",
          fontSize: isMobile ? "1.5rem" : "2rem",
          marginLeft: "5%",
          textAlign: "left",
          paddingLeft: "0px",
          marginTop: "2%",
          justifyContent: "left",
          marginBottom: "30px",
        }}
        label={t("home.comp-numbers.text1")}
        colorScheme="dark"
        onClick={onClick}
      ></ImageButton>

      <Container
        itemsPerRow={isMobile ? 1 : 3}
        itemWidth="auto"
        style={{
          marginLeft: "5%",
          width: isMobile ? "70%" : "80%",
          marginRight: "5%",
          display: "flex",
          flexDirection: isMobile ? "column" : "",
          alignItems: "flex-start", // Align the top of each column
          justifyContent: "space-between",
        }}
        margin="0"
      >
        {[
          { number: "24M", text: t("home.comp-numbers.text2") },
          { number: "20%", text: t("home.comp-numbers.text3") },
          { number: "100€", text: t("home.comp-numbers.text4") },
        ].map((item, index) => (
          <Container
            key={index}
            itemsPerRow={1}
            itemWidth="auto"
            margin="0"
            gap="0"
            style={{
              flex: 1,
              minWidth: "0", // prevent overflow
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <h1 style={{ fontSize: isMobile ? "2rem" : "", margin: 0 }}>
              {item.number}
            </h1>
            <h3
              style={{ fontSize: isMobile ? "1.5rem" : "", marginTop: "1rem" }}
            >
              {item.text}
            </h3>
          </Container>
        ))}
      </Container>
    </div>
  );
};
