import { useAnalytics } from "../../../analytics";
import { Container } from "../../../shared/components";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";
import "../../../text.css";

export const How = () => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();
  useAnalytics();

  return (
    <div
      style={{
        height: isMobile ? "auto" : "80rem" /*300rem*/,
        marginTop: "5rem",
        marginBottom: "5rem",
        marginRight: "5%",
        marginTop: isMobile ? "" : "10rem",
      }}
    >
      <Container
        margin="0"
        itemWidth="100%"
        itemsPerRow={1}
        gap={isMobile ? "0" : ""}
        columnWidths={["100%"]}
        style={{
          marginLeft: "5%",
          width: "100%",
        }}
      >
        <div
          style={{ marginRight: "5%", marginBottom: isMobile ? "" : "2rem" }}
        >
          <h1
            style={{
              fontSize: isMobile ? "2rem" : "",
              wordBreak: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal",
              width: "80%",
              maxWidth: "100%",
            }}
          >
            {t("home.comp-how.title")}
          </h1>
          <h3
            style={{
              fontSize: isMobile ? "1.5rem" : "",
              wordBreak: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal",
              width: "80%",
              maxWidth: "100%",
            }}
          >
            {t("home.comp-how.subtitle")}
            {t("home.comp-how.text1")}
            {t("home.comp-how.text2")}
          </h3>
        </div>
        <Container
          margin="0"
          itemWidth="100%"
          itemsPerRow={isMobile ? 1 : 2}
          columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
          style={{ width: "100%", marginTop: isMobile ? "3rem" : "" }}
        >
          <Container
            itemWidth="100%"
            itemsPerRow={1}
            style={{
              height: isMobile ? "45rem" : "15rem",
              marginTop: isMobile ? "0" : "2rem",
            }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_reasons.png`
              )}
              alt="Description of how to reasons in respective language"
            ></img>
            <h2
              style={{
                marginTop: isMobile ? "" : "2rem",
                fontSize: isMobile ? "2rem" : "",
                width: "80%",
              }}
            >
              {t("home.comp-how.text3")}
            </h2>
            <h3
              style={{
                width: "80%",
                fontSize: isMobile ? "1.5rem" : "",
              }}
            >
              {t("home.comp-how.text4")}
            </h3>
          </Container>
          <Container
            itemWidth="100%"
            style={{
              height: isMobile ? "45rem" : "15rem",
              marginTop: isMobile ? "0" : "2rem",
            }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_steps.png`
              )}
              alt="Description of how to steps in respective language"
            ></img>
            <h2
              style={{
                marginTop: isMobile ? "" : "2rem",
                fontSize: isMobile ? "2rem" : "",
                width: "80%",
              }}
            >
              {t("home.comp-how.text5")}
            </h2>
            <h3
              style={{
                width: "80%",
                fontSize: isMobile ? "1.5rem" : "",
              }}
            >
              {t("home.comp-how.text6")}
            </h3>
          </Container>
        </Container>

        <Container
          margin="0"
          itemWidth="100%"
          itemsPerRow={isMobile ? 1 : 2}
          columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
          style={{ marginTop: isMobile ? "10%" : "30%", width: "100%" }}
        >
          <Container
            margin="0"
            itemWidth="100%"
            itemsPerRow={1}
            style={{
              height: isMobile ? "45rem" : "15rem",
              marginTop: isMobile ? "0rem" : isTablet ? "10rem" : "5rem",
            }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_you_decide.png`
              )}
              alt="Description of how to decide in respective language"
            ></img>
            <h2
              style={{
                marginTop: isMobile ? "" : "2rem",
                fontSize: isMobile ? "2rem" : "",
                width: "80%",
              }}
            >
              {t("home.comp-how.text7")}
            </h2>
            <h3
              style={{
                width: "80%",
                fontSize: isMobile ? "1.5rem" : "",
              }}
            >
              {t("home.comp-how.text8")}
            </h3>
          </Container>
          <Container
            margin="0"
            itemWidth="100%"
            style={{
              height: isMobile ? "45rem" : "15rem",
              marginTop: isMobile ? "0rem" : isTablet ? "10rem" : "5rem",
            }}
          >
            <img
              style={{ width: "80%" }}
              src={require(
                `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_how_to_result.png`
              )}
              alt="Description of how to get result in respective language"
            ></img>
            <h2
              style={{
                marginTop: isMobile ? "" : "2rem",
                fontSize: isMobile ? "2rem" : "",
                width: "80%",
              }}
            >
              {t("home.comp-how.text9")}
            </h2>
            <h3
              style={{
                fontSize: isMobile ? "1.5rem" : "",
                width: "80%",
              }}
            >
              {t("home.comp-how.text10")}
            </h3>
          </Container>
        </Container>
      </Container>
    </div>
  );
};
