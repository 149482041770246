import { BlurredBackground, Button, ContinueButton } from "./shared/components";
import { CaseCard, NewsCard, Pill } from "./App/components";
import { Container } from "./shared/components";
import { Account } from "./Account";
import { Header } from "./shared/header";
import React, { useState, useEffect, useRef } from "react";
import { CasesMenu } from "./Cases";
import { useAuth } from "./Auth/provider";
import { useAnalytics } from "./analytics";
import { getStageConfig } from "./config/config";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { Correspondence } from "./Cases/correspondence";
import { AcceptSuggestion } from "./Cases/acceptsuggestion";
import { mapReason, mapStatus } from "./shared/helpers";
import { useTranslation } from "react-i18next";
import "./text.css";
import "./i18n";
import { useResponsiveStyles } from "./shared/mobile";
import axiosInstance from "./axiosInstance";

ReactGA.initialize("G-7ZVT49R3P1");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search,
});

const App = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t, i18n } = useTranslation();
  const config = getStageConfig();
  const parentRef = useRef();
  const nestedRef = useRef();
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [correspondenceVisible, setCorrespondenceVisible] = useState(false);
  const [acceptSuggestionSelected, setAcceptSuggestionSelected] =
    useState(false);
  const [accountVisible, setAccountVisible] = useState(false);
  const [casesVisible, setCasesVisible] = useState(false);
  const [Case, setCase] = useState();
  const [cases, setCases] = useState();
  const { user, jwtToken } = useAuth();
  const [activeChat, setActiveChat] = useState(false);
  const [casesLength, setCasesLength] = useState(0);

  const handlePillClick = (question) => {
    navigate(`/chat?question=${question}`);
  };

  const handleNavigateCasesOverview = () => {
    navigate("/cases");
  };

  const handleMouseEnter = (index) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const iGot99ProblemsAndABitchAintOne = [
    t("I have no warm water in my."),
    t("I have mold in my rental."),
    t("I want to enforce a rental reduction."),
    t("I am constantly disturbed by loud noises."),
    t("I received a large bill for servicecharges."),
    t("I regularly have electrical problems in my rental."),
    t("My heat doesn't work during the winter."),
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const accountParam = queryParams.get("account");
    if (accountParam === "true") {
      setAccountVisible(true);
    }

    const fetchData = async () => {
      try {
        const fetchCases = await axiosInstance.get(
          `/cases/${user?.tenantId}?status=opened,contact_landlord,contact_attorney,response_needed,chat_consultation`
        );

        setCases(fetchCases.data);
        /*console.log(fetchCases.data);*/
        setCasesLength(fetchCases.data.length);
      } catch (err) {
        /*console.error(err);*/
      }
    };
    fetchData();
  }, []);

  useAnalytics();

  const handleShowCorrespondence = (visible) => {
    setCorrespondenceVisible(visible);
    setCasesVisible(!visible);
    setMenuVisible(false);
  };

  const handleShowAcceptSuggestion = (status) => {
    setAcceptSuggestionSelected(status);
    setCorrespondenceVisible(!status);
  };

  const handleCasesClick = (index) => {
    setCasesVisible(!casesVisible);
    setCase(cases[index]);
  };

  const handleContractClick = () => {
    navigate("/contract");
  };

  const handleChatClicked = () => {
    navigate("/chat?new=true");
  };
  const handleServiceClicked = () => {
    navigate("/servicecharges");
  };

  const getSubdivStyle = (index) => ({
    flex: 1,
    position: "relative",
    color: "black",
    textAlign: "center",
    height: isTablet ? "15vh" : "25vh",
    width: "25vw",
    transition: "transform 0.3s, z-index 0.3s, box-shadow 0.3s",
    zIndex: activeIndex === index ? 10 : 3 - index,
    transform: activeIndex === index ? "scale(1.05)" : "scale(1)",
    boxShadow: activeIndex === index ? "0 4px 10px rgba(0, 0, 0, 0.5)" : "none",
    marginLeft: index !== 0 ? "-50px" : "0",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "10px",
    cursor: "pointer",
  });

  return (
    <div
      className="App"
      ref={parentRef}
      style={{ marginTop: isMobile ? "30%" : "" }}
    >
      {/*<Navigation></Navigation>*/}
      <Header
        parentRef={parentRef}
        nestedRef={nestedRef}
        staticColor={true}
        onAccountClick={() => setAccountVisible(true)}
      />

      <Container
        style={{
          backgroundColor: "#16171D",
          borderRadius: isMobile ? "30px" : "20px",
          marginLeft: "5%",
          marginRight: "5%",
          height: isMobile
            ? casesLength === 0
              ? "25rem"
              : "50rem"
            : isTablet
              ? "30vh"
              : "40vh",
          marginTop: "120px",
          justifyContent: isMobile ? "center" : "",
        }}
        itemsPerRow={isMobile ? 1 : 2}
        columnWidths={isMobile ? ["100%"] : ["40%", "60%"]}
        itemWidth="50vw"
        gap="0"
        margin="0"
      >
        <h2
          style={{
            color: "white",
            fontSize: isMobile ? "2rem" : "",
            marginLeft: isMobile ? "10%" : "3%",
            position: "relative",
            marginTop: isMobile ? "1rem" : "",
          }}
        >
          Start
        </h2>
        {isMobile ? undefined : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end", // ✅ Pushes button all the way right
              width: isTablet ? "49vw" : "51vw", // ✅ Ensures div spans full width
              marginBottom: "1rem",
            }}
          >
            <Button
              style={{
                display: "relative",
                right: 0,
                backgroundColor: "transparent",
                color: "white",
                fontSize: "2rem",
                width: "13rem",
                padding: 0,
              }}
              onClick={() => handleNavigateCasesOverview(true)}
              label={t("View all") + " " + t("cases")}
            />
          </div>
        )}

        <Container
          style={{ width: "80%", top: 0, marginLeft: isMobile ? "8%" : "2%" }}
          itemsPerRow={isMobile ? 1 : 2}
          gap="0"
          margin="0"
          itemWidth="40vw"
          columnWidths={["60%", "40%"]}
        >
          <CaseCard
            style={{
              backgroundColor: "white",
              position: "relative",
              cursor: "pointer",
              width: isMobile ? "82vw" : "25vw",
              height: isMobile ? "15rem" : isTablet ? "12rem" : "25vh",
            }}
            onClick={() => handleChatClicked()}
          >
            <div
              style={{
                width: "50%",
                height: isMobile ? "4rem" : "2rem",
                borderRadius: isMobile ? "50px" : "100px",
                right: "2%",
                top: "2%",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(135deg, #D469FF 0%, #FFD3CB 100%)",
              }}
            >
              <h4
                style={{
                  fontSize: isMobile ? "1.25rem" : isTablet ? "0.8rem" : "",
                  width: "100%",
                  fontWeight: "500",
                  textAlign: "center",
                  top: isMobile ? "16px" : "6px",
                  position: "absolute",
                  height: "100%",
                  marginBottom: "0px",
                }}
              >
                {t("Recommended")}
              </h4>
            </div>
            <h4
              style={{
                fontWeight: "500",
                marginLeft: "5%",
                fontSize: isMobile ? "1.5rem" : "",
                position: "absolute",
                bottom: isMobile ? "7rem" : "56px",
              }}
            >
              {t("Explain your problem")}
            </h4>
            <h2
              style={{
                fontSize: isMobile ? "3rem" : "",
                marginLeft: "5%",
                position: "absolute",
                bottom: "0.5rem",
              }}
            >
              Chat
            </h2>
          </CaseCard>
          {false ? (
            <CaseCard style={{ backgroundColor: "white", cursor: "pointer" }}>
              <p
                style={{
                  fontWeight: "700",
                  fontSize: "1.6rem",
                  marginLeft: "5%",
                  position: "absolute",
                  bottom: "10px",
                }}
              >
                {t("Contact Attorney")}
              </p>
            </CaseCard>
          ) : undefined}
        </Container>
        <Container
          itemWidth="auto"
          gap="0"
          margin="0"
          itemsPerRow={isMobile ? 1 : 4}
          style={{ marginRight: "20%" }}
        >
          {isMobile ? (
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                style={{
                  backgroundColor: "transparent",
                  color: "white",
                  width: "auto",
                  fontSize: "2rem",
                  marginLeft: "10%",
                  marginRight: "3%",
                  marginBottom: "2rem",
                  whiteSpace: "nowrap", // Prevents wrapping, ensures text stays on one line
                }}
                onClick={() => handleNavigateCasesOverview(true)}
                label={t("View all") + " " + t("cases")}
              />
            </div>
          ) : undefined}

          {cases &&
            !isMobile &&
            cases.slice(0, 3).map((input, index) => (
              <div
                key={index}
                onClick={() => handleCasesClick(index)}
                style={getSubdivStyle(index)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <div
                  style={{
                    width: "50%",
                    height: isTablet ? "2.5rem" : "2rem",
                    borderRadius: isMobile ? "50px" : "100px",
                    right: "2%",
                    top: "2%",
                    minWidth: "30%",
                    paddingLeft: "6%",
                    paddingRight: "6%",
                    background:
                      "linear-gradient(135deg, #69ADFF 0%, #B3E5FC 100%)",
                    backgroundColor: "#69ADFF",
                    position: "absolute",
                  }}
                >
                  <h4
                    style={{
                      width: "100%",
                      textAlign: "center",
                      position: "relative",
                      height: "100%",
                      fontWeight: "600",
                      marginTop: isTablet ? "10%" : "4%",
                    }}
                  >
                    {
                      mapStatus(input.status)[
                        user?.language.substring(0, 2) ?? "de"
                      ]
                    }
                  </h4>
                </div>
                <h3
                  style={{
                    left: "10%",
                    top: "25%",
                    position: "absolute",
                    textAlign: "left",
                  }}
                >
                  {input.reason
                    ? mapReason(input.reason)[
                        user?.language.substring(0, 2) ?? "de"
                      ]
                    : "Problem"}
                </h3>
              </div>
            ))}
          {cases &&
            isMobile &&
            cases.slice(0, 1).map((input, index) => (
              <CaseCard
                style={{
                  backgroundColor: "white",
                  marginLeft: "10%",
                  position: "relative",
                  cursor: "pointer",
                  width: "82vw",
                  height: "15rem",
                  marginBottom: "10%",
                }}
                onClick={() => handleCasesClick(index)}
              >
                <div
                  style={{
                    width: "50%",
                    height: "4rem",
                    borderRadius: "50px",
                    right: "2%",
                    top: "2%",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background:
                      "linear-gradient(135deg, #69ADFF 0%, #B3E5FC 100%)",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "1.5rem",
                      width: "100%",
                      fontWeight: "500",
                      textAlign: "center",
                      top: "16px",
                      position: "absolute",
                      height: "100%",
                      marginBottom: "0px",
                    }}
                  >
                    {
                      mapStatus(input.status)[
                        user?.language.substring(0, 2) ?? "de"
                      ]
                    }
                  </h4>
                </div>
                <h4
                  style={{
                    fontWeight: "500",
                    marginLeft: "5%",
                    fontSize: "1.5rem",
                    position: "absolute",
                    bottom: "6.5rem",
                  }}
                >
                  {input.reason
                    ? mapReason(input.reason)[
                        user?.language.substring(0, 2) ?? "de"
                      ]
                    : "Problem"}
                </h4>
              </CaseCard>
            ))}
        </Container>
      </Container>
      <Container
        itemWidth="20rem"
        itemsPerRow={isMobile ? 1 : 2}
        columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
        gap="0"
        margin="0"
        style={{
          marginLeft: "5%",
          marginRight: "5%",
          height: "20rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "90vw" : "42.5vw",
            height: isMobile ? "15rem" : isTablet ? "12rem" : "25vh",
            cursor: "pointer",
            position: "relative",
            backgroundImage: `url(${require("./shared/assets/smartmieter_contract_visualization.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: isMobile ? "30px" : "10px",
            marginTop: isMobile ? "10%" : "",
          }}
          onClick={() => handleContractClick()}
        >
          <div style={{ padding: "24px", zIndex: 1, color: "white" }}>
            <h2
              style={{
                marginBottom: "0px",
                fontSize: isMobile ? "1.75rem" : "1.5rem",
                maxWidth: isMobile ? "80vw" : "35vw",
                height: isMobile ? "3rem" : "",
                wordWrap: "break-word",
              }}
            >
              {t("RENTAL CONTRACT")} <br /> {t("UPLOAD")}
            </h2>
            <ContinueButton
              onClick={() => handleContractClick()}
              style={{
                position: "absolute",
                bottom: isMobile ? "1rem" : isTablet ? "2rem" : "3rem",
                left: "1rem",
              }}
              colorScheme="dark"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "90vw" : "42.5vw",
            height: isMobile ? "15rem" : isTablet ? "12rem" : "25vh",
            cursor: "pointer",
            position: "relative",
            backgroundImage: `url(${require("./shared/assets/smartmieter_utility_cost_visualization.png")})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: isMobile ? "30px" : "10px",
            border: "1px solid #000",
            marginLeft: isMobile ? "" : "2.5vw",
            marginTop: isMobile ? "10%" : "",
          }}
          onClick={() => handleServiceClicked()}
        >
          <div style={{ padding: "24px", zIndex: 1, color: "white" }}>
            <h2
              style={{
                marginBottom: "0px",
                fontSize: isMobile ? "1.5rem" : "1.5rem",
                maxWidth: isMobile ? "65vw" : "35vw",
                height: isMobile ? "3rem" : "",
                color: "black",
                wordWrap: "break-word",
              }}
            >
              {t("SERVICECHARGES")} <br></br> {t("UPLOAD")}
            </h2>
            <ContinueButton
              onClick={() => handleServiceClicked()}
              style={{
                position: "absolute",
                bottom: isMobile ? "1rem" : isTablet ? "2rem" : "3rem",
                left: "1rem",
              }}
              colorScheme="dark"
            />
          </div>
        </div>
      </Container>
      <Container
        itemWidth="auto"
        itemsPerRow={isMobile ? 2 : 4}
        columnWidths={isMobile ? ["50%", "50%"] : ["25%", "25%", "25%", "25%"]}
        gap="5px"
        style={{
          marginTop: isMobile ? "65%" : isTablet ? "2rem" : "5rem",
          position: "relative",
          width: "90%",
          marginLeft: "5%",
          marginRight: "5%",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isMobile
          ? iGot99ProblemsAndABitchAintOne.slice(0, 4).map((input, index) => (
              <Pill
                key={index}
                label={input}
                style={{
                  minWidth: "88%",
                  marginLeft: "2%",
                  marginRight: "2%",
                }}
                textStyle={{ fontSize: isMobile ? "1.3rem" : "0.8rem" }}
                onClick={() => handlePillClick(input)}
              />
            ))
          : iGot99ProblemsAndABitchAintOne.map((input, index) => (
              <Pill
                key={index}
                label={input}
                style={{
                  minWidth: "88%",
                  marginLeft: "48px",
                  marginRight: "48px",
                }}
                textStyle={{ fontSize: "1rem" }}
                onClick={() => handlePillClick(input)}
              />
            ))}
      </Container>
      <Account></Account>
      {casesVisible ? <BlurredBackground></BlurredBackground> : undefined}
      {correspondenceVisible ? (
        <Correspondence
          Case={Case}
          onCancel={() => handleShowCorrespondence(false)}
          onAcceptSuggestion={() => handleShowAcceptSuggestion(true)}
        ></Correspondence>
      ) : undefined}
      {acceptSuggestionSelected ? (
        <AcceptSuggestion
          onCancel={() => handleShowAcceptSuggestion(false)}
        ></AcceptSuggestion>
      ) : undefined}
      {casesVisible ? (
        <CasesMenu
          Case={Case}
          onCancel={() => setCasesVisible(false)}
          onClick={() => handleShowCorrespondence(true)}
        ></CasesMenu>
      ) : undefined}
    </div>
  );
};

export default App;
