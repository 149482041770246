import { useResponsiveStyles } from "../shared/mobile";

export const Styles = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  return {
    containerStyle: {
      padding: "2rem",
      maxWidth: "800px",
      margin: "0 auto",
    },

    titleStyle: {
      fontSize: "2.5rem",
      marginTop: isMobile ? "5rem" : "10rem",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "1rem",
    },

    subtitleStyle: {
      fontSize: "1.1rem",
      textAlign: "center",
      color: "#666",
      marginBottom: "2rem",
    },

    infoBoxStyle: {
      backgroundColor: "#E1EFFF",
      borderRadius: "1rem",
      padding: "2rem",
      marginTop: "5rem",
      marginBottom: "1rem",
      position: "relative",
      overflow: "hidden",
    },

    infoTitleStyle: {
      fontSize: "1.5rem",
      fontWeight: "600",
      marginLeft: "1rem",
      marginBottom: "0.5rem",
    },

    infoTextStyle: {
      color: "#444",
      maxWidth: "70%",
      marginLeft: "1rem",
    },

    actionItemStyle: {
      display: "flex",
      alignItems: "center",
      padding: isMobile ? "0.5rem" : "1.5rem",
      height: "5rem",
      borderRadius: "0.75rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "all 0.2s ease",
      backgroundColor: "white",
      textDecoration: "none",
      color: "inherit",
    },

    iconStyle: {
      fontSize: "1.5rem",
      marginRight: "1.5rem",
      marginLeft: "0.5rem",
      display: "flex",
      alignItems: "center",
    },

    actionTextContainerStyle: {
      flex: 1,
      marginLeft: "0.5rem",
    },

    actionTitleStyle: {
      fontSize: "1.3rem",
      fontWeight: "600",
      marginBottom: "0.25rem",
    },

    actionSubtitleStyle: {
      fontSize: "0.9rem",
      color: "#666",
    },

    arrowStyle: {
      fontSize: "1.5rem",
      color: "#666",
    },

    modalStyle: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "white",
      borderRadius: "20px",
      padding: "2rem",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      textAlign: "left",
      height: isMobile ? "30rem" : isTablet ? "36vh" : "55vh",
      width: isMobile ? "80vw" : isTablet ? "60vw" : "50vw",
    },

    modalHeaderStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "-0.5rem",
    },

    filePreviewStyle: {
      backgroundColor: "#E1EFFF",
      padding: "0.5rem 1rem",
      borderRadius: "8px",
      marginTop: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    dropzoneStyle: {
      border: "2px dashed #ccc",
      borderRadius: "10px",
      padding: "1rem",
      marginTop: "1rem",
      textAlign: "center",
      cursor: "pointer",
      backgroundColor: "#fafafa",
    },

    loadingStyle: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: "2rem",
    },

    spinnerStyle: {
      width: "20px",
      height: "20px",
      border: "3px solid #f3f3f3",
      borderTop: "3px solid #3498db",
      borderRadius: "50%",
      animation: "spin 1s linear infinite",
      marginBottom: "1rem",
    },
  };
};
