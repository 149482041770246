import { useAnalytics } from "../../../analytics";
import { Container } from "../../../shared/components";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { useResponsiveStyles } from "../../../shared/mobile";
import "../../../text.css";

export const EasyHelp = () => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();

  useAnalytics();

  return (
    <div
      style={{
        minHeight: isMobile ? "40rem" : isTablet ? "55vh" : "80vh",
        marginTop: isMobile ? "" : "10rem",
      }}
    >
      <Container
        itemsPerRow={isMobile ? 1 : 2}
        itemWidth="fit-content"
        columnWidths={isMobile ? ["100%"] : ["50%", "50%"]}
        style={{
          marginLeft: "5%",
          height: isMobile ? "auto" : "600px",
          position: "relative",
        }}
      >
        <div>
          <h1 style={{ fontSize: isMobile ? "2rem" : "" }}>
            {t("home.comp-easyhelp.help")} <br></br>
            {t("home.comp-easyhelp.solve")}
          </h1>
          <h3
            style={{
              fontSize: isMobile ? "1.5rem" : "",
              color: "#AAA6A6",
              width: isMobile ? "85%" : "448px",
            }}
          >
            <span style={{ color: "black" }}>
              {t("home.comp-easyhelp.know")}
            </span>{" "}
            {t("home.comp-easyhelp.better")}
          </h3>
          <h3
            style={{
              fontSize: isMobile ? "1.5rem" : "",
              color: "#AAA6A6",
              width: isMobile ? "85%" : "448px",
            }}
          >
            <span style={{ color: "black" }}>
              {t("home.comp-easyhelp.stress")}
            </span>{" "}
            {t("home.comp-easyhelp.text1")}
          </h3>
          <h3
            style={{
              fontSize: isMobile ? "1.5rem" : "",
              color: "#AAA6A6",
              width: isMobile ? "85%" : "448px",
            }}
          >
            <span style={{ color: "black" }}>
              {t("home.comp-easyhelp.247")}
            </span>{" "}
            {t("home.comp-easyhelp.comp")}
            <span style={{ color: "black" }}>
              {" "}
              {t("home.comp-easyhelp.start")}
            </span>
          </h3>
        </div>
        {!isMobile ? (
          <img
            loading="eager"
            alt="SmartMieter Product Display"
            style={{ marginLeft: "5%", width: "75%", marginTop: "5%" }}
            src={require(
              `../../../../public/languages/resources/${i18n.language ?? "de"}/smartmieter_product_display.png`
            )}
          ></img>
        ) : undefined}
      </Container>
    </div>
  );
};
