import {
  BlurredBackground,
  CancelButton,
  Container,
  Button,
} from "../shared/components";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "../analytics";
import { Dialog } from "../shared/dialog";
import { useState } from "react";
import "../i18n";
import axiosInstance from "../axiosInstance";

export const AcceptSuggestion = ({
  suggestion,
  suggestionId,
  onClick,
  onCancel,
}) => {
  const { t } = useTranslation();
  const backgroundStyle = {
    width: "50vw",
    height: "70vh",
    borderRadius: "30px",
    backgroundColor: "white",
    zIndex: 10,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(50%, 20%)",
  };

  const titleStyle = {
    fontWeight: "700",
    fontSize: "2rem",
    margin: 0,
    marginLeft: "10%",
    marginTop: "8%",
    bottom: 0,
  };

  const subtitleStyle = {
    marginLeft: "10%",
    fontSize: "1rem",
    color: "#868080",
    fontWeight: "500",
    top: 0,
  };

  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);

  useAnalytics();

  const handleDismissDialog = () => {
    setDialogError(false);
  };

  const handleAcceptSuggestion = async () => {
    const result = await axiosInstance.put(
      `/suggestions/${suggestionId}?acceptedOption=first_option`
    );
    if (result.status === 200) {
      setDialogSuccess(true);
      setDialogMessage(t("cases-suggestion.forwarded"));
      setTimeout(onCancel, 4000);
    }
  };

  const handleActivateAutomaticResponses = async () => {
    try {
      await axiosInstance.put(`/users/${suggestion.userId}`, {
        sk: "SETTINGS",
        automaticResponses: true,
      });
    } catch (error) {
      setDialogError(true);
    }
  };

  return (
    <div style={{ position: "absolute" }}>
      <BlurredBackground></BlurredBackground>
      <div style={backgroundStyle}>
        <Container
          style={{ width: "100%", margin: 0, rowGap: 0, columnGap: 0 }}
          itemWidth="auto"
          columnWidths={["80%", "20%"]}
          itemsPerRow={2}
        >
          <div>
            <p style={titleStyle}>{t("cases-suggestion.response")}</p>
            <p style={subtitleStyle}>{t("cases-suggestion.choose-response")}</p>
          </div>
          <CancelButton onClick={() => onCancel()}></CancelButton>
        </Container>

        <p style={{ marginLeft: "8%" }}>{suggestion}</p>
        <Button
          style={{
            width: "30%",
            marginLeft: "8%",
            borderRadius: "100px",
            position: "absolute",
            marginLeft: "8%",
            bottom: "30%",
          }}
          onClick={() => handleAcceptSuggestion()}
          label={t("cases-suggestion.send-response")}
        ></Button>
        <br></br>
        <Button
          style={{
            borderRadius: "100px",
            height: "6vh",
            width: "85%",
            position: "absolute",
            marginLeft: "8%",
            bottom: "5%",
          }}
          onClick={() => handleActivateAutomaticResponses()}
          label={t("cases-suggestion.auto")}
        ></Button>
      </div>

      {dialogError ? (
        <Dialog
          type="error"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
      {dialogSuccess ? (
        <Dialog
          type="success"
          message={dialogMessage}
          dismiss={handleDismissDialog}
        ></Dialog>
      ) : undefined}
    </div>
  );
};
