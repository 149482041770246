import { Container } from "../shared/components";
import { SideMenu } from "./components/sidemenu";
import { Button } from "../shared/components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Problem } from "./components/problem";
import { Result } from "./components/result";
import { Contact } from "./components/contact";
import { Documents } from "./components/documents";
import { Payment } from "./components/payment";
import { Finish } from "./components/finish";
import { mapReason } from "../shared/helpers";
import { useTranslation } from "react-i18next";
import "../text.css";
import { useResponsiveStyles } from "../shared/mobile";
export const SignUpFunnel = () => {
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet } = useResponsiveStyles();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const urlStep = searchParams.get("step");

  useEffect(() => {
    if (urlStep) {
      setStep(parseInt(urlStep));
    }
  }, [urlStep]);

  const [step, setStep] = useState(() => {
    const step = localStorage.getItem("funnel_step");
    return urlStep ? urlStep : step ? parseInt(step) : 1;
  });

  const handleAboutClick = () => {
    navigate("/about");
  };

  const handlePrivacyClick = () => {
    navigate("/privacy");
  };

  const handleContinueClick = () => {
    setStep((prevStep) => {
      const newStep = prevStep + 1;
      return newStep;
    });
  };

  const handleCompensation = (compensation) => {
    localStorage.setItem("compensation_reason", compensation);
    localStorage.setItem(
      "compensation_estimate",
      mapReason(compensation)["compensation_estimate"]
    );
  };

  return (
    <div>
      <Container
        margin="0"
        itemWidth="auto"
        alignItems="auto"
        itemsPerRow={2}
        columnWidths={["20%", "80%"]}
        style={{ overflow: "hidden", height: step === 6 ? "140vh" : "100%" }}
      >
        <SideMenu step={step}></SideMenu>
        <Container
          alignItems="auto"
          itemsPerRow={1}
          itemWidth="auto"
          columnWidths={["100%"]}
        >
          <Container
            style={{ position: "absolute", right: 0, top: "2%", width: "30%" }}
            itemsPerRow={2}
            itemWidth="auto"
            columnWidths={["10%", "90%"]}
          >
            <img
              style={{ width: "60%" }}
              alt="SmartMieter Shield"
              src={require("./assets/smartmieter_shield.png")}
              loading="lazy"
            ></img>
            <h3 style={{ marginBottom: 0, fontWeight: 600 }}>
              {t("signup.index.text1")}
            </h3>
          </Container>

          {step === 1 ? (
            <Problem
              onClick={handleContinueClick}
              resolve={(compensation) => handleCompensation(compensation)}
            ></Problem>
          ) : undefined}
          {step === 2 ? (
            <Result onClick={handleContinueClick}></Result>
          ) : undefined}
          {step === 3 ? (
            <Contact type={"personal"} onClick={handleContinueClick}></Contact>
          ) : undefined}
          {step === 4 ? (
            <Contact type={"landlord"} onClick={handleContinueClick}></Contact>
          ) : undefined}
          {step === 5 ? (
            <Documents onClick={handleContinueClick}></Documents>
          ) : undefined}
          {step === 6 ? (
            <Payment onClick={handleContinueClick}></Payment>
          ) : undefined}
          {step === 7 ? (
            <Finish onClick={handleContinueClick}></Finish>
          ) : undefined}

          <Container
            style={{
              position: "absolute",
              left: "32rem",
              marginLeft: "0%",
              bottom: isMobile ? "0" : isTablet ? "0" : "-10%",
              width: "25%",
            }}
            columnWidths={["50%", "50%"]}
            itemWidth="auto"
            margin="0"
            gap="0"
            itemsPerRow={2}
          >
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                width: "50%",
                whiteSpace: "nowrap",
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
              label={t("signup.index.about")}
              onClick={handleAboutClick}
            ></Button>
            <Button
              style={{
                backgroundColor: "white",
                color: "black",
                width: "50%",
                fontSize: "0.9rem",
                fontWeight: 600,
              }}
              label={t("signup.index.privacy")}
              onClick={handlePrivacyClick}
            ></Button>
          </Container>
        </Container>
      </Container>
    </div>
  );
};
