import { useEffect, useState } from "react";
import {
  BlurredBackground,
  Button,
  CancelButton,
} from "../../shared/components";
import axiosInstance from "../../axiosInstance";
import { useAuth } from "../../Auth/provider";
import { getDateByISO } from "../../shared/helpers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const Contract = ({ onClick, toggleVisibility }) => {
  const [contracts, setContracts] = useState([]);
  const { user } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/users/${user?.tenantId}/contracts/`
        );
        setContracts(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div style={{ position: "relative" }}>
      <BlurredBackground clicked={toggleVisibility}></BlurredBackground>
      <div
        style={{
          backgroundColor: "white",
          height: "30rem",
          width: "25rem",
          position: "absolute",
          left: "10px",
          zIndex: 1000,
          bottom: "0.2rem",
          border: "0.5px solid black",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "2rem",
            paddingTop: 0,
            paddingBottom: 0,
          }}
        >
          <p style={{ fontSize: "1.3rem", fontWeight: 500 }}>
            {t("contract-contract.pose_question")}
          </p>
          <CancelButton onClick={toggleVisibility}></CancelButton>
        </div>
        <div
          style={{
            overflowY: "scroll",
            height: "25rem",
            marginLeft: "1rem",
          }}
        >
          {contracts.length > 0 ? (
            contracts.map((c, i) => (
              <ContractRow
                contract={c}
                onClick={() => onClick(c.contractId, c.key)}
                key={i}
              ></ContractRow>
            ))
          ) : (
            <Button
              style={{ width: "70%" }}
              label={t("contract-contract.upload")}
              onClick={() => navigate("/contract")}
            ></Button>
          )}
        </div>
      </div>
    </div>
  );
};

const ContractRow = ({ onClick, contract }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "1rem",
        cursor: "pointer",
        height: "4.5rem",
      }}
    >
      <img
        loading="lazy"
        src={require("../../shared/assets/contract2.png")}
        alt="SmartMieter Contract"
      ></img>
      <div style={{ marginLeft: "1rem" }}>
        <p
          style={{
            fontWeight: "bold",
            fontSize: "1.3rem",
            marginBottom: "0.2rem",
          }}
        >
          {contract.contractName}
        </p>
        <p style={{ marginTop: "0.2rem" }}>
          {getDateByISO(contract.createdAt)}
        </p>
      </div>
    </div>
  );
};
