export const prodStageConfig = {
  USER_POOL_ID: "eu-central-1_ADwOY1F6z",
  CLIENT_ID: "2srv0f4q3g8vmmahh3tf1k3k3v",
  API_URL: "https://qk9sp0covl.execute-api.eu-central-1.amazonaws.com/default",
  WEBSOCKET_URL: "wss://zgb1a5lf0l.execute-api.eu-central-1.amazonaws.com/prod",
  STRIPE_PUBLISHABLE_KEY:
    "pk_live_51QGfVnBQODzeSQeQXagR6ipzuTcxnwdv1CukoSHhrGH3y9UnqGjwCZPQhG0FFoqy4iraNcXe1hXZYzV8F2HGQpdU005Vq5mp2p",
  STRIPE_PRICE_IDS: {
    basic: {
      monthly: "price_1QlrtkBQODzeSQeQs1vg2Ube",
      yearly: "price_1QlsaKBQODzeSQeQbhV9M1JQ",
    },
    pro: "price_1Qls7ZBQODzeSQeQE6SEHqfr",
  },
};
