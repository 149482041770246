import { Helmet } from "react-helmet-async";

export const StructuredData = ({ data }) => {
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(data)}
      </script>
    </Helmet>
  );
};

export const OrganizationSchema = () => {
  const data = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Smartmieter",
    "url": "https://smartmieter.com",
    "logo": "https://smartmieter.com/logo.png",
    "description": "Smartmieter helps tenants with rental problems including cold water, mold, broken heating, high service charges, and rental contract analysis. Get expert legal support and compensation for rental issues.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Erbachstraße 13",
      "addressLocality": "Magstadt",
      "postalCode": "71106",
      "addressCountry": "DE"
    },
    "areaServed": "Germany",
    "serviceType": ["Legal Service", "Rental Assistance", "Contract Analysis"],
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Rental Assistance Services",
      "itemListElement": [
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Service Charge Analysis",
            "description": "Check your service charges for overbilling and get compensation"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Rental Contract Analysis",
            "description": "AI-powered analysis of rental contracts for problematic clauses"
          }
        },
        {
          "@type": "Offer",
          "itemOffered": {
            "@type": "Service",
            "name": "Rental Issues Support",
            "description": "Help with cold water, mold, broken heating, and other rental problems"
          }
        }
      ]
    }
  };

  return <StructuredData data={data} />;
};

export const WebSiteSchema = () => {
  const data = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Smartmieter",
    "url": "https://smartmieter.com",
    "description": "Smartmieter helps tenants with rental problems including cold water, mold, broken heating, high service charges, and rental contract analysis. Get expert legal support and compensation for rental issues.",
    "potentialAction": {
      "@type": "SearchAction",
      "target": "https://smartmieter.com/search?q={search_term_string}",
      "query-input": "required name=search_term_string"
    },
    "keywords": "rental problems, cold water, mold, broken heating, service charges, rental contract, tenant rights, compensation, legal support, rental assistance"
  };

  return <StructuredData data={data} />;
}; 