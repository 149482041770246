import { SplitPage, Container } from "../shared/components";
import { HomeButton } from "../Auth/components";
import { useAnalytics } from "../analytics";
import { useTranslation } from "react-i18next";
import { getStageConfig } from "../config/config";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useResponsiveStyles } from "../shared/mobile";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import { useAuth } from "../Auth/provider";
import "../i18n";
import { storeAnalyticsEvent } from "../analytics";
import axiosInstance from "../axiosInstance";
import "../text.css";

export const Checkout = ({ return_url, style }) => {
  const { user } = useAuth();
  const config = getStageConfig();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get("plan");
  const interval = queryParams.get("interval");

  const fetchClientSecret = async () => {
    try {
      const session = await axiosInstance.post(`/payments/checkout`, {
        priceId:
          plan === "basic"
            ? config.STRIPE_PRICE_IDS.basic[interval ?? "monthly"]
            : config.STRIPE_PRICE_IDS.pro,
        tenantId: user?.tenantId,
        return_url,
      });

      return session.data.client_secret;
    } catch (error) {
      console.error(`error while fetching client secret: ${error}`);
    }
  };

  const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY);

  const options = {
    fetchClientSecret,
    onComplete: () => {
      storeAnalyticsEvent({
        category: location.pathname + location.search,
        action: "payment",
        label: "subscription purchased",
      });
    },
  };

  return (
    <div style={{ width: "70%", marginLeft: "2%", ...style }}>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

const Payment = () => {
  const { isMobile, isTablet } = useResponsiveStyles();
  const { t } = useTranslation();

  useAnalytics();

  const stripePromise = loadStripe(
    "pk_test_51QGfVnBQODzeSQeQ0lMUgcJq98ZyVbRKADz5qppJLUWUoMDMTRihfgQELVgLhN2b3w4bTpcw06fCVrTWLKNi8Xd100M5Ex94cm"
  );

  return (
    <div>
      <head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
      </head>
      {!isMobile ? (
        <div>
          <SplitPage color="white" float="left">
            <div style={{ marginLeft: "20%" }}>
              <HomeButton style={{ marginLeft: "7%" }} />

              <h1
                style={{
                  marginTop: "10%",
                  bottom: 0,
                  marginLeft: "8%",
                }}
              >
                {t("payment-index.payment")}
              </h1>

              <Elements stripe={stripePromise}>
                <Checkout></Checkout>
              </Elements>
            </div>
          </SplitPage>
          <SplitPage float="right" style={{ height: "135vh" }}>
            <Container
              itemsPerRow={1}
              itemWidth="80%"
              style={{ margin: "5rem 0rem 0rem 5rem" }}
            >
              <h2 style={{ color: "white" }}>
                {t("payment-index.payment_headline")}
              </h2>
              <Container
                itemsPerRow={2}
                style={{
                  backgroundColor: "#EFEFEF",
                  width: "100%",
                  marginTop: "2rem",
                  borderRadius: "10px",
                  height: "6rem",
                }}
              >
                <h3
                  style={{
                    marginLeft: "1.5rem",
                    marginBottom: "0",
                  }}
                >
                  1
                </h3>
                <h3 style={{ justifyContent: "center", marginBottom: "0" }}>
                  {t("payment-index.text1")}
                </h3>
              </Container>
              <Container
                itemsPerRow={2}
                style={{
                  backgroundColor: "#EFEFEF",
                  width: "100%",
                  marginTop: "1rem",
                  borderRadius: "10px",
                  height: "6rem",
                }}
              >
                <h3
                  style={{
                    marginLeft: "1.5rem",
                    marginBottom: "0",
                  }}
                >
                  2
                </h3>
                <h3 style={{ justifyContent: "center", marginBottom: "0" }}>
                  {t("payment-index.text2")}
                </h3>
              </Container>
              <Container
                itemsPerRow={2}
                style={{
                  backgroundColor: "#EFEFEF",
                  width: "100%",
                  marginTop: "1rem",
                  borderRadius: "10px",
                  height: "6rem",
                }}
              >
                <h3
                  style={{
                    marginLeft: "1.5rem",
                    marginBottom: "0",
                  }}
                >
                  3
                </h3>
                <h3 style={{ justifyContent: "center", marginBottom: "0" }}>
                  {t("payment-index.text3")}
                </h3>
              </Container>
              <Container
                itemsPerRow={2}
                style={{
                  backgroundColor: "#EFEFEF",
                  width: "100%",
                  marginTop: "1rem",
                  borderRadius: "10px",
                  height: "6rem",
                }}
              >
                <h3
                  style={{
                    marginLeft: "1.5rem",
                    marginBottom: "0",
                  }}
                >
                  4
                </h3>
                <h3 style={{ justifyContent: "center", marginBottom: "0" }}>
                  {t("payment-index.text4")}
                </h3>
              </Container>
            </Container>
          </SplitPage>
        </div>
      ) : (
        <div>
          <HomeButton style={{ marginLeft: "7%" }} />
          <h1
            style={{
              marginTop: "10%",
              bottom: 0,
              marginLeft: "8%",
            }}
          >
            {t("payment-index.payment")}
          </h1>
          <Elements stripe={stripePromise}>
            <Checkout></Checkout>
          </Elements>
        </div>
      )}
    </div>
  );
};
export default Payment;
